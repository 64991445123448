import React from 'react';
import { clsx } from 'clsx';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

interface CardComposition {
  Header: React.FC<CardProps>;
  Content: React.FC<CardProps>;
  Footer: React.FC<CardProps>;
}

export const Card: React.FC<CardProps> & CardComposition = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'overflow-hidden rounded-lg border border-slate-200 bg-white shadow-sm',
        className
      )}
    >
      {children}
    </div>
  );
};

const CardHeader: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'border-b border-slate-200 bg-slate-50 px-6 py-4',
        className
      )}
    >
      {children}
    </div>
  );
};

const CardContent: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={clsx('p-6', className)}>
      {children}
    </div>
  );
};

const CardFooter: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'border-t border-slate-200 bg-slate-50 px-6 py-4',
        className
      )}
    >
      {children}
    </div>
  );
};

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter; 