import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  CalendarIcon,
  CalendarDaysIcon,
} from '@heroicons/react/20/solid';
import { CALENDAR_MODE__MONTH, CALENDAR_MODE__WEEK } from '../../../constants';
import { Button } from '../../../ui/Button';
import { clsx } from 'clsx';
import { Card } from '../../../ui/Card';

export function CalendarHeader({
  today,
  setIsOpenNewRotaModal,
  handlePrevious,
  handleToday,
  handleNext,
  setMode,
  mode
}: any) {
  return (
    <div className="border-b border-slate-200 bg-white px-4 py-5 sm:px-6">
      <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
        {/* Left side with title and date picker */}
        <div className="flex items-center gap-2">
          <div className="hidden rounded-md bg-slate-100 p-1.5 sm:block">
            <CalendarDaysIcon className="h-5 w-5 text-slate-500" />
          </div>
          <div>
            <h2 className="text-lg font-medium leading-6 text-slate-900">
              <time dateTime={today.format('YYYY-MM')}>
                {today.format('MMMM YYYY')}
              </time>
            </h2>
            <p className="mt-1 text-sm text-slate-500">
              {mode === CALENDAR_MODE__MONTH ? 'Monthly view' : 'Weekly view'}
            </p>
          </div>
        </div>
        
        {/* Right side with controls */}
        <div className="flex flex-wrap items-center gap-3">
          {/* Navigation buttons */}
          <div className="flex rounded-md shadow-sm">
            <Button
              onClick={handlePrevious}
              variant="outline"
              size="sm"
              className="rounded-r-none border-r-0"
              aria-label="Previous"
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </Button>
            <Button
              onClick={handleToday}
              variant="outline"
              size="sm"
              className="rounded-none border-x-0"
            >
              Today
            </Button>
            <Button
              onClick={handleNext}
              variant="outline"
              size="sm"
              className="rounded-l-none border-l-0"
              aria-label="Next"
            >
              <ChevronRightIcon className="h-5 w-5" />
            </Button>
          </div>
          
          {/* View mode selector */}
          <Menu as="div" className="relative">
            {({ open }) => (
              <>
                <Menu.Button 
                  as={Button}
                  variant="outline"
                  size="sm"
                  className={clsx(
                    "gap-1",
                    open && "ring-2 ring-slate-300 ring-opacity-50"
                  )}
                >
                  <CalendarIcon className="h-4 w-4 text-slate-500" />
                  <span>{mode === CALENDAR_MODE__MONTH ? 'Month' : 'Week'}</span>
                  <ChevronDownIcon className="h-4 w-4 text-slate-500" />
                </Menu.Button>
                
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-1 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Card className="overflow-hidden">
                      <div className="py-1">
                        {[
                          { id: CALENDAR_MODE__MONTH, label: 'Month view' },
                          { id: CALENDAR_MODE__WEEK, label: 'Week view' },
                        ].map((viewMode) => (
                          <Menu.Item key={viewMode.id}>
                            {({ active }) => (
                              <button
                                onClick={() => setMode(viewMode.id)}
                                className={clsx(
                                  'flex w-full items-center px-4 py-2 text-left text-sm',
                                  active ? 'bg-slate-50 text-slate-900' : 'text-slate-700',
                                  mode === viewMode.id && 'font-medium text-slate-900'
                                )}
                              >
                                {viewMode.id === CALENDAR_MODE__MONTH ? (
                                  <CalendarDaysIcon className="mr-2 h-4 w-4 text-slate-500" />
                                ) : (
                                  <CalendarIcon className="mr-2 h-4 w-4 text-slate-500" />
                                )}
                                {viewMode.label}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Card>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
          
          {/* Create rota button */}
          <Button
            color="red"
            size="sm"
            onClick={() => setIsOpenNewRotaModal(true)}
            className="gap-1.5"
          >
            <PlusIcon className="h-4 w-4" />
            <span className="hidden sm:inline">Create new rota</span>
            <span className="sm:hidden">New rota</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
