import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Button } from './Button';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
}

interface ModalComposition {
  Header: React.FC<ModalHeaderProps>;
  Body: React.FC<ModalBaseProps>;
  Footer: React.FC<ModalBaseProps>;
  Title: React.FC<ModalBaseProps>;
  Description: React.FC<ModalBaseProps>;
}

interface ModalBaseProps {
  children: React.ReactNode;
  className?: string;
}

interface ModalHeaderProps extends ModalBaseProps {
  onClose?: () => void;
  showCloseButton?: boolean;
}

export const Modal: React.FC<ModalProps> & ModalComposition = ({
  open,
  onClose,
  children,
  className,
  maxWidth = 'md',
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-950/50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full',
                  {
                    'sm:max-w-xs': maxWidth === 'xs',
                    'sm:max-w-sm': maxWidth === 'sm',
                    'sm:max-w-md': maxWidth === 'md',
                    'sm:max-w-lg': maxWidth === 'lg',
                    'sm:max-w-xl': maxWidth === 'xl',
                    'sm:max-w-2xl': maxWidth === '2xl',
                    'sm:max-w-full': maxWidth === 'full',
                  },
                  className
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  className,
  onClose,
  showCloseButton = true,
}) => {
  return (
    <div
      className={clsx(
        'flex items-start justify-between border-b border-slate-200 bg-slate-50 px-6 py-4',
        className
      )}
    >
      <div className="flex-1">{children}</div>
      {showCloseButton && onClose && (
        <Button
          variant="ghost"
          size="sm"
          onClick={onClose}
          className="ml-3 -mr-2 -mt-2 rounded-md"
          aria-label="Close"
        >
          <XMarkIcon className="h-5 w-5 text-slate-400" />
        </Button>
      )}
    </div>
  );
};

const ModalBody: React.FC<ModalBaseProps> = ({ children, className }) => {
  return <div className={clsx('p-6', className)}>{children}</div>;
};

const ModalFooter: React.FC<ModalBaseProps> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'border-t border-slate-200 bg-slate-50 px-6 py-4 flex justify-end space-x-3',
        className
      )}
    >
      {children}
    </div>
  );
};

const ModalTitle: React.FC<ModalBaseProps> = ({ children, className }) => {
  return (
    <Dialog.Title
      as="h3"
      className={clsx('text-lg font-medium text-slate-900', className)}
    >
      {children}
    </Dialog.Title>
  );
};

const ModalDescription: React.FC<ModalBaseProps> = ({ children, className }) => {
  return (
    <Dialog.Description
      className={clsx('mt-2 text-sm text-slate-500', className)}
    >
      {children}
    </Dialog.Description>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Title = ModalTitle;
Modal.Description = ModalDescription; 