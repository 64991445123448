import * as React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { clsx } from 'clsx'

export interface LinkProps extends RouterLinkProps {
  variant?: 'primary' | 'secondary' | 'subtle'
  className?: string
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ variant = 'primary', className, children, ...props }, ref) => {
    return (
      <RouterLink
        ref={ref}
        className={clsx(
          'font-medium transition',
          {
            'text-blue-600 hover:text-blue-500': variant === 'primary',
            'text-gray-600 hover:text-gray-500': variant === 'secondary',
            'text-gray-500 hover:text-gray-400': variant === 'subtle',
          },
          className
        )}
        {...props}
      >
        {children}
      </RouterLink>
    )
  }
) 