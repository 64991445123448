import { Modal } from '../../../ui/Modal';
import { Button } from '../../../ui/Button';
import { Card } from '../../../ui/Card';
import { Checkbox } from '../../../ui/component/checkbox';
import { MagnifyingGlassIcon, UserIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

export function AssignEmployee({
  setIsShownAssignEmployyes,
  peopleOptions,
  handleSelectPeople,
  selectedEmployees,
}: any) {
  const [searchTerm, setSearchTerm] = useState('');
  
  // Ensure peopleOptions is always an array
  const peopleList = Array.isArray(peopleOptions) ? peopleOptions : [];
  
  // Filter people based on search term
  const filteredPeople = searchTerm 
    ? peopleList.filter(person => 
        person?.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person?.email?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : peopleList;

  return (
    <>
      <Modal.Header onClose={() => setIsShownAssignEmployyes(false)}>
        <Modal.Title>Assign people</Modal.Title>
        <Modal.Description>
          Select team members to bring your department to life
        </Modal.Description>
      </Modal.Header>
      
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-slate-400" />
            </div>
            <input
              type="text"
              className="block w-full rounded-md border border-slate-300 py-2 pl-10 pr-3 text-sm placeholder:text-slate-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Search member by name or email address"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="mt-2 max-h-60 overflow-y-auto">
            {filteredPeople.length > 0 ? (
              filteredPeople.map((person) => (
                <div
                  key={person.id}
                  className="flex items-center gap-3 border-b border-slate-200 py-3"
                >
                  <div className="flex items-center">
                    <Checkbox
                      className="rounded-md border-[1px]"
                      color="rose"
                      checked={selectedEmployees.includes(person.id)}
                      onChange={(checked) =>
                        handleSelectPeople(person.id, checked)
                      }
                    />
                  </div>
                  <div className="flex items-center gap-3">
                    {person?.safe_profile_picture_url ? (
                      <img
                        className="h-8 w-8 rounded-full"
                        src={person.safe_profile_picture_url}
                        alt={person.full_name || 'User'}
                      />
                    ) : (
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-200">
                        <span className="text-xs font-medium text-slate-600">
                          {person.full_name ? person.full_name.charAt(0) : '?'}
                        </span>
                      </div>
                    )}
                    <span className="text-sm font-medium">{person.full_name || 'Unknown User'}</span>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center py-8 text-center">
                <UserIcon className="h-10 w-10 text-slate-300" />
                <p className="mt-2 text-sm text-slate-500">
                  {searchTerm ? 'No matching employees found' : 'No employees available'}
                </p>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      
      <Modal.Footer>
        <Button
          variant="outline"
          onClick={() => setIsShownAssignEmployyes(false)}
        >
          Back
        </Button>
        <Button
          color="red"
          onClick={() => setIsShownAssignEmployyes(false)}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </>
  );
}

