import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { showLoading } from 'react-global-loading';
import 'react-chatbot-kit/build/main.css';
import { HomeRoute } from '../routes/Home';
import { PeopleRoute } from '../routes/People';
import { SettingsRoute } from '../routes/Settings';
import { ProfileRoute } from '../routes/Profile';
import { CalendarRoute } from '../routes/Calendar';
import { ProtectedEventRoute } from '../routes/Event';
import { ControlPanelLayout } from '../ui/layouts/ControlPanel';
import { ErrorLayout } from '../ui/layouts/Error';
import { QueryClientProvider } from 'react-query';
import { ClerkProvider } from '@clerk/clerk-react';
import {
  NotificationContext,
  NotificationTabs,
} from '../ui/context/notification';
import { useAsync } from 'react-async';
import { ContactRoute } from '../routes/Contact';
import { DecisionConfirmationRoute } from './components/DecisionConfirmation';
import { Loader } from '../ui/Loader';
import { getConfig, getOnboardingData, getOrganisation } from '../utils';
import { useAppInitialization } from '../hooks/useAppInitialization';
import { GlobalAction } from '../reducer/GlobalReducer';
import { OnboardingLayout } from '../ui/layouts/OnboardingLayout';
import ReportRoute from '../routes/Reports';
import { JobRoute } from '../routes/Job';
import DocumentManagement from '../routes/DocumentManagement';
import { OrganizationRoute } from '../routes/Organization';
import TimeOffRoute from '../routes/TimeOff';
import { TimesheetsRoute } from '../routes/Timesheets';
import { RotaManagementRoute } from '../routes/RotaManagement';
import { ChatbotModalProvider } from '../ui/context/chatbot';
import DetailedReport from '../routes/Reports/DetailedReport';
import BradfordFactor from '../routes/Reports/BradfordFactor';
import AbsenceTrends from '../routes/Reports/AbsenceTrends';
import LeaveBalanceReport from '../routes/Reports/LeaveBalanceReport';

import { EmployeeDetailsRoute } from '../routes/TimeOff/routes/EmployeeDetailsRoute';
import ElevenLabsWidget from '../components/ElevenLabsWidget';
import { Auth0Provider } from '@auth0/auth0-react';
import AbsenteeismRiskReport from '../routes/Reports/AbsenteeismRiskReport';

export function AuthWrapper() {
  const { data: response, isPending } = useAsync({
    promiseFn: getOrganisation,
  });

  const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  if (!PUBLISHABLE_KEY) {
    throw new Error('Missing Publishable Key');
  }

  useEffect(() => {
    if (isPending) {
      showLoading(true);
    } else {
      showLoading(false);
    }
  }, [response, isPending]);

  if (isPending) return <Loader />;
  if (response)
    return (
      <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
        <ChatbotModalProvider>
          <App />
        </ChatbotModalProvider>
      </ClerkProvider>
    );

  return <Loader />;
}

export function App() {
  const { loaderActive, queryClient, state, dispatch, token } =
    useAppInitialization();

  const [onBoardingData, setOnBoardingData] = useState(null);
  const [onBoardingIsPending, setOnBoardingIsPending] = useState(true);
  const [showWidget, setShowWidget] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          await getConfig(token);
          const data = await getOnboardingData(token);
          setOnBoardingData(data);
          setOnBoardingIsPending(false);
        }
      } catch (error) {
        console.error('Error fetching onboarding data:', error);
        setOnBoardingIsPending(false);
      }
    };

    fetchData();
  }, [token]);

  if (loaderActive || onBoardingIsPending) {
    return <Loader />;
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <NotificationContext.Provider
        value={{
          isActive: (tab: NotificationTabs) => state.notification.auth.login,
          setActive: (tab: NotificationTabs) =>
            dispatch({ type: GlobalAction.ActivateNotification, value: tab }),
        }}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            {
              <Routes>
                <Route path="/onboarding" element={<OnboardingLayout />} />
                <Route
                  path="/timeaway/:id"
                  element={
                    <ControlPanelLayout>
                      <ProtectedEventRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/"
                  element={
                    <ControlPanelLayout>
                      <HomeRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <ControlPanelLayout>
                      <CalendarRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/timeaway/:id/:decision"
                  element={
                    <ControlPanelLayout>
                      <DecisionConfirmationRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/time-off"
                  element={
                    <ControlPanelLayout>
                      <TimeOffRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/time-off/:id"
                  element={
                    <ControlPanelLayout>
                      <EmployeeDetailsRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ControlPanelLayout>
                      <ProfileRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/profile/:id"
                  element={
                    <ControlPanelLayout>
                      <ProfileRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ControlPanelLayout>
                      <SettingsRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/report"
                  element={
                    <ControlPanelLayout>
                      <ReportRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/reports/:id"
                  element={
                    <ControlPanelLayout>
                      <DetailedReport />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/reports/bradford-factor"
                  element={
                    <ControlPanelLayout>
                      <BradfordFactor />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/reports/absence-trends"
                  element={
                    <ControlPanelLayout>
                      <AbsenceTrends />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/reports/leave-balance"
                  element={
                    <ControlPanelLayout>
                      <LeaveBalanceReport />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/reports/absenteeism-risk"
                  element={
                    <ControlPanelLayout>
                      <AbsenteeismRiskReport />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/people"
                  element={
                    <ControlPanelLayout>
                      <PeopleRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/ats"
                  element={
                    <ControlPanelLayout>
                      <JobRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/document-management"
                  element={
                    <ControlPanelLayout>
                      <DocumentManagement />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/organization"
                  element={
                    <ControlPanelLayout>
                      <OrganizationRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/timesheets"
                  element={
                    <ControlPanelLayout>
                      <TimesheetsRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route
                  path="/rota-management"
                  element={
                    <ControlPanelLayout>
                      <RotaManagementRoute />
                    </ControlPanelLayout>
                  }
                />
                <Route path="*" element={<ErrorLayout goHome code={404} />} />
              </Routes>
            }
          </BrowserRouter>
        </QueryClientProvider>
      </NotificationContext.Provider>
    </Auth0Provider>
  );
}
