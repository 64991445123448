import { useEffect, useState } from 'react';
import { Select } from '../../../../../../ui/form/Select';
import { Input } from '../../../../../../ui/form/Input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FooterSection } from '../../../FooterSection';
import onboardingProcess from '../../../../../../network/onboardingProcess';
import { OnboardingStep } from '../../../../../../ui/layouts/OnboardingLayout';

const workingOptions = [
  {
    id: 'full_time',
    label: 'Full time',
  },
  {
    id: 'part_time',
    label: 'Part-time',
  },
  {
    id: 'self_employed',
    label: 'Self-employed',
  },
  {
    id: 'freelance',
    label: 'Freelance',
  },
  {
    id: 'contract',
    label: 'Contract',
  },
  {
    id: 'internship',
    label: 'Internship',
  },
  {
    id: 'apprecticeship',
    label: 'Apprecticeship',
  },
  {
    id: 'seasonal',
    label: 'Seasonal',
  },
];

export function AbsenceMgmt({ handleNext, handleSkipForNow }: any) {
  const [isFormClear, setIsFormClear] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    unpaid_leave: '',
    paid_leave: '',
    sick_leave: '',
    parental_leave: '',
    maternity_leave: '',
    paternity_leave: '',
    adoption_leave: '',
    bereavement_leave: '',
    work_from_home: '',
  });

  const formik = useFormik({
    initialValues: {
      unpaid_leave: formData.unpaid_leave || '',
      paid_leave: formData.paid_leave || '',
      sick_leave: formData.sick_leave || '',
      parental_leave: formData.parental_leave || '',
      maternity_leave: formData.maternity_leave || '',
      paternity_leave: formData.paternity_leave || '',
      adoption_leave: formData.adoption_leave || '',
      bereavement_leave: formData.bereavement_leave || '',
      work_from_home: formData.work_from_home || '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      working_options: yup.string(),
      unpaid_leave: yup.string(),
      paid_leave: yup.string().required("Paid Holiday is required"),
      sick_leave: yup.string().required("Sick Leave is required"),
      parental_leave: yup.string(),
      maternity_leave: yup.string(),
      paternity_leave: yup.string(),
      adoption_leave: yup.string(),
      bereavement_leave: yup.string(),
      work_from_home: yup.string().required("Work From Home is required"),
    }),
    onSubmit: async (data) => {
      await onboardingProcess.onboardingProcess(
        OnboardingStep.AbsenceManagementConfiguration,
        {
          ...data,
          import_method: 'manual'
        },
      );
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.handleSubmit(event);
    if (isFormClear) {
      handleNext();
    }
  };

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;

    // Only check the fields that are displayed in the UI
    const requiredFields = ['paid_leave', 'sick_leave', 'work_from_home'];
    const isFormFilled = requiredFields.every(
      (field) => formik.values[field as keyof typeof formik.values] !== ''
    );

    if (isFormValid && isFormFilled) {
      const updatedValues = Object.fromEntries(
        Object.entries(formik.values).filter(([key, value]) => value !== ''),
      );

      const updatedFormData = { ...formData, ...updatedValues };
      setFormData(updatedFormData);
      setIsFormClear(true);
    } else {
      setIsFormClear(false);
    }
  }, [formik.errors, formik.values]);

  useEffect(() => {
    async function getInitialData() {
      try {
        const { data } = await onboardingProcess.getOnboardingData(
          OnboardingStep.AbsenceManagementConfiguration,
        );
        
        // Add proper null checks for the response structure
        const details = data?.data?.details || {};
        
        setFormData({
          unpaid_leave: details.unpaid_leave || '',
          paid_leave: details.paid_leave || '',
          sick_leave: details.sick_leave || '',
          parental_leave: details.parental_leave || '',
          maternity_leave: details.maternity_leave || '',
          paternity_leave: details.paternity_leave || '',
          adoption_leave: details.adoption_leave || '',
          bereavement_leave: details.bereavement_leave || '',
          work_from_home: details.work_from_home || '',
        });
      } catch (error) {
        console.error("Error fetching initial data:", error);
        // Keep the default empty values from useState
      }
    }
    getInitialData();
  }, []);

  return (
    <div className="flex h-[86vh] flex-col justify-between overflow-y-auto">
      <div className="mr-2">
        <h3 className="my-4 text-lg font-semibold">Leave Policy Setup</h3>
        <div>
          <label
            htmlFor="paid_leave"
            className="mb-1 block text-base font-medium"
          >
            Paid Holiday
          </label>
          <Input
            type="text"
            placeholder="Ex: 10, 50, 100"
            id="paid_leave"
            name="paid_leave"
            value={formik.values.paid_leave}
            onChange={formik.handleChange}
            error={findError('paid_leave')}
          />
        </div>
        <div className="mt-3">
          <label
            htmlFor="sick_leave"
            className="mb-1 block text-base font-medium"
          >
            Sick Leave
          </label>
          <Input
            type="text"
            placeholder="Ex: 10, 50, 100"
            id="sick_leave"
            name="sick_leave"
            value={formik.values.sick_leave}
            onChange={formik.handleChange}
            error={findError('sick_leave')}
          />
        </div>
        <div className="mt-3">
          <label
            htmlFor="work_from_home"
            className="mb-1 block text-base font-medium"
          >
            Work From Home
          </label>
          <Input
            type="text"
            placeholder="Ex: 10, 50, 100"
            id="work_from_home"
            name="work_from_home"
            value={formik.values.work_from_home}
            onChange={formik.handleChange}
            error={findError('work_from_home')}
          />
        </div>
      </div>
      <FooterSection
        handleSkipForNow={handleSkipForNow}
        nextHandle={handleSubmit}
      />
    </div>
  );
}
