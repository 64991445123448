import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ProfileHolidayRequest from './components/ProfileHolidayRequest';
import ProfileDescription from './components/ProfileDescription';
import { useProfileDetails } from '../../hooks/useProfileDetails';
import { useAuth } from '../../hooks/useAuth';
// @ts-ignore
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/catalyst/tabs';
import { Avatar } from '../../ui/component/avatar';

const profile = {
  coverImageUrl:
    'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
};

export function ProfileRoute() {
  const { id } = useParams();
  const { user } = useAuth();
  const profileId = id || user?.id;
  const { data: profileData } = useProfileDetails(profileId);
  const [activeTab, setActiveTab] = useState("profile");

  // Get initials for avatar fallback
  const getInitials = () => {
    if (!profileData) return '';
    const names = profileData.full_name?.split(' ') || [];
    return names.length > 1 
      ? (names[0][0] + names[names.length - 1][0]).toUpperCase() 
      : names[0]?.[0]?.toUpperCase() || '';
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="bg-white">
        <div>
          <img
            className="h-32 w-full object-cover lg:h-48"
            src={profile?.coverImageUrl}
            alt="Profile cover"
          />
        </div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <Avatar
                src={profileData?.safe_profile_picture_url}
                alt={profileData?.full_name || ""}
                initials={getInitials()}
                className="h-24 w-24 ring-4 ring-white sm:h-32 sm:w-32"
              />
            </div>
            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {profileData?.full_name}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">
              {profileData?.full_name}
            </h1>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 mt-8">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <div className="mb-5">
            <div className="mx-auto max-w-5xl">
              <TabsList className="w-full">
                <TabsTrigger value="profile" className="flex-1">Profile</TabsTrigger>
                <TabsTrigger value="holiday-requests" className="flex-1">Holiday Requests</TabsTrigger>
              </TabsList>
            </div>
          </div>
          <div className="mt-4">
            <TabsContent value="profile">
              <ProfileDescription data={profileData} />
            </TabsContent>
            <TabsContent value="holiday-requests">
              <ProfileHolidayRequest />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
}
