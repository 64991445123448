import {
  ArrowDownTrayIcon,
  ArrowRightIcon,
  CalendarDaysIcon,
  ChevronLeftIcon,
  ClockIcon,
  DocumentIcon,
  EyeIcon,
  HashtagIcon,
  UserIcon,
} from '@heroicons/react/16/solid';
import {
  ArrowPathIcon,
  CheckCircleIcon,
  UserCircleIcon,
  XCircleIcon,
} from '@heroicons/react/16/solid';
import {
  HomeIcon, // Using 20px icon for navigation
} from '@heroicons/react/20/solid';
import axios from 'axios';
import { addDays, format, startOfWeek } from 'date-fns';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { Popover } from '@headlessui/react';
import { motion } from 'framer-motion';

// Import locally created Catalyst components with path aliases
import { Button } from '../../../components/catalyst/button';
import { Card } from '../../../components/catalyst/card';
import { Link } from '../../../components/catalyst/link';
import { Badge } from '../../../components/catalyst/badge';

const { REACT_APP_API_HOST } = process.env;

const today = new Date();
const startDate = startOfWeek(today, { weekStartsOn: 1 });

// First, let's add an interface for the absentee data
interface Absentee {
  id: string;
  name: string;
  role: string;
  email: string;
  team: string;
  leave_type: string;
}

interface DayAbsentees {
  date: string;
  absentees: Absentee[];
}

// Add interface for currentUser
interface CurrentUser {
  applicant: string;
  email: string;
  start_date: string;
  end_date: string;
  days_requested: string;
  type: string;
  documents: Array<{ name: string; id: string }>;
}

// Update the interface to match the API response
interface ApiResponse {
  status: string;
  total: number;
  absentees: DayAbsentees[];
}

// Add interface for shift times
interface ShiftTimes {
  start: string;
  end: string;
}

// Update the days interface
interface DayData {
  day: string;
  weekday: string;
  date: string;
  employees: number;
  absent: number;
  absentees: Absentee[];
  shift?: ShiftTimes;
}

export function EmployeeDetails({ 
  currentUser 
}: { 
  currentUser: CurrentUser;
}) {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState<number | null>(null);

  const { data, isLoading, isError } = useQuery<ApiResponse>({
    queryKey: ['absentees', startDate.toISOString()],
    queryFn: async () => {
      const { data } = await axios.post(`${REACT_APP_API_HOST}/v1/timeaway/absentees`, { 
        date: startDate 
      });
      return data;
    },
  });

  const days = useMemo<DayData[]>(() => 
    Array.from({ length: 14 }, (_, i) => {
      const date = addDays(startDate, i);
      const formattedDate = format(date, 'yyyy-MM-dd');
      const dayAbsentees = data?.absentees?.find((day) => day.date === formattedDate);
      
      return {
        day: format(date, 'd'),
        weekday: format(date, 'E'),
        date: formattedDate,
        employees: data?.total || 0,
        absent: dayAbsentees?.absentees?.length || 0,
        absentees: dayAbsentees?.absentees || [],
        shift: { start: '09:00AM', end: '06:00PM' }
      };
    }),
    [data?.absentees]
  );

  if (isError) {
    return (
      <div className="flex justify-center p-4 text-red-500">
        Error loading calendar data
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="mt-5">
        <Button
          variant="ghost"
          onClick={() => navigate('/time-off')}
          className="flex items-center gap-2"
        >
          <ChevronLeftIcon className="h-4 w-4" />
          Back to page
        </Button>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
            <UserCircleIcon className="h-8 w-8 text-gray-500" />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">{currentUser.applicant}</h2>
            <p className="text-sm text-gray-500">{currentUser.email}</p>
          </div>
        </div>

        <div className="flex gap-3">
          <Button variant="secondary" color="amber">
            <ArrowPathIcon className="h-4 w-4 -translate-y-px" />
            Request Changes
          </Button>
          <Button variant="secondary" color="red">
            <XCircleIcon className="h-4 w-4 -translate-y-px" />
            Reject
          </Button>
          <Button variant="secondary" color="green">
            <CheckCircleIcon className="h-4 w-4 -translate-y-px" />
            Approve
          </Button>
        </div>
      </div>

      <Card>
        <Card.Header>
          <Card.Title>Leave Details</Card.Title>
        </Card.Header>
        <Card.Content className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <CalendarDaysIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm font-medium text-gray-700">Dates Requested</span>
            </div>
            <Badge variant="outline" className="text-sm">
              {currentUser.start_date} - {currentUser.end_date}
            </Badge>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <ClockIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm font-medium text-gray-700">Duration</span>
            </div>
            <Badge variant="outline" className="text-sm">
              {currentUser.days_requested}
            </Badge>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <HashtagIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm font-medium text-gray-700">Leave type</span>
            </div>
            <Badge variant="outline" className="text-sm">
              {currentUser.type}
            </Badge>
          </div>

          <div className="flex items-start justify-between">
            <div className="flex items-center gap-2">
              <DocumentIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm font-medium text-gray-700">Supporting Documents</span>
            </div>
            <div className="flex flex-col gap-2">
              {currentUser.documents.length > 0 ? (
                currentUser.documents.map((document) => (
                  <div key={document.id} className="flex items-center gap-2">
                    <Badge variant="outline" className="text-sm">
                      {document.name}
                    </Badge>
                    <div className="flex items-center">
                      <Button size="xs" variant="ghost" className="px-2 py-1" title="View document">
                        <EyeIcon className="h-4 w-4" />
                      </Button>
                      <Button size="xs" variant="ghost" className="px-2 py-1" title="Download document">
                        <ArrowDownTrayIcon className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <span className="text-sm text-gray-500">No documents attached</span>
              )}
            </div>
          </div>
        </Card.Content>
      </Card>

      <div>
        <h3 className="mb-3 font-medium text-gray-900">Team calendar</h3>
        <Card>
          <Card.Header className="bg-gray-50">
            <Card.Title className="text-sm font-semibold text-gray-900">
              {format(today, 'd MMM yyyy')} - {format(addDays(today, 13), 'd MMM yyyy')}
            </Card.Title>
          </Card.Header>
          <Card.Content className="p-0">
            <div className="grid grid-cols-7 border-t border-l">
              {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(
                (day, index) => (
                  <div
                    key={index}
                    className="border-b border-r p-2 text-center font-medium text-sm text-gray-700"
                  >
                    {day}
                  </div>
                ),
              )}
              {days.map((day, index) => (
                <div key={index} className="border-b border-r">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={clsx(
                            "w-full cursor-pointer p-3 text-left focus:outline-none",
                            selectedDay === index ? "bg-red-50" : "",
                            open ? "bg-gray-50" : ""
                          )}
                          onClick={() => setSelectedDay(index)}
                        >
                          <div className="flex min-h-[70px] flex-col gap-2">
                            <span className="text-gray-600">{day.day}</span>
                            <div>
                              <p className="text-xs text-gray-500">
                                {day.employees} Employees
                              </p>
                              {day.absent > 0 && (
                                <p className="text-xs text-gray-700">{day.absent} Employees absent</p>
                              )}
                            </div>
                          </div>
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10 mt-2 w-72 rounded-md border bg-white shadow-lg">
                          <div className="p-4">
                            <h4 className="text-sm font-medium text-gray-900">Shift details</h4>
                            <div className="mt-2 flex items-center gap-2">
                              <span className="rounded-md border px-4 py-1 text-sm">
                                {days[index]?.shift?.start || '09:00AM'}
                              </span>
                              <ArrowRightIcon className="h-4 w-4 text-gray-500" />
                              <span className="rounded-md border px-4 py-1 text-sm">
                                {days[index]?.shift?.end || '06:00PM'}
                              </span>
                            </div>
                            <div className="mt-3 rounded-md bg-gray-50 px-3 py-2">
                              <p className="text-sm font-medium text-gray-700">
                                {days[index]?.absent || 0} Employees Absent
                              </p>
                            </div>

                            <div className="mt-3 max-h-[200px] overflow-y-auto">
                              {days[index]?.absentees.map((user) => (
                                <div key={user.id} className="flex items-center gap-2 border-b py-2">
                                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100">
                                    <UserIcon className="h-4 w-4 text-gray-500" />
                                  </div>
                                  <div className="text-sm">
                                    <p className="font-medium text-gray-900">{user.name}</p>
                                    <p className="text-gray-500">{user.team} - {user.leave_type}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </Popover>
                </div>
              ))}
            </div>
          </Card.Content>
        </Card>
      </div>
    </div>
  );
}
