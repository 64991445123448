import React, { useState, useEffect } from 'react';
import { 
  Card,
  Title,
  Text,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Select,
  SelectItem,
  DateRangePicker,
  DateRangePickerItem,
  DateRangePickerValue,
  MultiSelect,
  MultiSelectItem,
  Button,
  Badge,
  ProgressBar
} from '@tremor/react';
import { 
  RiDownload2Line,
  RiFilter3Line,
  RiRefreshLine,
  RiUserLine,
  RiTeamLine,
  RiMapPinLine,
  RiCalendarLine
} from '@remixicon/react';
import { reportService } from '../../services/report';
import { 
  LeaveBalanceRequest, 
  LeaveBalanceResponse,
  EmployeeLeaveBalance,
  ApiResponse
} from '../../types/report.types';
import { WORKING_OPTIONS } from '../../constants';
import { exportToExcel, exportToPdf } from '../../utils/export';

// Map leave types to user-friendly names
const leaveTypeNames: Record<string, string> = {
  'holiday_paid': 'Annual Leave',
  'holiday_unpaid': 'Unpaid Leave',
  'sick_leave': 'Sick Leave',
  'parental_leave': 'Parental Leave',
  'other_leave': 'Other Leave'
};

// Default date ranges
const dateRanges: { name: string; value: DateRangePickerValue }[] = [
  {
    name: "Year to date",
    value: {
      from: new Date(new Date().getFullYear(), 0, 1),
      to: new Date(),
    },
  },
  {
    name: "Last 30 days",
    value: {
      from: new Date(new Date().setDate(new Date().getDate() - 30)),
      to: new Date(),
    },
  },
  {
    name: "Last 90 days",
    value: {
      from: new Date(new Date().setDate(new Date().getDate() - 90)),
      to: new Date(),
    },
  },
  {
    name: "Last year",
    value: {
      from: new Date(new Date().getFullYear() - 1, 0, 1),
      to: new Date(new Date().getFullYear() - 1, 11, 31),
    },
  },
];

const LeaveBalanceReport: React.FC = () => {
  // State for filters
  const [dateRange, setDateRange] = useState<DateRangePickerValue>(dateRanges[0].value);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [selectedLocation, setSelectedLocation] = useState<string>('all');
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState<string[]>([]);
  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState<string[]>(
    ['holiday_paid', 'holiday_unpaid', 'sick_leave', 'parental_leave', 'other_leave']
  );
  const [showForecast, setShowForecast] = useState<boolean>(false);
  const [forecastDate, setForecastDate] = useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() + 3))
  );
  
  // State for data
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leaveBalanceData, setLeaveBalanceData] = useState<LeaveBalanceResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  
  // State for dropdown options
  const [departments, setDepartments] = useState<{id: string; name: string}[]>([]);
  const [locations, setLocations] = useState<{id: string; name: string}[]>([]);
  
  // Fetch departments and locations on component mount
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const departmentsResp = await reportService.getDepartments();
        if (departmentsResp.status === 'GET_DEPARTMENTS_SUCCESS' && departmentsResp.payload.departments) {
          setDepartments(departmentsResp.payload.departments);
        }
        
        const locationsResp = await reportService.getLocations();
        if (locationsResp.status === 'GET_LOCATIONS_SUCCESS' && locationsResp.payload.locations) {
          setLocations(locationsResp.payload.locations);
        }
      } catch (err) {
        console.error('Error fetching filter options:', err);
      }
    };
    
    fetchFilters();
  }, []);
  
  // Fetch leave balance data whenever filters change
  useEffect(() => {
    if (!dateRange.from || !dateRange.to) return;
    
    const fetchLeaveBalanceData = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const request: LeaveBalanceRequest = {
          startDate: dateRange.from?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
          endDate: dateRange.to?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
          department: selectedDepartment,
          location: selectedLocation,
          employmentType: selectedEmploymentTypes.length > 0 ? selectedEmploymentTypes : undefined,
          leaveTypes: selectedLeaveTypes.length > 0 ? selectedLeaveTypes : undefined,
          forecastDate: showForecast && forecastDate 
            ? forecastDate.toISOString().split('T')[0] 
            : undefined
        };
        
        const response = await reportService.getLeaveBalanceReport(request);
        
        if (response.status === 'LEAVE_BALANCE_SUCCESS' && response.payload.leave_balance) {
          setLeaveBalanceData(response.payload.leave_balance);
        } else {
          setError('Failed to fetch leave balance data');
        }
      } catch (err) {
        console.error('Error fetching leave balance data:', err);
        setError('An error occurred while fetching data');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchLeaveBalanceData();
  }, [
    dateRange, 
    selectedDepartment, 
    selectedLocation, 
    selectedEmploymentTypes, 
    selectedLeaveTypes,
    showForecast && forecastDate
  ]);
  
  // Handle export to Excel
  const handleExportExcel = () => {
    if (!leaveBalanceData) return;
    
    // Prepare data for export
    const exportData = leaveBalanceData.employees.flatMap(employee => 
      employee.balances.map(balance => ({
        'Employee ID': employee.employeeId,
        'Name': `${employee.firstName} ${employee.lastName}`,
        'Department': employee.department,
        'Role': employee.role,
        'Employment Type': employee.employmentType,
        'Location': employee.location,
        'Leave Type': leaveTypeNames[balance.leaveType] || balance.leaveType,
        'Entitled Days': balance.entitled,
        'Accrued Days': balance.accrued,
        'Used Days': balance.used,
        'Remaining Days': balance.remaining,
        'Carry Over Days': balance.carryOver,
        ...showForecast && { 'Forecast (Future Balance)': balance.forecast || 0 }
      }))
    );
    
    exportToExcel(
      exportData, 
      `Leave_Balance_Report_${new Date().toISOString().split('T')[0]}`
    );
  };
  
  // Handle export to PDF
  const handleExportPdf = () => {
    if (!leaveBalanceData) return;
    
    // Prepare data for export
    const title = `Leave Balance Report - ${dateRange.from?.toDateString()} to ${dateRange.to?.toDateString()}`;
    
    const tableHeaders = [
      'Employee', 
      'Department', 
      'Leave Type', 
      'Entitled', 
      'Used', 
      'Remaining',
      ...(showForecast ? ['Forecast'] : [])
    ];
    
    const tableData = leaveBalanceData.employees.flatMap(employee => 
      employee.balances.map(balance => [
        `${employee.firstName} ${employee.lastName}`,
        employee.department,
        leaveTypeNames[balance.leaveType] || balance.leaveType,
        balance.entitled.toString(),
        balance.used.toString(),
        balance.remaining.toString(),
        ...(showForecast ? [(balance.forecast || 0).toString()] : [])
      ])
    );
    
    exportToPdf(
      title,
      tableHeaders,
      tableData,
      `Leave_Balance_Report_${new Date().toISOString().split('T')[0]}`
    );
  };
  
  // Get summary for each leave type
  const getLeaveTypeSummary = (leaveType: string) => {
    if (!leaveBalanceData) return null;
    
    const distribution = leaveBalanceData.summary.leaveTypeDistribution[leaveType];
    if (!distribution) return null;
    
    return {
      totalEntitled: distribution.totalEntitled,
      totalUsed: distribution.totalUsed,
      utilization: distribution.averageUtilization,
      average: leaveBalanceData.summary.totalEmployees > 0 
        ? (distribution.totalEntitled / leaveBalanceData.summary.totalEmployees).toFixed(1) 
        : '0'
    };
  };
  
  return (
    <div className="space-y-6 p-4">
      <Card>
        <div className="flex justify-between items-center mb-6">
          <div>
            <Title>Leave Balance Report</Title>
            <Text>Current time-off balances and accruals for all employees</Text>
          </div>
          <div className="flex gap-2">
            <Button
              icon={RiDownload2Line}
              variant="secondary"
              onClick={handleExportExcel}
              disabled={isLoading || !leaveBalanceData}
            >
              Excel
            </Button>
            <Button
              icon={RiDownload2Line}
              variant="secondary" 
              onClick={handleExportPdf}
              disabled={isLoading || !leaveBalanceData}
            >
              PDF
            </Button>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div>
            <Text className="mb-2">Date Range</Text>
            <DateRangePicker
              className="w-full"
              value={dateRange}
              onValueChange={setDateRange}
              enableSelect={true}
              selectPlaceholder="Select range"
              placeholder="Filter by date"
              color="rose"
            >
              {dateRanges.map((dateRange) => (
                <DateRangePickerItem
                  key={dateRange.name}
                  value={dateRange.name}
                  from={dateRange.value.from || new Date()}
                  to={dateRange.value.to || new Date()}
                >
                  {dateRange.name}
                </DateRangePickerItem>
              ))}
            </DateRangePicker>
          </div>
          
          <div>
            <Text className="mb-2">Department</Text>
            <Select
              className="w-full"
              value={selectedDepartment}
              onValueChange={setSelectedDepartment}
              placeholder="Select department"
            >
              <SelectItem value="all">All Departments</SelectItem>
              {departments.map((dept) => (
                <SelectItem key={dept.id} value={dept.id}>
                  {dept.name}
                </SelectItem>
              ))}
            </Select>
          </div>
          
          <div>
            <Text className="mb-2">Location</Text>
            <Select
              className="w-full"
              value={selectedLocation}
              onValueChange={setSelectedLocation}
              placeholder="Select location"
            >
              <SelectItem value="all">All Locations</SelectItem>
              {locations.map((loc) => (
                <SelectItem key={loc.id} value={loc.id}>
                  {loc.name}
                </SelectItem>
              ))}
            </Select>
          </div>
          
          <div>
            <Text className="mb-2">Employment Type</Text>
            <MultiSelect
              className="w-full"
              value={selectedEmploymentTypes}
              onValueChange={setSelectedEmploymentTypes}
              placeholder="Select employment types"
            >
              {WORKING_OPTIONS.map((option) => (
                <MultiSelectItem key={option.value} value={option.value}>
                  {option.title}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div>
            <Text className="mb-2">Leave Types</Text>
            <MultiSelect
              className="w-full"
              value={selectedLeaveTypes}
              onValueChange={setSelectedLeaveTypes}
              placeholder="Select leave types"
            >
              {Object.entries(leaveTypeNames).map(([value, label]) => (
                <MultiSelectItem key={value} value={value}>
                  {label}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </div>
          
          <div>
            <Text className="mb-2">Show Forecast</Text>
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                id="showForecast"
                checked={showForecast}
                onChange={(e) => setShowForecast(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="showForecast">Show forecasted balances</label>
            </div>
          </div>
          
          {showForecast && (
            <div>
              <Text className="mb-2">Forecast Date</Text>
              <input
                type="date"
                title="Forecast Date"
                placeholder="Select forecast date"
                value={forecastDate?.toISOString().split('T')[0] || ''}
                onChange={(e) => setForecastDate(e.target.value ? new Date(e.target.value) : null)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
              />
            </div>
          )}
          
          <div className="flex items-end">
            <Button
              icon={RiRefreshLine}
              variant="primary"
              loading={isLoading}
              onClick={() => {
                // Force refresh
                const fetchData = async () => {
                  setIsLoading(true);
                  setError(null);
                  
                  try {
                    const request: LeaveBalanceRequest = {
                      startDate: dateRange.from?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
                      endDate: dateRange.to?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
                      department: selectedDepartment,
                      location: selectedLocation,
                      employmentType: selectedEmploymentTypes.length > 0 ? selectedEmploymentTypes : undefined,
                      leaveTypes: selectedLeaveTypes.length > 0 ? selectedLeaveTypes : undefined,
                      forecastDate: showForecast && forecastDate 
                        ? forecastDate.toISOString().split('T')[0] 
                        : undefined
                    };
                    
                    const response = await reportService.getLeaveBalanceReport(request);
                    
                    if (response.status === 'LEAVE_BALANCE_SUCCESS' && response.payload.leave_balance) {
                      setLeaveBalanceData(response.payload.leave_balance);
                    } else {
                      setError('Failed to fetch leave balance data');
                    }
                  } catch (err) {
                    console.error('Error fetching leave balance data:', err);
                    setError('An error occurred while fetching data');
                  } finally {
                    setIsLoading(false);
                  }
                };
                
                fetchData();
              }}
            >
              Refresh
            </Button>
          </div>
        </div>
      </Card>
      
      {error && (
        <Card>
          <div className="text-red-500">{error}</div>
        </Card>
      )}
      
      {isLoading ? (
        <Card>
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-rose-500"></div>
          </div>
        </Card>
      ) : leaveBalanceData ? (
        <>
          {/* Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <Card>
              <div className="flex justify-between">
                <div>
                  <Text>Total Employees</Text>
                  <Title>{leaveBalanceData.summary.totalEmployees}</Title>
                </div>
                <RiUserLine className="h-10 w-10 text-rose-500" />
              </div>
            </Card>
            
            {selectedLeaveTypes.includes('holiday_paid') && (
              <Card>
                <div className="flex justify-between">
                  <div>
                    <Text>Average Annual Leave</Text>
                    <Title>
                      {getLeaveTypeSummary('holiday_paid')?.average || '0'} days
                    </Title>
                  </div>
                  <RiCalendarLine className="h-10 w-10 text-rose-500" />
                </div>
                <ProgressBar 
                  value={getLeaveTypeSummary('holiday_paid')?.utilization || 0} 
                  color="rose" 
                  className="mt-3"
                />
                <Text className="text-xs mt-1">
                  Utilization: {getLeaveTypeSummary('holiday_paid')?.utilization || 0}%
                </Text>
              </Card>
            )}
            
            {selectedLeaveTypes.includes('sick_leave') && (
              <Card>
                <div className="flex justify-between">
                  <div>
                    <Text>Average Sick Leave</Text>
                    <Title>
                      {getLeaveTypeSummary('sick_leave')?.average || '0'} days
                    </Title>
                  </div>
                  <RiCalendarLine className="h-10 w-10 text-rose-500" />
                </div>
                <ProgressBar 
                  value={getLeaveTypeSummary('sick_leave')?.utilization || 0} 
                  color="rose" 
                  className="mt-3"
                />
                <Text className="text-xs mt-1">
                  Utilization: {getLeaveTypeSummary('sick_leave')?.utilization || 0}%
                </Text>
              </Card>
            )}
            
            <Card>
              <div className="flex justify-between">
                <div>
                  <Text>Report Period</Text>
                  <Title className="text-sm">
                    {new Date(leaveBalanceData.period.start).toLocaleDateString()} - {new Date(leaveBalanceData.period.end).toLocaleDateString()}
                  </Title>
                </div>
                <RiCalendarLine className="h-10 w-10 text-rose-500" />
              </div>
              {leaveBalanceData.period.forecastDate && (
                <div className="mt-2">
                  <Badge color="orange">
                    Forecast to: {new Date(leaveBalanceData.period.forecastDate).toLocaleDateString()}
                  </Badge>
                </div>
              )}
            </Card>
          </div>
          
          {/* Main Table */}
          <Card>
            <TabGroup>
              <TabList className="mb-6">
                <Tab>Employee View</Tab>
                <Tab>Leave Type View</Tab>
              </TabList>
              
              <TabPanels>
                {/* Employee View */}
                <TabPanel>
                  <div className="overflow-x-auto">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeaderCell>Employee</TableHeaderCell>
                          <TableHeaderCell>Department</TableHeaderCell>
                          <TableHeaderCell>Role</TableHeaderCell>
                          <TableHeaderCell>Employment Type</TableHeaderCell>
                          <TableHeaderCell>Location</TableHeaderCell>
                          {selectedLeaveTypes.map(leaveType => (
                            <React.Fragment key={leaveType}>
                              <TableHeaderCell>
                                {leaveTypeNames[leaveType] || leaveType} (Entitled)
                              </TableHeaderCell>
                              <TableHeaderCell>
                                {leaveTypeNames[leaveType] || leaveType} (Used)
                              </TableHeaderCell>
                              <TableHeaderCell>
                                {leaveTypeNames[leaveType] || leaveType} (Remaining)
                              </TableHeaderCell>
                              {showForecast && (
                                <TableHeaderCell>
                                  {leaveTypeNames[leaveType] || leaveType} (Forecast)
                                </TableHeaderCell>
                              )}
                            </React.Fragment>
                          ))}
                        </TableRow>
                      </TableHead>
                      
                      <TableBody>
                        {leaveBalanceData.employees.map((employee) => (
                          <TableRow key={employee.employeeId}>
                            <TableCell>{employee.firstName} {employee.lastName}</TableCell>
                            <TableCell>{employee.department}</TableCell>
                            <TableCell>{employee.role}</TableCell>
                            <TableCell>{employee.employmentType}</TableCell>
                            <TableCell>{employee.location}</TableCell>
                            
                            {selectedLeaveTypes.map(leaveType => {
                              const balance = employee.balances.find(b => b.leaveType === leaveType);
                              return (
                                <React.Fragment key={leaveType}>
                                  <TableCell>{balance?.entitled || 0}</TableCell>
                                  <TableCell>{balance?.used || 0}</TableCell>
                                  <TableCell>
                                    <Badge color={
                                      !balance ? 'gray' :
                                      balance.remaining <= 0 ? 'red' :
                                      balance.remaining < balance.entitled * 0.25 ? 'amber' :
                                      'green'
                                    }>
                                      {balance?.remaining || 0}
                                    </Badge>
                                  </TableCell>
                                  {showForecast && (
                                    <TableCell>{balance?.forecast || 0}</TableCell>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </TabPanel>
                
                {/* Leave Type View */}
                <TabPanel>
                  <div className="space-y-8">
                    {selectedLeaveTypes.map(leaveType => {
                      const summary = getLeaveTypeSummary(leaveType);
                      return (
                        <div key={leaveType} className="space-y-4">
                          <div className="flex justify-between items-center">
                            <Title>{leaveTypeNames[leaveType] || leaveType}</Title>
                            <div className="flex items-center gap-4">
                              <div className="text-right">
                                <Text>Total Entitled</Text>
                                <Text className="font-medium">{summary?.totalEntitled || 0} days</Text>
                              </div>
                              <div className="text-right">
                                <Text>Total Used</Text>
                                <Text className="font-medium">{summary?.totalUsed || 0} days</Text>
                              </div>
                              <div className="text-right">
                                <Text>Utilization</Text>
                                <Text className="font-medium">{summary?.utilization || 0}%</Text>
                              </div>
                            </div>
                          </div>
                          
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableHeaderCell>Employee</TableHeaderCell>
                                <TableHeaderCell>Department</TableHeaderCell>
                                <TableHeaderCell>Employment Type</TableHeaderCell>
                                <TableHeaderCell>Entitled</TableHeaderCell>
                                <TableHeaderCell>Accrued</TableHeaderCell>
                                <TableHeaderCell>Used</TableHeaderCell>
                                <TableHeaderCell>Remaining</TableHeaderCell>
                                <TableHeaderCell>Carry Over</TableHeaderCell>
                                {showForecast && (
                                  <TableHeaderCell>Forecast</TableHeaderCell>
                                )}
                              </TableRow>
                            </TableHead>
                            
                            <TableBody>
                              {leaveBalanceData.employees.map((employee) => {
                                const balance = employee.balances.find(b => b.leaveType === leaveType);
                                if (!balance) return null;
                                
                                return (
                                  <TableRow key={`${employee.employeeId}-${leaveType}`}>
                                    <TableCell>{employee.firstName} {employee.lastName}</TableCell>
                                    <TableCell>{employee.department}</TableCell>
                                    <TableCell>{employee.employmentType}</TableCell>
                                    <TableCell>{balance.entitled}</TableCell>
                                    <TableCell>{balance.accrued}</TableCell>
                                    <TableCell>{balance.used}</TableCell>
                                    <TableCell>
                                      <Badge color={
                                        balance.remaining <= 0 ? 'red' :
                                        balance.remaining < balance.entitled * 0.25 ? 'amber' :
                                        'green'
                                      }>
                                        {balance.remaining}
                                      </Badge>
                                    </TableCell>
                                    <TableCell>{balance.carryOver}</TableCell>
                                    {showForecast && (
                                      <TableCell>{balance.forecast || 0}</TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </div>
                      );
                    })}
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </Card>
        </>
      ) : (
        <Card>
          <div className="flex justify-center items-center h-64">
            <Text>Select a date range and apply filters to view leave balance data</Text>
          </div>
        </Card>
      )}
    </div>
  );
};

export default LeaveBalanceReport; 