import React, { Fragment, ReactNode } from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import clsx from 'clsx';

interface DialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  children: ReactNode;
  className?: string;
}

export function Dialog({ open, onClose, children, className }: DialogProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <HeadlessDialog as="div" className={clsx("relative z-50", className)} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            {children}
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
}

interface DialogPanelProps {
  children: ReactNode;
  className?: string;
}

export function DialogPanel({ children, className }: DialogPanelProps) {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <HeadlessDialog.Panel
        className={clsx(
          "w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all",
          className
        )}
      >
        {children}
      </HeadlessDialog.Panel>
    </Transition.Child>
  );
}

interface DialogTitleProps {
  children: ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export function DialogTitle({ 
  children, 
  className,
  as = 'h3'
}: DialogTitleProps) {
  return (
    <HeadlessDialog.Title
      as={as}
      className={clsx("text-lg font-medium leading-6 text-gray-900", className)}
    >
      {children}
    </HeadlessDialog.Title>
  );
}

export function DialogDescription({ children, className }: { children: ReactNode; className?: string }) {
  return (
    <HeadlessDialog.Description className={clsx("mt-2 text-sm text-gray-500", className)}>
      {children}
    </HeadlessDialog.Description>
  );
} 