import { CalendarHeader } from "./CalendarHeader";
import { CalendarBody } from "./CalendarBody";
import { CalendarProps } from "../../../types/RotaTypes";

export function Calendar({
  today, 
  daysMap, 
  rotas,
  setIsOpenNewRotaModal,
  handlePrevious,
  handleToday,
  handleNext,
  setMode,
  mode,
  onRotaClick
}: CalendarProps) {
  return(
    <div className="overflow-hidden rounded-lg border border-slate-200 bg-white">
      <div className="flex flex-col h-full">
        <CalendarHeader 
          today={today}
          setIsOpenNewRotaModal={setIsOpenNewRotaModal}
          handlePrevious={handlePrevious}
          handleToday={handleToday}
          handleNext={handleNext}
          setMode={setMode}
          mode={mode} 
        />
        <CalendarBody 
          today={today} 
          daysMap={daysMap} 
          rotas={rotas} 
          mode={mode}
          onRotaClick={onRotaClick}
        />
      </div>
    </div>
  )
}