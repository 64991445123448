import { useEffect, useState } from 'react';
import { Calendar } from './components/Calendar';
import CreateNewRotaModal from './components/CreateNewRotaModal';
import moment, { Moment } from 'moment';
import { useGetRotas } from './hooks';
import { CALENDAR_MODE__MONTH, CALENDAR_MODE__WEEK } from '../../constants';
import { Spinner } from '../../ui/Spinner';
import { Button } from '../../ui/Button';
import { Container } from '../../ui/Container';
import { Header, HeaderTitle, HeaderAction } from '../../ui/Header';
import { ChevronLeftIcon, ChevronRightIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import { ShiftDetail } from './components/ShiftDetail';
import { EmployeeScreen } from './components/EmployeeScreen';

export function RotaManagementRoute() {
  const [isOpenNewRotaModal, setIsOpenNewRotaModal] = useState(false);
  const [today, setToday] = useState(moment());
  const [mode, setMode] = useState(CALENDAR_MODE__MONTH);
  const [selectedShift, setSelectedShift] = useState<any>(null);
  const [isShiftDetailOpen, setIsShiftDetailOpen] = useState(false);
  const [showEmployeeScreen, setShowEmployeeScreen] = useState(false);

  const startDay =
    mode === CALENDAR_MODE__MONTH
      ? today.clone().startOf('month').startOf('week')
      : today.clone().startOf('week');

  const daysCount = mode === CALENDAR_MODE__MONTH ? 42 : 7;

  const daysMap: Moment[] = [...Array(daysCount)].map((_, index) =>
    startDay.clone().add(index, 'day'),
  );

  const { data: rotasResponse = [], refetch: refetchRotas, isLoading } =
    useGetRotas(daysMap);
  const rotas = rotasResponse.flatMap((response) => response?.data || []);

  // Mock employee data for demonstration
  const mockEmployees = [
    { 
      id: '1', 
      name: 'John Smith', 
      email: 'john.smith@example.com',
      role: 'Manager',
      department: 'Sales' 
    },
    { 
      id: '2', 
      name: 'Alice Johnson', 
      email: 'alice.johnson@example.com',
      role: 'Associate',
      department: 'Marketing' 
    },
    { 
      id: '3', 
      name: 'Bob Williams', 
      email: 'bob.williams@example.com',
      role: 'Senior Associate',
      department: 'Sales' 
    },
    { 
      id: '4', 
      name: 'Emma Davis', 
      email: 'emma.davis@example.com',
      role: 'Associate',
      department: 'Customer Service' 
    },
  ];

  function handlePrevious() {
    setToday((prev) =>
      prev
        .clone()
        .subtract(1, mode === CALENDAR_MODE__MONTH ? 'month' : 'week'),
    );
  }

  function handleToday() {
    setToday(moment());
  }

  function handleNext() {
    setToday((prev) =>
      prev.clone().add(1, mode === CALENDAR_MODE__MONTH ? 'month' : 'week'),
    );
  }

  function handleRotaClick(rota: any) {
    setSelectedShift(rota);
    setIsShiftDetailOpen(true);
  }

  function handleEditShift(shiftId: string) {
    // For now, just close the modal. In a real app, you'd open the edit form.
    setIsShiftDetailOpen(false);
    console.log('Edit shift:', shiftId);
    // Here you would typically open the edit modal with the shift data pre-filled
  }

  function handleDeleteShift(shiftId: string) {
    setIsShiftDetailOpen(false);
    console.log('Delete shift:', shiftId);
    // Here you would typically show a confirmation dialog and then delete the shift
  }

  function handleViewEmployeeDetail(employeeId: string) {
    console.log('View employee detail:', employeeId);
    // Here you would typically navigate to the employee detail page or open a modal
  }

  useEffect(() => {
    refetchRotas();
  }, [today, mode, refetchRotas]);

  if(isLoading){
    return (
      <div className='h-screen flex flex-col gap-3 justify-center items-center'>
        <Spinner size={10}/>
        <p className='font-semibold'>
          Loading...
        </p>
      </div>
    )
  }

  if (showEmployeeScreen) {
    return (
      <EmployeeScreen
        employees={mockEmployees}
        rotas={rotas}
        onViewEmployeeDetail={handleViewEmployeeDetail}
        onBack={() => setShowEmployeeScreen(false)}
      />
    );
  }

  return (
    <>
      <Container>
        <Header className="mb-8">
          <HeaderTitle>Rota Management</HeaderTitle>
          <HeaderAction>
            <div className="flex items-center gap-3">
              <Button 
                variant="outline" 
                onClick={() => setShowEmployeeScreen(true)}
                className="gap-1.5"
              >
                <UserGroupIcon className="h-4 w-4" />
                Manage Employees
              </Button>
              <Button color="red" onClick={() => {}}>
                Publish shifts
              </Button>
            </div>
          </HeaderAction>
        </Header>

        <Calendar
          today={today}
          daysMap={daysMap}
          rotas={rotas}
          setIsOpenNewRotaModal={setIsOpenNewRotaModal}
          handlePrevious={handlePrevious}
          handleToday={handleToday}
          handleNext={handleNext}
          setMode={setMode}
          mode={mode}
          onRotaClick={handleRotaClick}
        />
      </Container>
      
      <CreateNewRotaModal
        open={isOpenNewRotaModal}
        setOpen={setIsOpenNewRotaModal}
        refetchRotas={refetchRotas}
      />
      
      {selectedShift && (
        <ShiftDetail
          shift={selectedShift}
          open={isShiftDetailOpen}
          onClose={() => setIsShiftDetailOpen(false)}
          onEdit={handleEditShift}
          onDelete={handleDeleteShift}
          refetchRotas={refetchRotas}
        />
      )}
    </>
  );
}
