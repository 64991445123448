import { useState, useEffect } from 'react';
import {
  Card,
  Title,
  Text,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
  Flex,
  Badge,
  Button,
  Select,
  SelectItem,
  DateRangePicker,
  DateRangePickerValue,
  MultiSelect,
  MultiSelectItem,
  LineChart,
  BarChart,
  DonutChart,
  Divider,
  Grid,
} from '@tremor/react';
import { RiFileDownloadLine, RiFilterLine, RiRefreshLine } from '@remixicon/react';
import { reportService } from '../../services/report';
import { saveAs } from 'file-saver';
import { 
  Option, 
  AbsenceRecord, 
  ChartDataPoint, 
  AbsenceTrendMetrics 
} from '../../types/report.types';

// Define internal types for the component
type AbsenceData = {
  date: string;
  count: number;
  duration: number;
  type: string;
  department: string;
  employeeId: string;
  employeeName: string;
  role: string;
  location: string;
};

// Absence type options - these are constant and don't need to come from the API
const absenceTypes = [
  { value: 'all', label: 'All Types' },
  { value: 'annual_leave', label: 'Annual Leave' },
  { value: 'sick_leave', label: 'Sick Leave' },
  { value: 'medical_appointment', label: 'Medical Appointment' },
  { value: 'compassionate', label: 'Compassionate Leave' },
  { value: 'maternity', label: 'Maternity Leave' },
  { value: 'paternity', label: 'Paternity Leave' },
  { value: 'unpaid', label: 'Unpaid Leave' },
];

export default function AbsenceTrends() {
  // State for filters
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: new Date(new Date().getFullYear(), 0, 1), // Start of current year
    to: new Date(),
  });
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [selectedEmployee, setSelectedEmployee] = useState<string>('all');
  const [selectedRole, setSelectedRole] = useState<string>('all');
  const [selectedLocation, setSelectedLocation] = useState<string>('all');
  const [selectedAbsenceTypes, setSelectedAbsenceTypes] = useState<string[]>(['all']);
  
  // State for data
  const [absenceData, setAbsenceData] = useState<AbsenceData[]>([]);
  const [filteredData, setFilteredData] = useState<AbsenceData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  
  // Graph view options
  const [groupBy, setGroupBy] = useState<string>('department');
  const [viewType, setViewType] = useState<string>('frequency');
  
  // State for dropdown options
  const [departments, setDepartments] = useState<Option[]>([]);
  const [employees, setEmployees] = useState<Option[]>([]);
  const [roles, setRoles] = useState<Option[]>([]);
  const [locations, setLocations] = useState<Option[]>([]);
  const [loadingOptions, setLoadingOptions] = useState({
    departments: false,
    employees: false,
    roles: false,
    locations: false
  });

  // State for chart data from API
  const [chartData, setChartData] = useState<ChartDataPoint[]>([]);
  const [metrics, setMetrics] = useState<AbsenceTrendMetrics>({
    totalAbsences: 0,
    totalDays: 0,
    averageDuration: 0
  });

  // Load dropdown options on component mount
  useEffect(() => {
    fetchDropdownOptions();
  }, []);
  
  // Fetch absence data on component mount
  useEffect(() => {
    fetchAbsenceData();
  }, []);

  // Reload employees when department changes
  useEffect(() => {
    fetchEmployees(selectedDepartment);
  }, [selectedDepartment]);

  // Apply filters when any filter changes
  useEffect(() => {
    fetchAbsenceData();
  }, [
    dateRange, 
    selectedDepartment, 
    selectedEmployee, 
    selectedRole, 
    selectedLocation, 
    selectedAbsenceTypes,
    groupBy,
    viewType
  ]);

  // Function to fetch data from API
  const fetchAbsenceData = async () => {
    setLoading(true);
    try {
      const response = await reportService.getAbsenceTrendsData({
        startDate: dateRange.from?.toISOString().split('T')[0],
        endDate: dateRange.to?.toISOString().split('T')[0],
        department: selectedDepartment !== 'all' ? selectedDepartment : undefined,
        employeeId: selectedEmployee !== 'all' ? selectedEmployee : undefined,
        role: selectedRole !== 'all' ? selectedRole : undefined,
        location: selectedLocation !== 'all' ? selectedLocation : undefined,
        absenceTypes: selectedAbsenceTypes.includes('all') ? undefined : selectedAbsenceTypes,
        groupBy: groupBy as 'department' | 'employee' | 'role' | 'location' | 'type' | 'month',
        viewType: viewType as 'frequency' | 'duration'
      });
      
      if (response.status === 'ABSENCE_TRENDS_SUCCESS') {
        // Get data from API response
        const trendsData = response.payload.absence_trends;
        
        // Set metrics from API
        setMetrics(trendsData.metrics);
        
        // Set chart data from API
        setChartData(trendsData.chartData);
        
        // Convert API response to our internal format for any additional processing
        const apiData = trendsData.absenceRecords.map((record: AbsenceRecord) => ({
          date: record.startDate,
          count: 1,
          duration: record.duration,
          type: record.type,
          department: record.department,
          employeeId: record.userId,
          employeeName: record.userName,
          role: record.role,
          location: record.location
        }));
        
        setAbsenceData(apiData);
        setFilteredData(apiData);
      } else {
        console.error('Error fetching absence data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching absence data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Function to fetch all dropdown options
  const fetchDropdownOptions = async () => {
    try {
      // Fetch departments, roles, and locations in parallel
      await Promise.all([
        fetchDepartments(),
        fetchRoles(),
        fetchLocations()
      ]);
      
      // Employees depend on the selected department, so we fetch them after departments
      await fetchEmployees(selectedDepartment);
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };
  
  // Function to fetch department options
  const fetchDepartments = async () => {
    setLoadingOptions(prev => ({ ...prev, departments: true }));
    try {
      const response = await reportService.getDepartments();
      if (response.status === 'GET_DEPARTMENTS_SUCCESS') {
        setDepartments(response.payload.departments);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    } finally {
      setLoadingOptions(prev => ({ ...prev, departments: false }));
    }
  };
  
  // Function to fetch employee options
  const fetchEmployees = async (departmentId: string) => {
    setLoadingOptions(prev => ({ ...prev, employees: true }));
    try {
      const response = await reportService.getEmployees(departmentId);
      if (response.status === 'GET_EMPLOYEES_SUCCESS') {
        setEmployees(response.payload.employees);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    } finally {
      setLoadingOptions(prev => ({ ...prev, employees: false }));
    }
  };
  
  // Function to fetch role options
  const fetchRoles = async () => {
    setLoadingOptions(prev => ({ ...prev, roles: true }));
    try {
      const response = await reportService.getRoles();
      if (response.status === 'GET_ROLES_SUCCESS') {
        setRoles(response.payload.roles);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoadingOptions(prev => ({ ...prev, roles: false }));
    }
  };
  
  // Function to fetch location options
  const fetchLocations = async () => {
    setLoadingOptions(prev => ({ ...prev, locations: true }));
    try {
      const response = await reportService.getLocations();
      if (response.status === 'GET_LOCATIONS_SUCCESS') {
        setLocations(response.payload.locations);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setLoadingOptions(prev => ({ ...prev, locations: false }));
    }
  };
  
  // Function to export data to CSV
  const exportToCSV = () => {
    if (filteredData.length === 0) return;
    
    // Format data for CSV
    const headers = ['Date', 'Employee', 'Department', 'Role', 'Location', 'Type', 'Duration (Days)'];
    
    const csvRows = [
      headers.join(','),
      ...filteredData.map(item => {
        const values = [
          item.date,
          item.employeeName,
          item.department === 'hr' ? 'Human Resources' : 
            item.department === 'it' ? 'Information Technology' : 
            item.department.charAt(0).toUpperCase() + item.department.slice(1),
          item.role.charAt(0).toUpperCase() + item.role.slice(1),
          item.location.charAt(0).toUpperCase() + item.location.slice(1),
          item.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
          item.duration.toString()
        ];
        
        return values.join(',');
      })
    ].join('\n');
    
    // Create blob and download file
    const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `absence_trends_${new Date().toISOString().split('T')[0]}.csv`);
  };
  
  // Function to export data to PDF (simplified)
  const exportToPDF = () => {
    alert('PDF export functionality would be implemented here. Typically requires a server-side library or external service.');
  };

  return (
    <div className="p-4">
      <Card className="mb-6">
        <Flex justifyContent="between" alignItems="center" className="mb-4">
          <Title>Absence Trends Analysis</Title>
          <Flex justifyContent="end" className="gap-2">
            <Button
              size="sm"
              variant="secondary"
              icon={RiRefreshLine}
              onClick={fetchAbsenceData}
              loading={loading}
            >
              Refresh
            </Button>
            <Button
              size="sm"
              variant="secondary"
              icon={RiFileDownloadLine}
              onClick={exportToCSV}
            >
              Export CSV
            </Button>
            <Button
              size="sm"
              variant="secondary"
              icon={RiFileDownloadLine}
              onClick={exportToPDF}
            >
              Export PDF
            </Button>
          </Flex>
        </Flex>
        
        <Text>Analyze absence patterns across your organization</Text>
        
        <Divider className="my-4" />
        
        {/* Filters Section */}
        <Grid numItems={1} numItemsSm={2} numItemsLg={6} className="gap-2 mb-6">
          <DateRangePicker
            className="max-w-md"
            value={dateRange}
            onValueChange={setDateRange}
            enableSelect={false}
            selectPlaceholder="Select range"
          />
          
          <Select
            value={selectedDepartment}
            onValueChange={setSelectedDepartment}
            placeholder="Select Department"
            disabled={loadingOptions.departments}
          >
            {departments.map((dept) => (
              <SelectItem key={dept.id} value={dept.id}>
                {dept.name}
              </SelectItem>
            ))}
          </Select>
          
          <Select
            value={selectedEmployee}
            onValueChange={setSelectedEmployee}
            placeholder="Select Employee"
            disabled={loadingOptions.employees}
          >
            {employees.map((emp) => (
              <SelectItem key={emp.id} value={emp.id}>
                {emp.name}
              </SelectItem>
            ))}
          </Select>
          
          <Select
            value={selectedRole}
            onValueChange={setSelectedRole}
            placeholder="Select Role"
            disabled={loadingOptions.roles}
          >
            {roles.map((role) => (
              <SelectItem key={role.id} value={role.id}>
                {role.name}
              </SelectItem>
            ))}
          </Select>
          
          <Select
            value={selectedLocation}
            onValueChange={setSelectedLocation}
            placeholder="Select Location"
            disabled={loadingOptions.locations}
          >
            {locations.map((loc) => (
              <SelectItem key={loc.id} value={loc.id}>
                {loc.name}
              </SelectItem>
            ))}
          </Select>
          
          <MultiSelect
            value={selectedAbsenceTypes}
            onValueChange={setSelectedAbsenceTypes}
            placeholder="Absence Types"
          >
            {absenceTypes.map((type) => (
              <MultiSelectItem key={type.value} value={type.value}>
                {type.label}
              </MultiSelectItem>
            ))}
          </MultiSelect>
        </Grid>
        
        {/* Summary Metrics */}
        <Grid numItems={1} numItemsSm={3} className="gap-2 mb-6">
          <Card decoration="top" decorationColor="blue">
            <Text>Total Absences</Text>
            <Flex justifyContent="center" alignItems="center" className="mt-2">
              <Title>{metrics.totalAbsences}</Title>
            </Flex>
          </Card>
          
          <Card decoration="top" decorationColor="amber">
            <Text>Total Days</Text>
            <Flex justifyContent="center" alignItems="center" className="mt-2">
              <Title>{metrics.totalDays}</Title>
            </Flex>
          </Card>
          
          <Card decoration="top" decorationColor="green">
            <Text>Average Duration</Text>
            <Flex justifyContent="center" alignItems="center" className="mt-2">
              <Title>{metrics.averageDuration} days</Title>
            </Flex>
          </Card>
        </Grid>
        
        {/* Visualization Options */}
        <Flex justifyContent="between" alignItems="center" className="mb-4">
          <Text>Visualization Options</Text>
          <Flex className="gap-2">
            <Select
              value={groupBy}
              onValueChange={setGroupBy}
              placeholder="Group by"
              className="w-40"
            >
              <SelectItem value="department">Department</SelectItem>
              <SelectItem value="employee">Employee</SelectItem>
              <SelectItem value="role">Role</SelectItem>
              <SelectItem value="location">Location</SelectItem>
              <SelectItem value="type">Absence Type</SelectItem>
              <SelectItem value="month">Month</SelectItem>
            </Select>
            
            <Select
              value={viewType}
              onValueChange={setViewType}
              placeholder="View"
              className="w-40"
            >
              <SelectItem value="frequency">Frequency</SelectItem>
              <SelectItem value="duration">Duration (Days)</SelectItem>
            </Select>
          </Flex>
        </Flex>
      </Card>
      
      {/* Visualization Section */}
      <TabGroup>
        <TabList className="mb-4">
          <Tab>Bar Chart</Tab>
          <Tab>Line Chart</Tab>
          <Tab>Distribution</Tab>
        </TabList>
        
        <TabPanels>
          <TabPanel>
            <Card>
              <Title>
                {viewType === 'frequency' ? 'Absence Frequency' : 'Absence Duration'} by {
                  groupBy === 'department' ? 'Department' :
                  groupBy === 'employee' ? 'Employee' :
                  groupBy === 'role' ? 'Role' :
                  groupBy === 'location' ? 'Location' :
                  groupBy === 'type' ? 'Absence Type' : 'Month'
                }
              </Title>
              
              <BarChart
                className="mt-4 h-80"
                data={chartData}
                index="name"
                categories={[viewType === 'frequency' ? 'Absences' : 'Days']}
                colors={["blue"]}
                valueFormatter={(value) => `${value} ${viewType === 'frequency' ? 'absences' : 'days'}`}
                yAxisWidth={48}
              />
            </Card>
          </TabPanel>
          
          <TabPanel>
            <Card>
              <Title>
                {viewType === 'frequency' ? 'Absence Frequency' : 'Absence Duration'} Trend by {
                  groupBy === 'department' ? 'Department' :
                  groupBy === 'employee' ? 'Employee' :
                  groupBy === 'role' ? 'Role' :
                  groupBy === 'location' ? 'Location' :
                  groupBy === 'type' ? 'Absence Type' : 'Month'
                }
              </Title>
              
              <LineChart
                className="mt-4 h-80"
                data={chartData}
                index="name"
                categories={[viewType === 'frequency' ? 'Absences' : 'Days']}
                colors={["blue"]}
                valueFormatter={(value) => `${value} ${viewType === 'frequency' ? 'absences' : 'days'}`}
                yAxisWidth={48}
              />
            </Card>
          </TabPanel>
          
          <TabPanel>
            <Card>
              <Title>
                {viewType === 'frequency' ? 'Absence Frequency' : 'Absence Duration'} Distribution by {
                  groupBy === 'department' ? 'Department' :
                  groupBy === 'employee' ? 'Employee' :
                  groupBy === 'role' ? 'Role' :
                  groupBy === 'location' ? 'Location' :
                  groupBy === 'type' ? 'Absence Type' : 'Month'
                }
              </Title>
              
              <DonutChart
                className="mt-4 h-80"
                data={chartData}
                category={viewType === 'frequency' ? 'Absences' : 'Days'}
                index="name"
                valueFormatter={(value) => `${value} ${viewType === 'frequency' ? 'absences' : 'days'}`}
                colors={["blue", "cyan", "indigo", "violet", "fuchsia", "pink", "rose", "amber"]}
              />
            </Card>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
} 