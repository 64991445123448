import React, { useState } from 'react';
import { Modal } from '../../../ui/Modal';
import { Button } from '../../../ui/Button';
import { Card } from '../../../ui/Card';
import { Input } from '../../../ui/form/Input';
import moment from 'moment';
import { 
  CalendarDaysIcon, 
  ClockIcon, 
  UserGroupIcon, 
  PencilIcon, 
  TrashIcon, 
  ArrowRightIcon 
} from '@heroicons/react/20/solid';
import { clsx } from 'clsx';

interface ShiftDetailProps {
  shift: any;
  open: boolean;
  onClose: () => void;
  onEdit: (shiftId: string) => void;
  onDelete: (shiftId: string) => void;
  refetchRotas: () => void;
}

export function ShiftDetail({ 
  shift, 
  open, 
  onClose, 
  onEdit, 
  onDelete,
  refetchRotas 
}: ShiftDetailProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(shift?.notes || '');

  const handleSaveNotes = () => {
    // Here you would save the notes to the backend
    // For now we'll just simulate a save
    console.log('Saving notes:', notes);
    setIsEditing(false);
    
    // In a real implementation, you would call an API to update the shift
    // After saving, refresh the data
    refetchRotas();
  };

  if (!shift) return null;

  return (
    <Modal open={open} onClose={onClose} maxWidth="md">
      <Modal.Header onClose={onClose}>
        <Modal.Title>Shift Details</Modal.Title>
        <Modal.Description>
          View and manage details for this shift
        </Modal.Description>
      </Modal.Header>

      <Modal.Body>
        <div className="space-y-6">
          {/* Header with basic info */}
          <div className="flex items-center gap-3">
            <div 
              className="h-10 w-10 flex-shrink-0 rounded-md"
              style={{ backgroundColor: shift.color || '#F4F4F5' }}
            />
            <div>
              <h3 className="text-lg font-semibold text-slate-900">{shift.name}</h3>
              <p className="text-sm text-slate-500">
                {shift.employees?.length || 0} {shift.employees?.length === 1 ? 'employee' : 'employees'} assigned
              </p>
            </div>
          </div>

          {/* Time and date information */}
          <Card>
            <Card.Header className="border-b border-slate-200 bg-slate-50 py-3">
              <h3 className="text-sm font-medium text-slate-900">Schedule Information</h3>
            </Card.Header>
            <Card.Content className="space-y-4 p-4">
              <div className="flex items-center gap-2 text-sm">
                <ClockIcon className="h-4 w-4 text-slate-500" />
                <span className="font-medium text-slate-700">Time:</span>
                <div className="flex items-center gap-2">
                  <span className="rounded-md bg-slate-100 px-2 py-1 text-slate-800">
                    {moment(shift.start_time, 'HH:mm').format('h:mm A')}
                  </span>
                  <ArrowRightIcon className="h-3.5 w-3.5 text-slate-400" />
                  <span className="rounded-md bg-slate-100 px-2 py-1 text-slate-800">
                    {moment(shift.end_time, 'HH:mm').format('h:mm A')}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 text-sm">
                <CalendarDaysIcon className="h-4 w-4 text-slate-500" />
                <span className="font-medium text-slate-700">Date Range:</span>
                <div className="flex items-center gap-2">
                  <span className="rounded-md bg-slate-100 px-2 py-1 text-slate-800">
                    {moment(shift.start_date).format('MMM D, YYYY')}
                  </span>
                  {shift.end_date && (
                    <>
                      <ArrowRightIcon className="h-3.5 w-3.5 text-slate-400" />
                      <span className="rounded-md bg-slate-100 px-2 py-1 text-slate-800">
                        {moment(shift.end_date).format('MMM D, YYYY')}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </Card.Content>
          </Card>

          {/* Employee list */}
          <Card>
            <Card.Header className="border-b border-slate-200 bg-slate-50 py-3">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-slate-900">Assigned Employees</h3>
                <span className="rounded-full bg-slate-200 px-2 py-0.5 text-xs font-medium text-slate-700">
                  {shift.employees?.length || 0}
                </span>
              </div>
            </Card.Header>
            <Card.Content className="divide-y divide-slate-100 p-0">
              {shift.employees && shift.employees.length > 0 ? (
                shift.employees.map((employee: any) => (
                  <div key={employee.id} className="flex items-center gap-3 p-4">
                    <div className="h-8 w-8 rounded-full bg-slate-200 flex items-center justify-center">
                      {employee.avatar_url ? (
                        <img
                          src={employee.avatar_url}
                          alt={employee.name}
                          className="h-8 w-8 rounded-full"
                        />
                      ) : (
                        <span className="text-sm font-medium text-slate-600">
                          {employee.name.charAt(0)}
                        </span>
                      )}
                    </div>
                    <div>
                      <p className="text-sm font-medium text-slate-900">{employee.name}</p>
                      <p className="text-xs text-slate-500">{employee.email || employee.role || ''}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center justify-center py-8">
                  <UserGroupIcon className="h-10 w-10 text-slate-300" />
                  <p className="mt-2 text-sm text-slate-500">No employees assigned</p>
                </div>
              )}
            </Card.Content>
          </Card>

          {/* Notes section */}
          <Card>
            <Card.Header className="border-b border-slate-200 bg-slate-50 py-3">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-slate-900">Notes</h3>
                <Button 
                  variant="ghost" 
                  size="xs" 
                  onClick={() => setIsEditing(!isEditing)}
                  className="text-slate-600 hover:text-slate-900"
                >
                  <PencilIcon className="h-3.5 w-3.5 mr-1" />
                  {isEditing ? 'Cancel' : 'Edit'}
                </Button>
              </div>
            </Card.Header>
            <Card.Content className="p-4">
              {isEditing ? (
                <>
                  <textarea
                    className={clsx(
                      "w-full rounded-md border border-slate-300 shadow-sm py-2 px-3 text-sm",
                      "focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    )}
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add notes about this shift..."
                  />
                  <div className="mt-2 flex justify-end">
                    <Button size="sm" onClick={handleSaveNotes}>
                      Save Notes
                    </Button>
                  </div>
                </>
              ) : (
                <p className="text-sm text-slate-700">
                  {notes || 'No notes for this shift.'}
                </p>
              )}
            </Card.Content>
          </Card>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button 
          variant="outline" 
          color="red" 
          className="gap-1.5" 
          onClick={() => onDelete(shift.id)}
        >
          <TrashIcon className="h-4 w-4" />
          Delete Shift
        </Button>
        <Button onClick={() => onEdit(shift.id)} className="gap-1.5">
          <PencilIcon className="h-4 w-4" />
          Edit Shift
        </Button>
      </Modal.Footer>
    </Modal>
  );
} 