// @ts-ignore
import * as TabsPrimitives from "@radix-ui/react-tabs"
import React from "react"
import clsx from "clsx"

// Utility for focus ring
const focusRing = "focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"

const Tabs = (
  props: Omit<
    React.ComponentPropsWithoutRef<typeof TabsPrimitives.Root>,
    "orientation"
  >,
) => {
  return <TabsPrimitives.Root {...props} />
}

Tabs.displayName = "Tabs"

type TabsListVariant = "line" | "solid"

const TabsListVariantContext = React.createContext<TabsListVariant>("line")

interface TabsListProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitives.List> {
  variant?: TabsListVariant;
  className?: string;
  children?: React.ReactNode;
}

const variantStyles: Record<TabsListVariant, string> = {
  line: clsx(
    // base
    "flex items-center justify-start border-b",
    // border color
    "border-gray-200 dark:border-gray-800",
  ),
  solid: clsx(
    // base
    "inline-flex items-center justify-center rounded-md p-1",
    // background color
    "bg-gray-100 dark:bg-gray-800",
  ),
}

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitives.List>,
  TabsListProps
>(({ className, variant = "line", children, ...props }, forwardedRef) => (
  <TabsPrimitives.List
    ref={forwardedRef}
    className={clsx(variantStyles[variant as keyof typeof variantStyles], className)}
    {...props}
  >
    <TabsListVariantContext.Provider value={variant}>
      {children}
    </TabsListVariantContext.Provider>
  </TabsPrimitives.List>
))

TabsList.displayName = "TabsList"

function getVariantStyles(tabVariant: TabsListVariant): string {
  switch (tabVariant) {
    case "line":
      return clsx(
        // base
        "-mb-px items-center justify-center whitespace-nowrap border-b-2 border-transparent px-3 pb-3 text-sm font-medium transition-all",
        // text color - changed to black for both states
        "text-black dark:text-black",
        // hover
        "hover:text-black hover:dark:text-black",
        // border hover
        "hover:border-gray-300 hover:dark:border-gray-400",
        // selected - still black but with added font weight for distinction
        "data-[state=active]:border-gray-900 data-[state=active]:text-black data-[state=active]:font-semibold",
        "data-[state=active]:dark:border-gray-50 data-[state=active]:dark:text-black data-[state=active]:font-semibold",
        // disabled
        "disabled:pointer-events-none",
        "disabled:text-gray-300 disabled:dark:text-gray-700",
      )
    case "solid":
      return clsx(
        // base
        "inline-flex items-center justify-center whitespace-nowrap rounded px-3 py-1 text-sm font-medium transition-all",
        // text color - changed to black for better visibility
        "text-black dark:text-black",
        // hover
        "hover:text-black hover:dark:text-black",
        // selected
        "data-[state=active]:bg-white data-[state=active]:text-black data-[state=active]:font-semibold data-[state=active]:shadow",
        "data-[state=active]:dark:bg-gray-900 data-[state=active]:dark:text-black data-[state=active]:font-semibold",
        // disabled
        "disabled:pointer-events-none disabled:text-gray-400 disabled:opacity-50 disabled:dark:text-gray-600",
      )
    default:
      return ""
  }
}

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitives.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitives.Trigger>
>(({ className, children, ...props }, forwardedRef) => {
  const variant = React.useContext(TabsListVariantContext)
  return (
    <TabsPrimitives.Trigger
      ref={forwardedRef}
      className={clsx(getVariantStyles(variant), focusRing, className)}
      {...props}
    >
      {children}
    </TabsPrimitives.Trigger>
  )
})

TabsTrigger.displayName = "TabsTrigger"

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitives.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitives.Content>
>(({ className, ...props }, forwardedRef) => (
  <TabsPrimitives.Content
    ref={forwardedRef}
    className={clsx("outline-none", focusRing, className)}
    {...props}
  />
))

TabsContent.displayName = "TabsContent"

export { Tabs, TabsContent, TabsList, TabsTrigger } 