import axios from 'axios';
import { 
  ApiResponse, 
  ReportType, 
  DepartmentsResponse,
  EmployeesResponse,
  LocationsResponse,
  RolesResponse,
  AbsenceTrendsResponse,
  BradfordFactorResponse,
  AbsenceTrendsRequest,
  LeaveBalanceRequest,
  LeaveBalanceResponse
} from '../types/report.types';

const API_BASE_URL = process.env.REACT_APP_API_HOST;

// Define the absenteeism risk interfaces
export interface AbsenteeismRiskRequest {
  startDate: string;
  endDate: string;
  department?: string;
  location?: string;
  employeeId?: string;
  includeInactive?: boolean;
}

export interface AbsenteeismRiskRule {
  id: string;
  name: string;
  description: string;
  threshold: number;
  period: number;
  enabled: boolean;
}

export interface AbsenteeismRiskScore {
  employeeId: string;
  firstName: string;
  lastName: string;
  department: string;
  role: string;
  riskScore: number;
  riskLevel: 'low' | 'medium' | 'high' | 'critical';
  triggers: {
    ruleName: string;
    count: number;
    threshold: number;
  }[];
  absenceCount: number;
  absenceDays: number;
  lastAbsenceDate: string;
  bradfordFactor?: number;
}

export interface AbsenteeismRiskSummary {
  departmentSummaries: {
    department: string;
    employeeCount: number;
    riskCounts: {
      low: number;
      medium: number;
      high: number;
      critical: number;
    };
    averageRiskScore: number;
  }[];
  totalEmployees: number;
  riskDistribution: {
    low: number;
    medium: number;
    high: number;
    critical: number;
  };
  period: {
    start: string;
    end: string;
  };
  config: {
    rules: AbsenteeismRiskRule[];
    mediumThreshold: number;
    highThreshold: number;
    criticalThreshold: number;
  };
}

export interface AbsenteeismRiskResponse {
  employees: AbsenteeismRiskScore[];
  summary: AbsenteeismRiskSummary;
}

export const reportService = {
  async getReportTypes(): Promise<ReportType[]> {
    const response = await axios.get(`${API_BASE_URL}/v1/report/type`);
    return response.data;
  },

  async getReportData(type: string, params?: { company?: string; year?: string }) {
    const response = await axios.get(`${API_BASE_URL}/v1/report/${type}`, { params });
    return response.data;
  },
  
  async getBradfordFactorData(params?: { 
    department?: string; 
    employmentTypes?: string[]; 
    startDate?: string; 
    endDate?: string;
    employeeId?: string;
    formulaSettings?: {
      spellsExponent: number;
      daysMultiplier: number;
    };
    thresholds?: {
      lowMax: number;
      mediumMax: number;
      highMax: number;
    };
    includeAbsenceDetails?: boolean;
  }): Promise<ApiResponse<BradfordFactorResponse>> {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/report/statistics`, { 
        types: ['bradford_factor'],
        filter: {
          type: 'department_filter',
          id: params?.department || 'all',
          employmentTypes: params?.employmentTypes,
          dateRange: params?.startDate && params?.endDate ? {
            start: params.startDate,
            end: params.endDate
          } : undefined,
          employeeId: params?.employeeId !== 'all' ? params?.employeeId : undefined
        },
        settings: {
          bradford_factor: {
            formulaSettings: params?.formulaSettings,
            thresholds: params?.thresholds,
            includeAbsenceDetails: params?.includeAbsenceDetails
          }
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Bradford Factor data:', error);
      throw error;
    }
  },

  async getAbsenceTrendsData(params: Omit<AbsenceTrendsRequest, 'startDate' | 'endDate'> & { 
    startDate?: string; 
    endDate?: string;
  }): Promise<ApiResponse<AbsenceTrendsResponse>> {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/report/statistics/absence-trends`, { 
        filter: {
          type: 'department_filter',
          id: params?.department || 'all',
          dateRange: params?.startDate && params?.endDate ? {
            start: params.startDate,
            end: params.endDate
          } : undefined,
          employeeId: params?.employeeId !== 'all' ? params?.employeeId : undefined
        },
        request: {
          startDate: params.startDate || new Date().toISOString().split('T')[0],
          endDate: params.endDate || new Date().toISOString().split('T')[0],
          department: params.department,
          employeeId: params.employeeId,
          role: params.role,
          location: params.location,
          absenceTypes: params.absenceTypes,
          groupBy: params.groupBy,
          viewType: params.viewType
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching absence trends data:', error);
      throw error;
    }
  },

  async getLeaveBalanceReport(params: LeaveBalanceRequest): Promise<ApiResponse<{
    leave_balance: LeaveBalanceResponse
  }>> {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/report/leave-balance`, {
        filter: {
          type: 'department_filter',
          id: params?.department || 'all',
          dateRange: params.startDate && params.endDate ? {
            start: params.startDate,
            end: params.endDate
          } : undefined,
          employeeId: params?.employeeId !== 'all' ? params?.employeeId : undefined
        },
        request: {
          startDate: params.startDate || new Date().toISOString().split('T')[0],
          endDate: params.endDate || new Date().toISOString().split('T')[0],
          department: params.department,
          employeeId: params.employeeId,
          employmentType: params.employmentType,
          location: params.location,
          leaveTypes: params.leaveTypes,
          forecastDate: params.forecastDate
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching leave balance data:', error);
      throw error;
    }
  },

  // Fetch departments for dropdown
  async getDepartments(): Promise<ApiResponse<DepartmentsResponse>> {
    try {
      const response = await axios.get('/api/report/departments');
      return response.data;
    } catch (error) {
      console.error('Error fetching departments:', error);
      throw error;
    }
  },

  // Fetch employees for dropdown
  async getEmployees(departmentId?: string): Promise<ApiResponse<EmployeesResponse>> {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/report/employees`, {
        params: { department: departmentId }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching employees:', error);
      throw error;
    }
  },

  // Fetch locations for dropdown
  async getLocations(): Promise<ApiResponse<LocationsResponse>> {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/report/locations`);
      return response.data;
    } catch (error) {
      console.error('Error fetching locations:', error);
      throw error;
    }
  },

  // Fetch roles for dropdown
  async getRoles(): Promise<ApiResponse<RolesResponse>> {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/report/roles`);
      return response.data;
    } catch (error) {
      console.error('Error fetching roles:', error);
      throw error;
    }
  },

  async getAbsenteeismRiskReport(params: AbsenteeismRiskRequest): Promise<ApiResponse<{
    risk_report: AbsenteeismRiskResponse
  }>> {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/report/absenteeism-risk`, {
        filter: {
          type: 'department_filter',
          id: params?.department || 'all',
          employeeId: params?.employeeId !== 'all' ? params?.employeeId : undefined
        },
        request: params
      });
      
      return response.data;
    } catch (error) {
      console.error('Error getting absenteeism risk report:', error);
      throw error;
    }
  },
}; 