import React from 'react';
import { clsx } from 'clsx';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'solid' | 'outline' | 'ghost';
  color?: 'slate' | 'red' | 'blue' | 'green';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  children: React.ReactNode;
  className?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'solid',
      color = 'slate',
      size = 'md',
      children,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
          {
            // Size variations
            'px-2.5 py-1.5 text-xs': size === 'xs',
            'px-3 py-2 text-sm': size === 'sm',
            'px-4 py-2 text-sm': size === 'md',
            'px-5 py-2.5 text-base': size === 'lg',

            // Color and variant combinations
            'bg-slate-900 text-white hover:bg-slate-700': variant === 'solid' && color === 'slate',
            'bg-red-600 text-white hover:bg-red-500': variant === 'solid' && color === 'red',
            'bg-blue-600 text-white hover:bg-blue-500': variant === 'solid' && color === 'blue',
            'bg-green-600 text-white hover:bg-green-500': variant === 'solid' && color === 'green',
            
            'border border-slate-300 bg-transparent text-slate-700 hover:bg-slate-50': variant === 'outline' && color === 'slate',
            'border border-red-300 bg-transparent text-red-700 hover:bg-red-50': variant === 'outline' && color === 'red',
            'border border-blue-300 bg-transparent text-blue-700 hover:bg-blue-50': variant === 'outline' && color === 'blue',
            'border border-green-300 bg-transparent text-green-700 hover:bg-green-50': variant === 'outline' && color === 'green',
            
            'bg-transparent text-slate-700 hover:bg-slate-100': variant === 'ghost' && color === 'slate',
            'bg-transparent text-red-700 hover:bg-red-100': variant === 'ghost' && color === 'red',
            'bg-transparent text-blue-700 hover:bg-blue-100': variant === 'ghost' && color === 'blue',
            'bg-transparent text-green-700 hover:bg-green-100': variant === 'ghost' && color === 'green',
          },
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button'; 