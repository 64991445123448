import moment from 'moment';
import { clsx } from 'clsx';
import { DayCellProps } from '../../../types/RotaTypes';
import { Popover } from '@headlessui/react';
import {
  ArrowRightIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
  PencilIcon as PencilSquareIcon,
} from '@heroicons/react/20/solid';
import { MinusCircleIcon, UserIcon } from '@heroicons/react/20/solid';
import { Button } from '../../../ui/Button';
import { Card } from '../../../ui/Card';

export function DayCell({ today, day, rotas, onRotaClick }: DayCellProps) {
  const dayRotas = rotas.filter((rota) =>
    moment(day).isBetween(rota.start_date, rota.end_date, 'day', '[]'),
  );

  const uniqueDayRotas = Array.from(
    new Set(dayRotas.map((rota) => rota.id)),
  ).map((id) => dayRotas.find((rota) => rota.id === id));

  const isToday = moment().isSame(day, 'day');
  const isCurrentMonth = today.isSame(day, 'month');

  return (
    <div
      className={clsx(
        'group relative min-h-[100px] p-2 border-b border-r border-slate-200',
        isCurrentMonth ? 'bg-white' : 'bg-slate-50 text-slate-400'
      )}
    >
      <header className="flex items-center justify-between">
        <time
          dateTime={day.toISOString()}
          className={clsx('flex h-6 w-6 items-center justify-center text-sm', {
            'rounded-full bg-red-600 font-medium text-white': isToday,
            'font-medium text-slate-900': !isToday && isCurrentMonth,
          })}
        >
          {day.format('D')}
        </time>
      </header>
      
      <div className="mt-2 flex max-h-[120px] flex-col gap-1.5 overflow-y-auto pr-1">
        {uniqueDayRotas?.map((rota: any) => (
          <RotaItem key={rota.id} rota={rota} onRotaClick={onRotaClick} />
        ))}
      </div>
    </div>
  );
}

function RotaItem({ rota, onRotaClick }: { rota: any; onRotaClick?: (rota: any) => void }) {
  const handleClick = () => {
    if (onRotaClick) {
      onRotaClick(rota);
    }
  };

  return (
    <div 
      className={clsx(
        "w-full rounded-md border-l-4 px-2 py-1.5 text-left text-xs shadow-sm cursor-pointer hover:bg-slate-50",
      )}
      style={{ borderLeftColor: rota.color, backgroundColor: `${rota.color}10` }}
      onClick={handleClick}
    >
      <p className="font-medium text-slate-900">{rota.name}</p>
      <p className="text-slate-600">
        {moment(rota.start_time, 'HH:mm').format('h:mm A')} - {' '}
        {moment(rota.end_time, 'HH:mm').format('h:mm A')}
      </p>
    </div>
  );
}
