import React, { useState } from 'react';
import axios from 'axios';
import { Input } from '../../../ui/form/Input';
import { Select, SelectItem } from '@tremor/react';

const API_HOST = process.env.REACT_APP_API_HOST;

// Helper to format a date as "D MMM,YYYY" e.g., "28 Oct,2024"
const formatDate = (date: Date): string => {
  const day = date.getDate();
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export function WorkSpaceDetails() {
  // Organization Information state
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('Technology');
  const [employees, setEmployees] = useState('1-50');

  // Absence Policies state
  const [sickLeave, setSickLeave] = useState('');
  const [pto, setPto] = useState('');
  const [parentalLeave, setParentalLeave] = useState('');

  const handleSaveChanges = async () => {
    const workspaceDetails = {
      companyName,
      industry,
      employees,
      sickLeave,
      pto,
      parentalLeave,
    };

    try {
      const response = await axios.patch(`${API_HOST}/v1/workspace`, workspaceDetails);
      if (response.status === 200) {
        alert('Workspace details updated successfully!');
      } else {
        alert('Error updating workspace details');
      }
    } catch (error) {
      console.error(error);
      alert('An unexpected error occurred');
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="items-ceneter mt-1 flex flex-row justify-between">
        <p className="rounded-lg bg-[#F4F4F5] px-3 py-1">
          Last update: {formatDate(new Date())}
        </p>
      </div>
      <div className="flex flex-row rounded-xl p-5 shadow">
        <div className="flex w-[50%] flex-col">
          <h5 className="text-base font-medium">Organization Information</h5>
          <p className="text-gray-500">Enter details about your organization</p>
        </div>
        <div className="flex w-[50%] flex-col">
          <div>
            <label
              htmlFor="company_name"
              className="mb-1 block text-base font-medium"
            >
              Company name
            </label>
            <Input
              type="text"
              placeholder="Company name"
              id="company_name"
              name="company_name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label
              htmlFor="industry"
              className="mb-1 block text-base font-medium"
            >
              Industry
            </label>
            <Select
              id="industry"
              name="industry"
              defaultValue="Technology"
              className="w-full"
              value={industry}
              onValueChange={(value) => setIndustry(value)}
            >
              <SelectItem value="Technology">Technology</SelectItem>
              <SelectItem value="Healthcare">Healthcare</SelectItem>
              <SelectItem value="Finance">Finance</SelectItem>
              <SelectItem value="Manufacturing">Manufacturing</SelectItem>
              <SelectItem value="Retail">Retail</SelectItem>
              <SelectItem value="Education">Education</SelectItem>
              <SelectItem value="Other">Other</SelectItem>
            </Select>
          </div>
          <div className="mt-2">
            <label
              htmlFor="employees"
              className="mb-1 block text-base font-medium"
            >
              Number of Employees
            </label>
            <Select
              id="employees"
              name="employees"
              defaultValue="1-50"
              className="w-full"
              value={employees}
              onValueChange={(value) => setEmployees(value)}
            >
              <SelectItem value="1-50">1-50</SelectItem>
              <SelectItem value="51-200">51-200</SelectItem>
              <SelectItem value="201-500">201-500</SelectItem>
              <SelectItem value="500+">500+</SelectItem>
            </Select>
          </div>
        </div>
      </div>
      <div className="flex flex-row rounded-xl p-5 shadow">
        <div className="flex w-[50%] flex-col">
          <h5 className="text-base font-medium">Absence Policies</h5>
          <p className="text-gray-500">
            Define and manage your company's absence policies
          </p>
          <p className="text-gray-500 mt-2">
            Last update: {formatDate(new Date())}
          </p>
        </div>
        <div className="flex w-[50%] flex-col">
          <div>
            <label
              htmlFor="sick_leave"
              className="mb-1 block text-base font-medium"
            >
              Sick Leave
            </label>
            <Input
              type="text"
              placeholder="24"
              id="sick_leave"
              name="sick_leave"
              value={sickLeave}
              onChange={(e) => setSickLeave(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="pto" className="mb-1 block text-base font-medium">
              Paid Time Off(PTO)
            </label>
            <Input
              type="text"
              placeholder="12"
              id="pto"
              name="pto"
              value={pto}
              onChange={(e) => setPto(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label
              htmlFor="parental_leave"
              className="mb-1 block text-base font-medium"
            >
              Parental Leave
            </label>
            <Input
              type="text"
              placeholder="12"
              id="parental_leave"
              name="parental_leave"
              value={parentalLeave}
              onChange={(e) => setParentalLeave(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-4">
        <button className="flex items-center rounded-lg border-[1px] px-3 py-1 font-normal shadow">
          Go Back
        </button>
        <button
          className="flex items-center rounded-lg bg-[#DC2626] px-3 py-1 font-normal text-white"
          onClick={handleSaveChanges}
        >
          Save changes
        </button>
      </div>
    </div>
  );
}
