import { useEffect, useState } from 'react';
import moment from 'moment';
import { getDay } from 'date-fns';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react';
import {
  UserGroupIcon,
  BuildingOfficeIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { Position, Tooltip } from 'evergreen-ui';
import clsx from 'clsx';

import { Select } from '../../ui/form/Select';
import { Spinner } from '../../ui/Spinner';
import { UserPreview } from './components/UserPreview';
import { UserType } from '../../types';
import { EmptyState } from './components/EmptyState';
import ElevenLabsWidget from '../../components/ElevenLabsWidget';

// Custom Card component
const Card = ({ children, className = '' }: { children: React.ReactNode, className?: string }) => (
  <div className={`bg-white shadow-sm rounded-xl overflow-hidden ${className}`}>
    {children}
  </div>
);

// Create an enhanced UserAvatar component
const UserAvatar = ({ user }: { user: UserType }) => {
  return (
    <Tooltip
      key={user.id}
      content={user.full_name}
      position={Position.TOP}
    >
      <Link to={`/profile/${user.id}`} className="p-1">
        <motion.img
          whileHover={{ scale: 1.05 }}
          alt={user.full_name}
          src={user.safe_profile_picture_url}
          className="h-12 w-12 rounded-full bg-gray-100 object-cover ring-2 ring-white shadow-sm"
        />
      </Link>
    </Tooltip>
  );
};

// Create a StatusBadge component
const StatusBadge = ({ status }: { status: string }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'In office':
        return 'bg-green-100 text-green-800';
      case 'Working remotely':
        return 'bg-blue-100 text-blue-800';
      case 'Holiday':
        return 'bg-yellow-100 text-yellow-800';
      case 'Sick leave':
        return 'bg-red-100 text-red-800';
      case 'Parental leave':
        return 'bg-purple-100 text-purple-800';
      case 'Other leave':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${getStatusColor(status)}`}>
      {status}
    </span>
  );
};

type Person = {
  id: string;
  name: string;
};

type Team = {
  id: string;
  name: string;
};

type Office = {
  id: string;
  name: string;
};

const { REACT_APP_API_HOST } = process.env;
const API_HOST = process.env.REACT_APP_API_HOST;

const Overview = () => {
  const [token, setToken] = useState<string>();
  const [currentOffice, setCurrentOffice] = useState<string>('');
  const [currentTeam, setCurrentTeam] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {}, [token]);

  const { data: response = [], refetch } = useQuery({
    queryKey: ['home-section', { office: currentOffice, team: currentTeam }],
    queryFn: async ({ queryKey }) => {
      setIsLoading(true);
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/people/overview`,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          office: currentOffice,
          team: currentTeam,
        },
      });
      setIsLoading(false);
      return data;
    },
    staleTime: 300000,
    cacheTime: 900000,
  });

  const { data: offices = [] } = useQuery({
    queryKey: ['offices'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/office`,
      });
      return data;
    },
  });

  const { data: teams = [] } = useQuery({
    queryKey: ['teams'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/team`,
      });
      return data;
    },
  });

  useEffect(() => {
    if (currentOffice !== '0' || currentTeam !== '0') {
      refetch();
    }
  }, [currentOffice, currentTeam, refetch]);

  const renderStatus = () => {
    const showStatusMessage = (status: string) => {
      switch (status) {
        case 'In office':
          return 'working in the office';
        case 'Working remotely':
          return 'working remotely';
        case 'Not contracted to work':
          return 'not contracted to work today';
        case 'Non-contracted day':
          return 'not contracted day today';
        case 'Holiday':
          return 'on holiday';
        case 'Sick leave':
          return 'sick';
        case 'Parental leave':
          return 'on parental leave';
        case 'Other leave':
          return 'on other leave';
      }
    };

    function isWeekday(date: Date): boolean {
      const day = getDay(date);
      return day !== 0 && day !== 6;
    }

    const statusUsers =
      response?.status?.flatMap(({ users }: any) => users) || [];

    const remoteUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Working remotely',
    );
    const contractedToWorkUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Not contracted to work',
    );
    const contractedDaykUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Non-contracted day',
    );
    const holidayUsers = statusUsers.filter((user: any) =>
      user.status_now.includes('Holiday'),
    );
    const sickLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Sick leave',
    );
    const parentalLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Parental leave',
    );
    const otherLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Other leave',
    );

    const summary = [
      { label: 'working remotely', count: remoteUsers.length, icon: <UserIcon className="h-4 w-4 text-blue-500" /> },
      { label: 'on holiday', count: holidayUsers.length, icon: <UserIcon className="h-4 w-4 text-yellow-500" /> },
      { label: 'on sick leave', count: sickLeaveUsers.length, icon: <UserIcon className="h-4 w-4 text-red-500" /> },
      { label: 'on parental leave', count: parentalLeaveUsers.length, icon: <UserIcon className="h-4 w-4 text-purple-500" /> },
      { label: 'on other leave', count: otherLeaveUsers.length, icon: <UserIcon className="h-4 w-4 text-gray-500" /> },
      { label: 'not contracted to work', count: contractedToWorkUsers.length, icon: <UserIcon className="h-4 w-4 text-gray-500" /> },
      { label: 'not contracted day today', count: contractedDaykUsers.length, icon: <UserIcon className="h-4 w-4 text-gray-500" /> },
    ];

    const summaryMessage = summary
      .filter((item) => item.count > 0)
      .map((item) => `${item.count} ${item.label}`)
      .reduce((acc, current, index, array) => {
        return acc + current + (index < array.length - 1 ? ', ' : '');
      }, '');

    const office_users = response?.office?.map(
      ({ office_name, users }: any, index: number) => {
        return (
          <Card key={index} className="mb-6 overflow-hidden">
            <div className="px-6 py-4">
              <div className="mb-4 flex items-center gap-2">
                <BuildingOfficeIcon className="h-5 w-5 text-gray-500" />
                <h3 className="text-base font-medium text-gray-900">
                  <span className="text-indigo-600">{users.length} people </span>
                  in the {office_name} office
                </h3>
              </div>
              <div className="-mx-1 flex flex-wrap">
                {users.map((user: UserType) => {
                  if (
                    user.status_now === 'In office' ||
                    user.office_name === 'Elstree'
                  ) {
                    return <UserAvatar key={user.id} user={user} />;
                  }
                  return null;
                })}
              </div>
            </div>
          </Card>
        );
      },
    );

    const status_users = response?.status
      ?.filter(({ status_now }: any) => status_now !== 'In office')
      .map(({ status_now, users }: any, index: number) => {
        return (
          <Card key={index} className="mb-6 overflow-hidden">
            <div className="px-6 py-4">
              <div className="mb-4 flex items-center gap-2">
                <UserIcon className="h-5 w-5 text-gray-500" />
                <h3 className="text-base font-medium text-gray-900">
                  <span className="text-indigo-600">{users.length} people </span> 
                  <StatusBadge status={status_now} />
                </h3>
              </div>
              <div className="-mx-1 flex flex-wrap">
                {users &&
                  users.map((user: UserType) => {
                    if (user?.status_now !== 'In office') {
                      return <UserAvatar user={user} key={user.id} />;
                    }
                    return null;
                  })}
              </div>
            </div>
          </Card>
        );
      });

    const renderUsers = () => {
      let users: any[] = [];

      if (isWeekday(new Date())) {
        users = users.concat(office_users);
      }

      users = users.concat(status_users);
      return users.map((user) => user);
    };

    return (
      <div className="bg-white shadow-sm rounded-xl overflow-hidden">
        <div className="border-b border-gray-200 px-6 py-5">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">
                Team Status Today
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                A list of all employees and their locations.
              </p>
            </div>

            <div className="flex flex-col sm:flex-row items-center gap-3">
              <div className="relative w-full sm:w-auto">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <UserGroupIcon className="h-4 w-4 text-gray-400" />
                </div>
                <Select 
                  onChange={(event) => setCurrentTeam(event.target.value)}
                  className="w-full sm:w-auto bg-white border-gray-300 rounded-md shadow-sm text-sm pl-10 pr-10 py-2"
                >
                  <option value={''}>Everyone</option>
                  {teams.data &&
                    teams.data.map((team: Team) => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                </Select>
              </div>
              
              <div className="relative w-full sm:w-auto">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <BuildingOfficeIcon className="h-4 w-4 text-gray-400" />
                </div>
                <Select 
                  onChange={(event) => setCurrentOffice(event.target.value)}
                  className="w-full sm:w-auto bg-white border-gray-300 rounded-md shadow-sm text-sm pl-10 pr-10 py-2"
                >
                  <option value={''}>All locations</option>
                  {offices.data &&
                    offices.data.map((office: Office) => (
                      <option key={office.id} value={office.id}>
                        {office.name}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center py-12">
            <Spinner className="h-10 w-10 text-indigo-600" />
          </div>
        ) : (
          <>
            <div className="p-6 space-y-6">{renderUsers()}</div>

            <div className="bg-gray-50 px-6 py-4 border-t border-gray-200">
              {summaryMessage.length > 0 ? (
                <div className="flex flex-wrap gap-3">
                  {summary.filter(item => item.count > 0).map((item, index) => (
                    <div key={index} className="flex items-center gap-1 text-sm text-gray-600">
                      {item.icon}
                      <span className="font-medium">{item.count}</span> {item.label}
                    </div>
                  ))}
                </div>
              ) : response?.office?.length !== 0 ? (
                <p className="text-sm text-gray-600">
                  No one is recorded as working remotely, on holiday, on
                  parental leave, on sick leave, or away for other reasons
                </p>
              ) : (
                <p className="text-sm text-gray-600">
                  No users found with current filters.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return <>{renderStatus()}</>;
};

// Skeleton loading component
const SkeletonLoading = () => (
  <div className="animate-pulse space-y-6 p-6">
    <div className="flex items-center gap-2 mb-4">
      <div className="h-5 w-5 bg-gray-200 rounded"></div>
      <div className="h-5 w-48 bg-gray-200 rounded"></div>
    </div>
    <div className="flex flex-wrap gap-2">
      {[...Array(8)].map((_, i) => (
        <div key={i} className="h-12 w-12 bg-gray-200 rounded-full"></div>
      ))}
    </div>
    <div className="flex items-center gap-2 mb-4 mt-8">
      <div className="h-5 w-5 bg-gray-200 rounded"></div>
      <div className="h-5 w-32 bg-gray-200 rounded"></div>
    </div>
    <div className="flex flex-wrap gap-2">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="h-12 w-12 bg-gray-200 rounded-full"></div>
      ))}
    </div>
  </div>
);

export function HomeRoute() {
  const navigate = useNavigate();

  const { data: response, status } = useQuery({
    queryKey: ['onboarding-status'],
    queryFn: async () => {
      const { data } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/onboarding`,
      });

      return data;
    },
  });

  useEffect(() => {
    if (status === 'success' && response?.data.status === 'onboarding') {
    }
  }, [response?.data.status, status]);

  const handleOnboardingNavigate = () => {
    navigate('/onboarding');
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {response?.data?.status === 'onboarding' && (
        <EmptyState handleOnboardingNavigate={handleOnboardingNavigate} />
      )}
      
      {response?.data?.status !== 'onboarding' && (
        <div className="space-y-6">
          <Greeting />
          
          <TabGroup>
            <TabList className="flex p-1 space-x-1 bg-gray-100 rounded-xl">
              <Tab className={({ selected }) =>
                clsx(
                  'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                  'flex items-center justify-center gap-2 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow text-indigo-700'
                    : 'text-gray-600 hover:bg-white/[0.12] hover:text-gray-700'
                )
              }>
                <UserGroupIcon className="h-5 w-5" />
                <span>Overview</span>
              </Tab>
            </TabList>
            
            <TabPanels className="mt-4">
              <TabPanel>
                {status === 'loading' ? (
                  <Card>
                    <SkeletonLoading />
                  </Card>
                ) : (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Overview />
                  </motion.div>
                )}
              </TabPanel>
            </TabPanels>
          </TabGroup>
          
          <ElevenLabsWidget isVisible={true} />
        </div>
      )}
    </div>
  );
}

function Greeting() {
  const { user } = useUser();

  const { data: response } = useQuery({
    queryKey: ['identity-state'],
    queryFn: async () => {
      const { data } = await axios.get(`${API_HOST}/v1/identity/state`);
      return data;
    },
  });

  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 18) return 'Good afternoon';
    return 'Good evening';
  };

  const getStatusEmoji = (status?: string) => {
    if (!status) return '👋';
    
    if (status.includes('office')) return '🏢';
    if (status.includes('remote')) return '🏠';
    if (status.includes('Holiday')) return '🏖️';
    if (status.includes('Sick')) return '🤒';
    if (status.includes('Parental')) return '👶';
    
    return '👋';
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-r from-orange-400 to-red-600 text-white rounded-xl p-8 shadow-lg"
    >
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div>
          <h1 className="text-3xl font-bold mb-1">
            {getTimeBasedGreeting()}, {user?.fullName} {getStatusEmoji(response?.state?.status)}
          </h1>
          <h3 className="text-xl opacity-90 font-light">
            {response?.state?.status || 'Welcome to Redstone HR'}
          </h3>
        </div>
        <div className="mt-4 md:mt-0">
          <div className="bg-white/20 backdrop-blur-sm rounded-lg py-2 px-4 text-white font-medium shadow-inner">
            {moment().format('dddd, MMMM Do YYYY')}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
