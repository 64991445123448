import { Checkbox } from '../../../ui/component/checkbox';
import { UserIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon, Menu, Popover, Position } from 'evergreen-ui';
import {
  ArrowPathIcon,
  CheckCircleIcon,
  UserCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Icon, TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { Pagination } from '../../../ui/component/pagination';
import timeaway from '../../../network/timeaway';
import usePagination from '../../../hooks/usePagination';
import { useState } from 'react';

// Define item interface
interface FilterItem {
  id: string;
  name?: string;
  label?: string;
  [key: string]: any; // For any other properties
}


// New reusable component for filter popover menus

export function UserTable({
  handleApproveModal,
  handleRejectModal,
  handleEmployeeDetails,
}: any) {
  const [limit, setLimit] = useState(10);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const {
    data: users = [],
    page,
    totalItems,
    setDirectPage,
  } = usePagination({
    key: 'users_requests',
    params: 'manager',
    fetcher: timeaway.getTimesOff,
    initialPage: 0,
    initialItemsPerPage: limit,
  });

  const handleSelectRow = (userId: string, checked: boolean) => {
    if (checked) {
      setSelectedUsers((prev: string[]) => [...prev, userId]);
    } else {
      setSelectedUsers((prev: string[]) => prev.filter((id) => id !== userId));
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedUsers(users?.map((user: any) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const isAllSelected =
    users.length > 0 && selectedUsers.length === users.length;
  return (
    <div className="mt-2 rounded-xl border-[1px] border-gray-100 px-6 py-2 shadow">
      <div className="flex flex-row justify-end gap-4">
        <TextInput
          icon={RiSearchLine}
          placeholder="Search"
          className="w-auto lg:w-[200px]"
        />
        <button
          className="flex items-center 
            rounded-lg border-[1px] px-3 font-normal shadow"
        >
          <Icon icon={FunnelIcon} size="sm" color="blue" />
          Filter
        </button>
      </div>
      <div className="overflow-hidden">
        <div className=" flow-root w-full">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <table className="w-full divide-y divide-gray-300">
                <thead>
                  <th
                    scope="col"
                    className=" flex items-center gap-3 py-3.5 pl-4 text-left text-sm font-semibold text-gray-500 sm:pl-0"
                  >
                    <Checkbox
                      className="rounded-md border-[1px]"
                      color="rose"
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                    Employee
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Leave type
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Dates Requested
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Duration
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Approval status
                  </th>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {users &&
                    users?.map((user: any) => (
                      <tr key={user.id}>
                        <td className="whitespace-nowrap py-2 pl-4 text-sm font-medium text-gray-900 sm:pl-0">
                          <div className="flex items-center gap-2">
                            <Checkbox
                              className="rounded-md border-[1px]"
                              color="rose"
                              checked={selectedUsers.includes(user.id)}
                              onChange={(checked) =>
                                handleSelectRow(user.id, checked)
                              }
                            />
                            <UserIcon className="w-5" />
                            {user?.full_name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm ">
                          {user?.type}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {user?.start} - {user?.end}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {user?.details}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm ">
                          {user.decision === 'pending' ? (
                            <div className="flex max-w-[90px] justify-center rounded-lg bg-[#FFEDD5] px-2 py-1 text-[#C2410C]">
                              Pending
                            </div>
                          ) : user.decision === 'approved' ? (
                            <p className="flex max-w-[90px] justify-center rounded-lg bg-[#DCFCE7] px-2 py-1 text-[#15803D]">
                              Approved
                            </p>
                          ) : user.decision === 'declined' ? (
                            <div className="flex max-w-[90px] justify-center rounded-lg bg-[#FEE2E2] px-2 py-1 text-[#DC2626]">
                              Rejected
                            </div>
                          ) : (
                            <div className="flex max-w-[200px] justify-center text-wrap rounded-lg bg-[#FEF9C3] px-2 py-1 text-[#A16207]">
                              Awaiting employee updates
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium sm:pr-0">
                          <Popover
                            position={Position.LEFT}
                            content={
                              <Menu>
                                <Menu.Group>
                                  <Menu.Item>
                                    <div
                                      onClick={() => handleApproveModal(user)}
                                      className="flex flex-row flex-nowrap gap-2"
                                    >
                                      <CheckCircleIcon
                                        color="gray"
                                        className="w-4"
                                      />
                                      Approve
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <div
                                      onClick={() => handleRejectModal(user)}
                                      className="flex flex-row flex-nowrap gap-2"
                                    >
                                      <XCircleIcon
                                        color="gray"
                                        className="w-4"
                                      />
                                      Reject
                                    </div>
                                  </Menu.Item>
                           
                                  <Menu.Item>
                                    <div
                                      onClick={() =>
                                        handleEmployeeDetails(user.id)
                                      }
                                      className="flex flex-row flex-nowrap gap-2"
                                    >
                                      <UserCircleIcon
                                        color="gray"
                                        className="w-4"
                                      />
                                      Employee Details
                                    </div>
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <EllipsisHorizontalIcon className="h-5 w-5 cursor-pointer" />
                          </Popover>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                limit={limit}
                count={totalItems}
                page={page}
                onPageChange={setDirectPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
