// 'use client';

import { RiAddFill, RiBookOpenLine, RiDatabase2Line, RiLineChartLine } from '@remixicon/react';
import { Accordion, AccordionBody, AccordionHeader, Card, Flex, Text, Title, Badge } from '@tremor/react';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { useState, useMemo } from 'react';
import { KpiModal } from './KpiModal';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { reportService } from '../../services/report';
import { ReportType } from '@/types/report.types';

function ContentPlaceholder() {
  return (
    <div className="relative h-full overflow-hidden rounded bg-gray-50">
      <svg
        className="stroke-gray-20 absolute inset-0 h-full w-full"
        fill="none"
      >
        <defs>
          <pattern
            id="pattern-1"
            x="0"
            y="0"
            width="10"
            height="10"
            patternUnits="userSpaceOnUse"
          >
            <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
          </pattern>
        </defs>
        <rect
          stroke="none"
          fill="url(#pattern-1)"
          width="100%"
          height="100%"
        ></rect>
      </svg>
    </div>
  );
}

export default function Example() {
  const [showKpiModal, setShowKpiModal] = useState<boolean>(false);
  const [currentKpi, setCurrentKpi] = useState<ReportType | null>(null);
  const navigate = useNavigate();

  // Fetch report types
  const { data: reportTypes, isLoading } = useQuery('reportTypes', reportService.getReportTypes);

  // Group reports by category
  const reportsByCategory = useMemo(() => {
    if (!reportTypes) return {};
    
    return reportTypes.reduce((acc, report) => {
      if (!acc[report.category]) {
        acc[report.category] = [];
      }
      acc[report.category].push(report);
      return acc;
    }, {} as Record<string, ReportType[]>);
  }, [reportTypes]);

  const handleKpiClick = (report: ReportType) => {
    navigate(`/reports/${report.type}`);
  };

  // Render report card
  const ReportCard = ({ report }: { report: ReportType }) => (
    <Card
      key={report.id}
      className="relative rounded-tremor-small p-2 cursor-pointer"
      onClick={() => handleKpiClick(report)}
    >
      <div className="h-20">
        <ContentPlaceholder />
      </div>
      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
        {report.name}
      </h3>
      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
        {report.description}
      </p>
    </Card>
  );

  if (isLoading) {
    return <div>Loading reports...</div>;
  }

  return (
    <>
      <KpiModal
        isOpen={showKpiModal}
        onClose={() => setShowKpiModal(false)}
        kpi={currentKpi}
      />
      <div className="border-b border-tremor-border bg-tremor-background-muted p-4 sm:p-6 lg:p-8">
        <header>
          <h1 className="text-tremor-title font-semibold text-tremor-content-strong">
            Dashboard and Reports
          </h1>
          <p className="text-tremor-default text-tremor-content">
            Explore and manage your reports
          </p>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <Card>
              <button 
                className="w-full text-left"
                onClick={() => navigate('/reports/summary')}
              >
                <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                  <RiBookOpenLine
                    className="dark:text-dark-tremor-content size-5 text-tremor-content-subtle"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                  Executive Summary Dashboard
                </h3>
                <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                  High-level overview of key metrics and trends for senior management.
                </p>
              </button>
            </Card>
            
            <Card>
              <button 
                className="w-full text-left"
                onClick={() => navigate('/reports/absence')}
              >
                <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                  <RiDatabase2Line
                    className="dark:text-dark-tremor-content size-5 text-tremor-content-subtle"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                  Detailed Absence Reports
                </h3>
                <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                  Comprehensive reports breaking down absences by department, team, individual employees.
                </p>
              </button>
            </Card>
            
            <Card>
              <button 
                className="w-full text-left"
                onClick={() => navigate('/reports/absence-trends')}
              >
                <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                  <RiLineChartLine
                    className="dark:text-dark-tremor-content size-5 text-tremor-content-subtle"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                  Absence Trends
                </h3>
                <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                  Visualize absence trends over time by department, role, or employee with interactive charts.
                </p>
              </button>
            </Card>
            
            <Card>
              <button 
                className="w-full text-left"
                onClick={() => navigate('/reports/bradford-factor')}
              >
                <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                  <RiDatabase2Line
                    className="dark:text-dark-tremor-content size-5 text-tremor-content-subtle"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                  Bradford Factor
                </h3>
                <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                  Identify frequent, short-term absence patterns using the Bradford Factor formula (S² × D).
                </p>
              </button>
            </Card>
            
            <Card>
              <button 
                className="w-full text-left"
                onClick={() => navigate('/reports/compliance')}
              >
                <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                  <RiDatabase2Line
                    className="dark:text-dark-tremor-content size-5 text-tremor-content-subtle"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                  Compliance and Policy Reports
                </h3>
                <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                  Reports focused on compliance with leave policies and regulations.
                </p>
              </button>
            </Card>
            
            <Card>
              <button 
                className="w-full text-left"
                onClick={() => navigate('/reports/leave-balance')}
              >
                <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                  <RiDatabase2Line
                    className="dark:text-dark-tremor-content size-5 text-tremor-content-subtle"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                  Leave Balance & Accruals
                </h3>
                <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                  Current time-off balances and accruals for all employees with forecasting capabilities.
                </p>
              </button>
            </Card>

            <Card
              decoration="top"
              decorationColor="rose"
              className="max-w-lg cursor-pointer hover:shadow-md transition-all duration-300"
              onClick={() => navigate('/reports/absenteeism-risk')}
            >
              <Flex alignItems="start">
                <div>
                  <Text>Absenteeism Risk Report</Text>
                  <Title className="mt-2">Risk Indicators</Title>
                  <Text className="mt-2">
                    Identifies employees with potential absenteeism issues based on frequency, 
                    patterns, and duration. Helps proactively address burnout or disengagement.
                  </Text>
                  <Flex className="mt-4">
                    <Badge color="rose">Risk Engine</Badge>
                    <Badge color="orange" className="ml-2">Configurable Rules</Badge>
                    <Badge color="emerald" className="ml-2">Department Summary</Badge>
                  </Flex>
                </div>
                <CheckBadgeIcon className="h-8 w-8 text-rose-500" />
              </Flex>
            </Card>
          </div>
        </header>
      </div>
      <div>
        <div className="py-6">
          <main>
            <div className="flex items-center justify-between">
              <h2 className="dark:text-dark-tremor-content-strong text-tremor-title font-semibold text-tremor-content-strong">
                Your statistics
              </h2>
              <button
                type="button"
                className="dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:hover:bg-dark-tremor-brand-emphasis dark:shadow-dark-tremor-input inline-flex items-center gap-1.5 whitespace-nowrap rounded-tremor-small bg-tremor-brand px-4 py-2.5 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
              >
                <RiAddFill
                  className="-ml-1 size-5 shrink-0"
                  aria-hidden="true"
                />
                Add report
              </button>
            </div>
            <Accordion className="mt-6" defaultOpen={true}>
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Recent (3)
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {reportTypes?.slice(0, 3).map((report) => (
                    <ReportCard key={report.id} report={report} />
                  ))}
                </div>
              </AccordionBody>
            </Accordion>

            {/* Dynamic categories */}
            {Object.entries(reportsByCategory).map(([category, reports]) => (
              <Accordion key={category} className="mt-6">
                <AccordionHeader className="text-sm font-medium text-gray-700">
                  {category.replace('_', ' ').charAt(0).toUpperCase() + category.slice(1)}
                </AccordionHeader>
                <AccordionBody>
                  <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                    {reports.map((report) => (
                      <ReportCard key={report.id} report={report} />
                    ))}
                  </div>
                </AccordionBody>
              </Accordion>
            ))}
          </main>
        </div>
      </div>
    </>
  );
}
