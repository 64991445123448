import { Icon } from '@tremor/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { EmptyStateIcon } from '../../../assets/EmptyState/EmptyStateIcon';
import { useState } from 'react';
import HandleNewLocation from './HandleNewLocation';
import { OfficeCard } from './OfficeCard';
import DeleteModal from './DeleteModal';
import { useGetOffices } from '../hooks';
import { useToast } from '../../../utils/useToast';
import officesManagement from '../../../network/officesManagement';
import { Spinner } from '../../../ui/Spinner';

export function Offices() {
  const [isOpenAddNewLocation, setIsOpenAddNewLocation] = useState(false);
  const [isOpenEditLocation, setIsOpenEditLocation] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [currentOffice, setCurrentOffice] = useState({
    id: '',
    office: '',
    address: '',
    country: {
      country: '',
      line1: '',
      town: '',
    },
    city: '',
    phone: '',
  });

  const { data: offices = [], refetch: refetchOffices, isLoading } = useGetOffices();
  const { toast } = useToast();

  const handleEditOffice = (office: any) => {
    setCurrentOffice(office);
    setIsOpenEditLocation(true);
  };

  const handleDeleteOfficeModal = (office: any) => {
    setCurrentOffice(office);
    setIsOpenDeleteModal(true);
  };

  const handleDeletOffice = async (id: string) => {
    try {
      await officesManagement.deleteOffice(id);
      refetchOffices();
      toast({
        variant: 'success',
        title: 'Office successfully deleted.',
      });
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Something went wrong. Please try again later.',
      });
    }
  };

  if(isLoading){
    return (
      <div className='h-[75vh] flex flex-col gap-3 justify-center items-center'>
        <Spinner size={10}/>
        <p className='font-semibold'>
          Loading...
        </p>
      </div>
    )
  }

  return (
    <>
      {offices?.length <= 0 ? (
        <>
          <div className="flex h-[72vh] w-full flex-col items-center justify-center gap-4">
            <EmptyStateIcon />
            <div>
              <p className="text-center font-semibold">
                No office location have been added
              </p>
              <p className=" text-center">
                Add new location to optimize management.
              </p>
            </div>
            <button
              onClick={() => setIsOpenAddNewLocation(true)}
              className="flex items-center gap-1 rounded-lg bg-[#DC2626] px-5 py-1 text-white"
            >
              <Icon icon={PlusIcon} size="sm" style={{ color: '#FFFFFF' }} />
              Add new location
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row items-center justify-between">
            <h6 className="font-medium">Offices</h6>
            <button
              onClick={() => setIsOpenAddNewLocation(true)}
              className="flex items-center gap-1 rounded-lg bg-[#DC2626] px-5 py-1 text-white"
            >
              <Icon icon={PlusIcon} size="sm" style={{ color: '#FFFFFF' }} />
              Add new location
            </button>
          </div>
          <div className="mt-4 grid grid-cols-3 gap-3">
            {offices?.map((office: any) => (
              <OfficeCard
                office={office}
                key={office.id}
                handleEditOffice={handleEditOffice}
                handleDeleteOfficeModal={handleDeleteOfficeModal}
              />
            ))}
          </div>
        </>
      )}
      <HandleNewLocation
        open={isOpenAddNewLocation}
        setOpen={setIsOpenAddNewLocation}
        setCurrentOffice={setCurrentOffice}
        mode="create"
        refetchOffices={refetchOffices}
      />
      <HandleNewLocation
        open={isOpenEditLocation}
        setOpen={setIsOpenEditLocation}
        setCurrentOffice={setCurrentOffice}
        currentOffice={currentOffice}
        mode="edit"
        refetchOffices={refetchOffices}
      />
      <DeleteModal
        open={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        id={currentOffice.id}
        handleDelete={handleDeletOffice}
        header="office"
        content="Deleting this office location is permanent and cannot be undone. 
                 Any associated information will be removed from the organization structure."
      />
    </>
  );
}
