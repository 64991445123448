import { CALENDAR_MODE__MONTH, CALENDAR_MODE__WEEK } from '../../../constants';
import { CalendarBodyProps } from '../../../types/RotaTypes';
import { DayCell } from './DayCell';
import { WeekDayCell } from './WeekDayCell';
import { Card } from '../../../ui/Card';

const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export function CalendarBody({
  today,
  daysMap,
  rotas,
  mode,
  onRotaClick
}: CalendarBodyProps) {
  return (
    <Card className="overflow-hidden border-0 shadow-sm">
      <div className="flex flex-auto flex-col">
        {mode === CALENDAR_MODE__MONTH && (
          <div className="grid grid-cols-7 divide-x divide-slate-200 border-b border-slate-200 bg-slate-50 text-center text-xs font-medium text-slate-700">
            {DAYS_OF_WEEK.map((day) => (
              <div key={day} className="py-2">{day}</div>
            ))}
          </div>
        )}
        
        <div className={`flex flex-auto ${mode === CALENDAR_MODE__MONTH ? 'bg-slate-50' : 'bg-white'} text-sm leading-6 text-slate-700`}>
          {mode === CALENDAR_MODE__MONTH ? (
            <div className="grid w-full grid-cols-7 divide-x divide-slate-200 sm:grid-rows-6">
              {daysMap.map((day, index) => (
                <DayCell 
                  key={index} 
                  today={today} 
                  day={day} 
                  rotas={rotas} 
                  onRotaClick={onRotaClick}
                />
              ))}
            </div>
          ) : (
            <WeekDayCell 
              days={daysMap} 
              rotas={rotas} 
              today={today} 
              onRotaClick={onRotaClick}
            />
          )}
        </div>
      </div>
    </Card>
  );
}
