import axios from 'axios';
import { ICreateOffce } from '../types/officesTypes';

const API_HOST = process.env.REACT_APP_API_HOST;

async function getOffices() {
  const { data } = await axios.get(`${API_HOST}/v1/office`);
  return data;
}

async function createOffice(body: ICreateOffce) {
  const response = await axios.post(`${API_HOST}/v1/office`, { value: body });
  return response;
}

async function deleteOffice(id: string) {
  const response = await axios.delete(`${API_HOST}/v1/office/${id}`);
  return response;
}

async function updateOffice({ id, body }: { id: string; body: ICreateOffce }) {
  const response = await axios.patch(`${API_HOST}/v1/office/${id}`, {
    value: body,
  });
  return response;
}

/**
 * Fetch all countries from the API
 */
async function getCountries() {
  const { data } = await axios.get(`${API_HOST}/v1/office/countries`);
  return data?.data || [];
}

/**
 * Fetch cities for a specific country
 * @param countryCode - ISO country code (GB, DE, etc.)
 */
async function getCitiesByCountry(countryCode: string) {
  if (!countryCode) return [];
  
  const { data } = await axios.get(`${API_HOST}/v1/office/cities/${countryCode}`);
  return data?.data || [];
}

/**
 * Search for addresses using the backend API
 * @param countryCode Country code for postcoder search
 * @param query Search query (postal code or street name)
 * @returns Array of address objects
 */
async function searchAddresses(countryCode: string, query: string) {
  try {
    if (!countryCode || !query || query.length < 3) {
      return [];
    }

    const response = await axios.get(
      `${API_HOST}/v1/office/address-search/${countryCode}?query=${encodeURIComponent(query)}`
    );
    
    // Return the data array if the request was successful
    if (response.data?.status === 'SEARCH_ADDRESSES_SUCCESS' && Array.isArray(response.data?.data)) {
      return response.data.data;
    }
    
    return [];
  } catch (error) {
    console.error('Error in searchAddresses:', error);
    return [];
  }
}

export default {
  getOffices,
  createOffice,
  deleteOffice,
  updateOffice,
  getCountries,
  getCitiesByCountry,
  searchAddresses,
};
