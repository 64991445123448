import { useEffect, useState } from 'react';
import { Checkbox } from '../../../../../../ui/component/checkbox';
import { Menu, Popover, Position } from 'evergreen-ui';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Icon } from '@tremor/react';
import { ChevronLeftIcon, FunnelIcon } from '@heroicons/react/24/solid';
import { useGetDepartments } from '../../../../../../routes/Organization/hooks';
import onboardingProcess from '../../../../../../network/onboardingProcess';

const positionOptions = [
  {
    id: 1,
    label: 'Sales manager',
  },
  {
    id: 2,
    label: 'Senior Security GRC Manager',
  },
  {
    id: 3,
    label: 'Digital Marketing Manager',
  },
  {
    id: 4,
    label: 'Target Manager',
  },
  {
    id: 5,
    label: 'Marketing Manager',
  },
  {
    id: 6,
    label: 'Analyst',
  },
  {
    id: 7,
    label: 'Compensation Partner',
  },
  {
    id: 8,
    label: 'Client Partner',
  },
];

export function UserTable({ users = [] }: any) {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string[]>([]);
  const [selectedRole, setSelectedRow] = useState<string[]>([]);
  const [roleOptions, setRoleOptions] = useState<
    { id: string; name: string }[]
  >([]);

  const { data: departments = [] } = useGetDepartments();

  const handleSelectAll = (checked: boolean) => {
    if (checked && Array.isArray(users)) {
      setSelectedUsers(users.map((item: any) => item?.user?.id).filter(Boolean));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectRow = (userId: string, checked: boolean) => {
    if (checked) {
      setSelectedUsers((prev: string[]) => [...prev, userId]);
    } else {
      setSelectedUsers((prev: string[]) => prev.filter((id) => id !== userId));
    }
  };

  const handlePositionApply = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handlePositionClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedPositions([]);
  };

  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleDepartmentApply = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleDepartmentClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedDepartment([]);
  };

  const handleRoleApply = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleRoleClearAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedRow([]);
  };

  useEffect(() => {
    async function getInitialData() {
      const { data: roleOptions } =
        await onboardingProcess.getOnboardingRoles();
      setRoleOptions(roleOptions);
    }
    getInitialData();
  }, []);

  const isAllSelected =
    Array.isArray(users) && users.length > 0 && selectedUsers.length === users.length;

  const capitalizeFirstLetter = (str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div className="mb-3 flex justify-end gap-3">
        <Popover
          minWidth={'100px'}
          content={
            <Menu>
              <Menu.Group>
                <Popover
                  position={Position.LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <div className="flex flex-col gap-1">
                          {positionOptions.map((item) => (
                            <Menu.Item
                              key={item.id}
                              onClick={handleCheckboxClick}
                            >
                              <div className="flex flex-row items-center gap-2">
                                <Checkbox
                                  className="rounded-md border-[1px]"
                                  color="rose"
                                  checked={selectedPositions.includes(
                                    item.label,
                                  )}
                                  onChange={() => {
                                    setSelectedPositions((prev) =>
                                      prev.includes(item.label)
                                        ? prev.filter((el) => el !== item.label)
                                        : [...prev, item.label],
                                    );
                                  }}
                                />
                                {item.label}
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                        <div className="mt-1 flex flex-row justify-center gap-2">
                          <button
                            className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                            onClick={handlePositionClearAll}
                          >
                            Clear all
                          </button>
                          <button
                            onClick={handlePositionApply}
                            className="w-[40%] rounded-lg border-[1px] shadow"
                          >
                            Apply
                          </button>
                        </div>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <Menu.Item>
                    <div className="flex flex-row flex-nowrap gap-2">
                      <ChevronLeftIcon className="w-4" />
                      Position
                    </div>
                  </Menu.Item>
                </Popover>

                <Popover
                  position={Position.LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <div className="flex flex-col gap-1">
                          {departments?.map(
                            (item: { id: string; name: string }) => (
                              <Menu.Item
                                key={item.id}
                                onClick={handleCheckboxClick}
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <Checkbox
                                    className="rounded-md border-[1px]"
                                    color="rose"
                                    checked={selectedDepartment.includes(
                                      item.id,
                                    )}
                                    onChange={() => {
                                      setSelectedDepartment((prev) =>
                                        prev.includes(item.id)
                                          ? prev.filter((el) => el !== item.id)
                                          : [...prev, item.id],
                                      );
                                    }}
                                  />
                                  {item.name}
                                </div>
                              </Menu.Item>
                            ),
                          )}
                        </div>
                        <div className="mt-1 flex flex-row justify-center gap-2">
                          <button
                            className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                            onClick={handleDepartmentClearAll}
                          >
                            Clear all
                          </button>
                          <button
                            onClick={handleDepartmentApply}
                            className="w-[40%] rounded-lg border-[1px] shadow"
                          >
                            Apply
                          </button>
                        </div>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <Menu.Item>
                    <div className="flex flex-row flex-nowrap gap-2">
                      <ChevronLeftIcon className="w-4" />
                      Department
                    </div>
                  </Menu.Item>
                </Popover>

                <Popover
                  position={Position.LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <div className="flex flex-col gap-1">
                          {roleOptions.map((item) => (
                            <Menu.Item
                              key={item.id}
                              onClick={handleCheckboxClick}
                            >
                              <div className="flex flex-row items-center gap-2">
                                <Checkbox
                                  className="rounded-md border-[1px]"
                                  color="rose"
                                  checked={selectedRole.includes(item.id)}
                                  onChange={() => {
                                    setSelectedRow((prev) =>
                                      prev.includes(item.id)
                                        ? prev.filter((id) => id !== item.id)
                                        : [...prev, item.id],
                                    );
                                  }}
                                />
                                {item.name}
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                        <div className="mt-1 flex flex-row justify-center gap-2">
                          <button
                            className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                            onClick={handleRoleClearAll}
                          >
                            Clear all
                          </button>
                          <button
                            onClick={handleRoleApply}
                            className="w-[40%] rounded-lg border-[1px] shadow"
                          >
                            Apply
                          </button>
                        </div>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <Menu.Item>
                    <div className="flex flex-row flex-nowrap gap-2">
                      <ChevronLeftIcon className="w-4" />
                      Role
                    </div>
                  </Menu.Item>
                </Popover>
              </Menu.Group>
            </Menu>
          }
        >
          <button className="flex items-center rounded-lg border-[1px] px-3 font-normal shadow">
            <Icon icon={FunnelIcon} size="sm" color="blue" />
            Filter
          </button>
        </Popover>
        <button
          onClick={() => handleSelectAll(!isAllSelected)}
          className="flex items-center rounded-lg border-[1px] px-3 font-normal shadow"
        >
          Select All
        </button>
      </div>
      <div className="flow-root h-[47vh] w-full overflow-y-auto">
        <div className="inline-block min-w-full align-middle">
          <table className="w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className=" flex items-center gap-3 py-3.5 pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  <Checkbox
                    className="rounded-md border-[1px]"
                    color="rose"
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                  />
                  Employee name
                </th>
                <th
                  scope="col"
                  className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Position
                </th>
                <th
                  scope="col"
                  className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Department
                </th>
                <th
                  scope="col"
                  className="py-3.5 pr-20 text-end text-sm font-semibold text-gray-900"
                >
                  Role
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 ">
              {Array.isArray(users) && users.length > 0 &&
                users.map((user: any) => (
                  <tr key={user?.user?.id}>
                    <td className="whitespace-nowrap py-2 pl-4 text-sm font-medium sm:pl-0">
                      <section className="flex items-center gap-2">
                        <Checkbox
                          className="rounded-md border-[1px]"
                          color="rose"
                          checked={selectedUsers.includes(user?.user?.id)}
                          onChange={(checked) =>
                            handleSelectRow(user?.user?.id, checked)
                          }
                        />
                        <div className="flex flex-col ">
                          <p>
                            {user?.first_name} {user?.last_name}
                          </p>
                          <p className="text-sm text-gray-500">{user?.email}</p>
                        </div>
                      </section>
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm">
                      {user?.job_title}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm">
                      {user?.department}
                    </td>
                    <td className="whitespace-nowrap py-2 text-sm sm:pr-0">
                      <div className="mr-2  flex items-center justify-end text-gray-500">
                        <Popover
                          position={Position.BOTTOM_RIGHT}
                          content={
                            <Menu>
                              <Menu.Group>
                                {roleOptions.map((item) => (
                                  <Menu.Item key={user.id}>
                                    <div className="flex flex-row flex-nowrap gap-2 text-gray-600">
                                      {item.id === user.role ? (
                                        <CheckCircleIcon
                                          color="gray"
                                          className="w-4"
                                        />
                                      ) : null}
                                      {capitalizeFirstLetter(item.name)}
                                    </div>
                                  </Menu.Item>
                                ))}
                              </Menu.Group>
                            </Menu>
                          }
                        >
                          <div className="flex flex-row gap-2 rounded-lg border-[1px] px-3 py-2 shadow">
                            {capitalizeFirstLetter(user?.role)}
                            <EllipsisHorizontalIcon className="h-5 w-5 cursor-pointer" />
                          </div>
                        </Popover>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
