import React, { useState, useEffect } from 'react';
import {
  Card,
  Text,
  Title,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
  Select,
  SelectItem,
  AreaChart,
  DonutChart,
  Grid,
  Col,
  Flex,
  Badge,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Button,
  DateRangePicker,
  DateRangePickerItem
} from '@tremor/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { reportService, AbsenteeismRiskRequest, AbsenteeismRiskResponse, AbsenteeismRiskScore } from '../../services/report';
import { exportToExcel, exportToPdf } from '../../utils/export';

const AbsenteeismRiskReport: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [riskData, setRiskData] = useState<AbsenteeismRiskResponse | null>(null);
  
  // Filters
  const [departments, setDepartments] = useState<{ id: string; name: string }[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [locations, setLocations] = useState<{ id: string; name: string }[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>('all');
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date }>({
    from: new Date(new Date().setDate(new Date().getDate() - 90)), // Last 90 days
    to: new Date()
  });
  const [showInactive, setShowInactive] = useState<boolean>(false);
  
  // Risk level colors
  const riskLevelColors: { [key: string]: string } = {
    low: 'emerald',
    medium: 'amber',
    high: 'orange',
    critical: 'rose'
  };

  // Date ranges preset
  const dateRanges = [
    { name: 'Last 30 days', value: { from: new Date(new Date().setDate(new Date().getDate() - 30)), to: new Date() } },
    { name: 'Last 90 days', value: { from: new Date(new Date().setDate(new Date().getDate() - 90)), to: new Date() } },
    { name: 'Last 6 months', value: { from: new Date(new Date().setMonth(new Date().getMonth() - 6)), to: new Date() } },
    { name: 'Year to date', value: { from: new Date(new Date().getFullYear(), 0, 1), to: new Date() } }
  ];

  // Load departments and locations on initial render
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await reportService.getDepartments();
        if (response?.payload?.departments) {
          setDepartments(response.payload.departments);
        }
      } catch (err) {
        console.error('Error fetching departments:', err);
      }
    };

    const fetchLocations = async () => {
      try {
        const response = await reportService.getLocations();
        if (response?.payload?.locations) {
          setLocations(response.payload.locations);
        }
      } catch (err) {
        console.error('Error fetching locations:', err);
      }
    };

    Promise.all([fetchDepartments(), fetchLocations()]).then(() => {
      loadRiskData();
    });
  }, []);

  // Reload data when filters change
  useEffect(() => {
    if (departments.length > 0 && locations.length > 0) {
      loadRiskData();
    }
  }, [selectedDepartment, selectedLocation, dateRange, showInactive]);

  const loadRiskData = async () => {
    setLoading(true);
    setError(null);

    try {
      const request: AbsenteeismRiskRequest = {
        startDate: dateRange.from.toISOString().split('T')[0],
        endDate: dateRange.to.toISOString().split('T')[0],
        department: selectedDepartment,
        location: selectedLocation,
        includeInactive: showInactive
      };

      const response = await reportService.getAbsenteeismRiskReport(request);
      if (response?.payload?.risk_report) {
        setRiskData(response.payload.risk_report);
      } else {
        setError('Failed to load risk data');
      }
    } catch (err) {
      console.error('Error loading risk data:', err);
      setError('Error loading risk data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleExportToExcel = () => {
    if (!riskData) return;

    const data = riskData.employees.map(employee => ({
      'First Name': employee.firstName,
      'Last Name': employee.lastName,
      'Department': employee.department,
      'Role': employee.role,
      'Risk Score': employee.riskScore,
      'Risk Level': employee.riskLevel.toUpperCase(),
      'Absence Count': employee.absenceCount,
      'Absence Days': employee.absenceDays,
      'Last Absence': employee.lastAbsenceDate,
      'Bradford Factor': employee.bradfordFactor || 0,
      'Triggered Rules': employee.triggers.map(t => t.ruleName).join(', ')
    }));

    exportToExcel(data, 'Absenteeism_Risk_Report');
  };

  const handleExportToPdf = () => {
    if (!riskData) return;

    const title = 'Absenteeism Risk Report';
    const headers = ['Employee', 'Department', 'Risk Level', 'Score', 'Absences', 'Days', 'Triggered Rules'];
    const data = riskData.employees.map(employee => [
      `${employee.firstName} ${employee.lastName}`,
      employee.department,
      employee.riskLevel.toUpperCase(),
      employee.riskScore.toString(),
      employee.absenceCount.toString(),
      employee.absenceDays.toString(),
      employee.triggers.map(t => t.ruleName).join(', ')
    ]);

    exportToPdf(title, headers, data, 'Absenteeism_Risk_Report');
  };

  // Format risk level for display with badge
  const formatRiskLevel = (level: string) => {
    const color = riskLevelColors[level] || 'gray';
    return <Badge color={color}>{level.toUpperCase()}</Badge>;
  };

  // Prepare chart data for department risk distribution
  const getDepartmentRiskData = () => {
    if (!riskData) return [];
    
    return riskData.summary.departmentSummaries.map(dept => ({
      department: dept.department,
      'Average Risk Score': dept.averageRiskScore,
      'Critical': dept.riskCounts.critical,
      'High': dept.riskCounts.high,
      'Medium': dept.riskCounts.medium,
      'Low': dept.riskCounts.low,
    }));
  };

  // Prepare donut chart data for risk distribution
  const getRiskDistributionData = () => {
    if (!riskData) return [];
    
    return [
      { name: 'Critical', value: riskData.summary.riskDistribution.critical },
      { name: 'High', value: riskData.summary.riskDistribution.high },
      { name: 'Medium', value: riskData.summary.riskDistribution.medium },
      { name: 'Low', value: riskData.summary.riskDistribution.low }
    ];
  };

  return (
    <div className="p-4">
      <Flex justifyContent="between" alignItems="center" className="mb-6">
        <Title>Absenteeism Risk Report</Title>
        <Flex justifyContent="end" className="space-x-2">
          <Button 
            icon={ArrowDownTrayIcon} 
            variant="secondary" 
            onClick={handleExportToExcel}
            disabled={loading || !riskData}
          >
            Export to Excel
          </Button>
          <Button 
            icon={ArrowDownTrayIcon} 
            variant="secondary" 
            onClick={handleExportToPdf}
            disabled={loading || !riskData}
          >
            Export to PDF
          </Button>
        </Flex>
      </Flex>

      {error && (
        <Card className="mb-6 bg-red-50">
          <Text>{error}</Text>
        </Card>
      )}

      <Card className="mb-6">
        <Title>Filters</Title>
        <Grid numItems={1} numItemsSm={2} numItemsLg={4} className="gap-4 mt-4">
          <Col>
            <Text className="mb-2">Department</Text>
            <Select
              value={selectedDepartment}
              onValueChange={setSelectedDepartment}
              className="w-full"
            >
              {departments.map((dept) => (
                <SelectItem key={dept.id} value={dept.id}>
                  {dept.name}
                </SelectItem>
              ))}
            </Select>
          </Col>
          <Col>
            <Text className="mb-2">Location</Text>
            <Select
              value={selectedLocation}
              onValueChange={setSelectedLocation}
              className="w-full"
            >
              {locations.map((loc) => (
                <SelectItem key={loc.id} value={loc.id}>
                  {loc.name}
                </SelectItem>
              ))}
            </Select>
          </Col>
          <Col>
            <Text className="mb-2">Date Range</Text>
            <DateRangePicker
              className="w-full"
              value={dateRange}
              onValueChange={(value: any) => {
                if (value?.from && value?.to) {
                  setDateRange({ from: value.from, to: value.to });
                }
              }}
              enableSelect={true}
              selectPlaceholder="Select range"
              placeholder="Filter by date"
              color="rose"
            >
              {dateRanges.map((range) => (
                <DateRangePickerItem
                  key={range.name}
                  value={range.name}
                  from={range.value.from}
                  to={range.value.to}
                >
                  {range.name}
                </DateRangePickerItem>
              ))}
            </DateRangePicker>
          </Col>
          <Col>
            <Text className="mb-2">Include Inactive</Text>
            <Select
              value={showInactive ? 'yes' : 'no'}
              onValueChange={(value) => setShowInactive(value === 'yes')}
              className="w-full"
            >
              <SelectItem value="no">No</SelectItem>
              <SelectItem value="yes">Yes</SelectItem>
            </Select>
          </Col>
        </Grid>
      </Card>

      {loading ? (
        <Card className="h-96 flex items-center justify-center">
          <Text>Loading risk data...</Text>
        </Card>
      ) : riskData ? (
        <>
          <TabGroup>
            <TabList className="mb-4">
              <Tab>Overview</Tab>
              <Tab>Employee Risk Details</Tab>
              <Tab>Risk Rules</Tab>
            </TabList>
            
            <TabPanels>
              {/* Overview Panel */}
              <TabPanel>
                <Grid numItems={1} numItemsSm={2} className="gap-6 mb-6">
                  <Card>
                    <Title>Risk Distribution</Title>
                    <DonutChart
                      className="mt-6"
                      data={getRiskDistributionData()}
                      category="value"
                      index="name"
                      valueFormatter={(value) => `${value} employees`}
                      colors={['rose', 'orange', 'amber', 'emerald']}
                    />
                    <Flex justifyContent="center" className="mt-4">
                      <Text>
                        Total Employees: {riskData.summary.totalEmployees}
                      </Text>
                    </Flex>
                  </Card>
                  <Card>
                    <Title>Department Risk Overview</Title>
                    <AreaChart
                      className="h-72 mt-4"
                      data={getDepartmentRiskData()}
                      index="department"
                      categories={['Average Risk Score']}
                      colors={['rose']}
                      valueFormatter={(value) => value.toFixed(1)}
                    />
                  </Card>
                </Grid>
                
                <Card>
                  <Title>Department Summary</Title>
                  <Table className="mt-4">
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell>Department</TableHeaderCell>
                        <TableHeaderCell>Employees</TableHeaderCell>
                        <TableHeaderCell>Avg. Risk Score</TableHeaderCell>
                        <TableHeaderCell>Low Risk</TableHeaderCell>
                        <TableHeaderCell>Medium Risk</TableHeaderCell>
                        <TableHeaderCell>High Risk</TableHeaderCell>
                        <TableHeaderCell>Critical Risk</TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {riskData.summary.departmentSummaries.map((dept) => (
                        <TableRow key={dept.department}>
                          <TableCell>{dept.department}</TableCell>
                          <TableCell>{dept.employeeCount}</TableCell>
                          <TableCell>{dept.averageRiskScore.toFixed(1)}</TableCell>
                          <TableCell>{dept.riskCounts.low}</TableCell>
                          <TableCell>{dept.riskCounts.medium}</TableCell>
                          <TableCell>{dept.riskCounts.high}</TableCell>
                          <TableCell>{dept.riskCounts.critical}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </TabPanel>
              
              {/* Employee Risk Details Panel */}
              <TabPanel>
                <Card>
                  <Title>Employee Risk Assessment</Title>
                  <Table className="mt-4">
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell>Employee</TableHeaderCell>
                        <TableHeaderCell>Department</TableHeaderCell>
                        <TableHeaderCell>Role</TableHeaderCell>
                        <TableHeaderCell>Risk Level</TableHeaderCell>
                        <TableHeaderCell>Risk Score</TableHeaderCell>
                        <TableHeaderCell>Absence Count</TableHeaderCell>
                        <TableHeaderCell>Absence Days</TableHeaderCell>
                        <TableHeaderCell>Last Absence</TableHeaderCell>
                        <TableHeaderCell>Bradford Factor</TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {riskData.employees.map((employee) => (
                        <TableRow key={employee.employeeId}>
                          <TableCell>{employee.firstName} {employee.lastName}</TableCell>
                          <TableCell>{employee.department}</TableCell>
                          <TableCell>{employee.role}</TableCell>
                          <TableCell>{formatRiskLevel(employee.riskLevel)}</TableCell>
                          <TableCell>{employee.riskScore}</TableCell>
                          <TableCell>{employee.absenceCount}</TableCell>
                          <TableCell>{employee.absenceDays}</TableCell>
                          <TableCell>{employee.lastAbsenceDate || 'N/A'}</TableCell>
                          <TableCell>{employee.bradfordFactor || 'N/A'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
                
                {/* Triggers for high/critical risk employees */}
                {riskData.employees.filter(e => e.riskLevel === 'high' || e.riskLevel === 'critical').length > 0 && (
                  <Card className="mt-6">
                    <Title>High Risk Triggers</Title>
                    <Table className="mt-4">
                      <TableHead>
                        <TableRow>
                          <TableHeaderCell>Employee</TableHeaderCell>
                          <TableHeaderCell>Risk Level</TableHeaderCell>
                          <TableHeaderCell>Triggered Rules</TableHeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {riskData.employees
                          .filter(e => e.riskLevel === 'high' || e.riskLevel === 'critical')
                          .map((employee) => (
                            <TableRow key={employee.employeeId}>
                              <TableCell>{employee.firstName} {employee.lastName}</TableCell>
                              <TableCell>{formatRiskLevel(employee.riskLevel)}</TableCell>
                              <TableCell>
                                <ul className="list-disc pl-5">
                                  {employee.triggers.map((trigger, index) => (
                                    <li key={index}>
                                      {trigger.ruleName}: {trigger.count} 
                                      {trigger.ruleName.includes('Percent') ? '%' : ''} 
                                      (Threshold: {trigger.threshold}
                                      {trigger.ruleName.includes('Percent') ? '%' : ''})
                                    </li>
                                  ))}
                                </ul>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Card>
                )}
              </TabPanel>
              
              {/* Risk Rules Panel */}
              <TabPanel>
                <Card>
                  <Title>Risk Calculation Rules</Title>
                  <Text className="mt-2">
                    The following rules are used to calculate employee absenteeism risk scores:
                  </Text>
                  
                  <Table className="mt-4">
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell>Rule Name</TableHeaderCell>
                        <TableHeaderCell>Description</TableHeaderCell>
                        <TableHeaderCell>Threshold</TableHeaderCell>
                        <TableHeaderCell>Period (days)</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {riskData.summary.config.rules.map((rule) => (
                        <TableRow key={rule.id}>
                          <TableCell>{rule.name}</TableCell>
                          <TableCell>{rule.description}</TableCell>
                          <TableCell>{rule.threshold}{rule.name.includes('Pattern') ? '%' : ''}</TableCell>
                          <TableCell>{rule.period}</TableCell>
                          <TableCell>{rule.enabled ? 'Enabled' : 'Disabled'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  
                  <Title className="mt-8">Risk Level Thresholds</Title>
                  <Grid numItems={1} numItemsSm={3} className="gap-4 mt-4">
                    <Card decoration="top" decorationColor="amber">
                      <Text>Medium Risk</Text>
                      <Title className="mt-2">{riskData.summary.config.mediumThreshold}+ Rules Triggered</Title>
                    </Card>
                    <Card decoration="top" decorationColor="orange">
                      <Text>High Risk</Text>
                      <Title className="mt-2">{riskData.summary.config.highThreshold}+ Rules Triggered</Title>
                    </Card>
                    <Card decoration="top" decorationColor="rose">
                      <Text>Critical Risk</Text>
                      <Title className="mt-2">{riskData.summary.config.criticalThreshold}+ Rules Triggered</Title>
                    </Card>
                  </Grid>
                </Card>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </>
      ) : (
        <Card className="h-96 flex items-center justify-center">
          <Text>No data available. Please adjust your filters and try again.</Text>
        </Card>
      )}
    </div>
  );
};

export default AbsenteeismRiskReport; 