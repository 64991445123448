import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';
import { Fragment, forwardRef } from 'react';
import { Button } from 'src/components/catalyst/button';
import { Card } from 'src/components/catalyst/card';

export default function Notifications({ isOpen, setOpen, notifications}: any) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="relative">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 -translate-x-4"
          enterTo="opacity-100 translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-4"
        >
          <div className="fixed left-[260px] top-0 z-50 flex h-screen min-w-[400px] max-w-[450px] flex-col rounded-xl border bg-white shadow-lg">
            <div className="flex items-center justify-between px-6 py-4">
              <div className="flex flex-row items-center gap-3">
                <h2 className="font-semibold text-lg">Notifications</h2>
                <p className="rounded-lg bg-red-100 px-2 text-red-600">
                  {notifications?.length}
                </p>
              </div>
              <Button
                onClick={() => setOpen(false)}
                variant="ghost"
                color="gray"
                size="sm"
                aria-label="Close notifications"
                className="text-gray-600 hover:text-gray-900"
              >
                <XMarkIcon className="w-4" />
              </Button>
            </div>
            <div className="flex-1 space-y-6 overflow-y-auto p-4">
              {notifications?.length > 0 &&
                notifications?.map((item: any) => (
                  <Card key={item.id} className="flex flex-row gap-2 justify-between px-3 py-3 items-start">
                    <Card.Content className="flex flex-row gap-3 items-start p-0">
                      <div>
                        <UserIcon className="w-7" />
                      </div>
                      <div className="flex flex-col gap-1">
                        <p>
                          <span className="font-medium">
                            {item?.user?.full_name}
                          </span> {' '}
                          {item.message}
                        </p>
                        <p className='text-gray-400'>{item.content}</p>
                        { item.comment && 
                          (
                            <div className='px-2 py-1 rounded-lg bg-gray-100 text-sm'>
                              <p className='font-medium'>Comment</p>
                              <p>{item.comment}</p>
                            </div>
                          )
                        }
                        <p className='text-gray-400 text-sm'>{item.time}</p>
                      </div>
                    </Card.Content>
                    { !item.read && (<div className='rounded-full p-[6px] bg-red-600'/>)}
                  </Card>
                ))}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}
