import { Fragment } from 'react';
import { Card } from '../../../components/catalyst/card';
import { Badge } from '../../../components/catalyst/badge';
import { Link } from '../../../components/catalyst/link';
import { ChevronRightIcon } from '@heroicons/react/16/solid';

interface Field {
  name: string;
  value?: string | any[];
  id?: string;
  image?: string;
  mapper?: (value: any) => React.ReactNode;
}

interface DescriptionListPayload {
  title: string;
  attachments?: boolean;
  fields: Field[];
}

interface ProfileDescriptionProps {
  data: any;
}

const ProfileDescription = ({ data }: ProfileDescriptionProps) => {
  const basicInformationPayload = {
    title: 'Basic information',
    attachments: false,
    fields: [
      {
        name: 'Preferred name',
        value: data?.preferred_first_name || data?.first_name,
      },
      {
        name: 'First name',
        value: data?.first_name,
      },
      {
        name: 'Last name',
        value: data?.last_name,
      },
    ],
  };

  const contactInformationPayload = {
    title: 'Contact information',
    attachments: false,
    fields: [
      {
        name: 'Telephone',
        value: data?.work_phone_number_primary,
      },
      {
        name: 'Login email',
        value: data?.email,
      },
    ],
  };

  const employmentInformationPayload = {
    title: 'Employment',
    attachments: false,
    fields: [
      {
        name: 'Position',
        value: data?.role_name,
      },
      {
        name: 'Team',
        value: data?.team,
      },
      {
        name: 'Office',
        value: data?.office_name,
      },
      {
        name: 'Date joined',
        value: data?.date_joined,
      },
    ],
  };

  const accountInformationPayload = {
    title: 'Account',
    fields: [
      {
        name: 'Permissions',
        value: data?.is_admin === true ? 'Admin' : 'Employee',
      },
    ],
  };

  const buildUserObject = (users: any[] = []) => {
    return (
      <div className="space-y-3">
        <div className="flex flex-wrap gap-2">
          {users.slice(0, 5).map((user) => (
            <Link 
              key={user.id}
              to={`/profile/${user.id}`}
              title={user.full_name}
              className="relative group"
            >
              <img
                alt={`${user.full_name}'s profile picture`}
                src={user.safe_profile_picture_url}
                className="h-10 w-10 rounded-full object-cover ring-2 ring-white transition-all group-hover:ring-gray-200"
              />
              <span className="absolute inset-0 rounded-full bg-black/5 opacity-0 transition-opacity group-hover:opacity-100"></span>
            </Link>
          ))}
        </div>
        {users.length > 5 && (
          <div className="flex flex-wrap gap-2">
            {users.slice(5).map((user) => (
              <Link 
                key={user.id}
                to={`/profile/${user.id}`}
                title={user.full_name}
                className="relative group"
              >
                <img
                  alt={`${user.full_name}'s profile picture`}
                  src={user.safe_profile_picture_url}
                  className="h-10 w-10 rounded-full object-cover ring-2 ring-white transition-all group-hover:ring-gray-200"
                />
                <span className="absolute inset-0 rounded-full bg-black/5 opacity-0 transition-opacity group-hover:opacity-100"></span>
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  const managementInformationPayload = {
    title: 'Management',
    fields: [
      {
        name: 'Manager',
        id: data?.management?.manager?.id,
        image: data?.management?.manager?.safe_profile_picture_url,
        value: data?.management?.manager?.full_name,
      },
      {
        name: 'Direct reports',
        value: data?.management?.reports,
        mapper: buildUserObject,
      },
    ],
  };

  const renderDescriptionList = (payload: DescriptionListPayload) => (
    <Card className="mt-6 overflow-hidden">
      <Card.Header className="px-6 py-1">
        <Card.Title className="text-base font-semibold text-gray-900">{payload.title}</Card.Title>
      </Card.Header>
      <Card.Content className="px-0 py-0">
        <dl className="divide-y divide-gray-100">
          {payload.fields.map((field, index) => (
            <div key={index} className="px-1 py-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">{field.name}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {field.mapper ? (
                  field.mapper(field.value)
                ) : field.image ? (
                  <Link to={`/profile/${field.id}`} className="group flex items-center gap-3 hover:no-underline">
                    <img
                      alt={`${field.value}'s profile picture`}
                      src={field.image}
                      className="h-10 w-10 rounded-full object-cover ring-2 ring-white transition-all group-hover:ring-gray-200"
                    />
                    <div className="flex items-center gap-1 text-sm font-medium text-gray-900 group-hover:text-blue-600">
                      {field.value}
                      <ChevronRightIcon className="h-4 w-4 opacity-0 transition group-hover:opacity-100" />
                    </div>
                  </Link>
                ) : field.name === 'Permissions' ? (
                  <Badge 
                    variant={field.value === 'Admin' ? 'solid' : 'outline'} 
                    color={field.value === 'Admin' ? 'blue' : 'gray'}
                  >
                    {field.value}
                  </Badge>
                ) : (
                  field.value || <span className="text-gray-400">Not provided</span>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </Card.Content>
    </Card>
  );

  return (
    <div className="space-y-6">
      {renderDescriptionList(basicInformationPayload)}
      {renderDescriptionList(contactInformationPayload)}
      {renderDescriptionList(employmentInformationPayload)}
      {renderDescriptionList(accountInformationPayload)}
      {renderDescriptionList(managementInformationPayload)}
    </div>
  );
};

export default ProfileDescription;
