import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Input } from '../../../../ui/form/Input';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ChevronUpDownIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { Select } from '../../../../ui/form/Select';
import { OnboardingStep } from '../../../../ui/layouts/OnboardingLayout';
import onboardingProcess from '../../../../network/onboardingProcess';
import {
  useGetDepartments,
  useGetOffices,
} from '../../../../routes/Organization/hooks';
import Notification from '../../../../ui/component/notification';
import { Spinner } from '../../../../ui/Spinner';

interface Employee {
  id: string;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  department: string;
  role: string;
  office: string;
  job_title?: string;
  system?: {
    id: string;
  };
}

export function ManualSetUp() {
  const [employees, setEmployees] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState<Employee | null>(null);
  const [formValues, setFormValues] = useState<Employee>({
    id: '',
    title: '',
    first_name: '',
    last_name: '',
    email: '',
    department: '',
    role: '',
    office: '',
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({ title: '', message: '', isError: false });

  const { data: departments = [] } = useGetDepartments();
  const { data: offices = [] } = useGetOffices();

  const { data: userData, isLoading: isLoadingUsers } = useQuery(
    ['onboarding-users-manual'],
    async () => {
      const { data } = await onboardingProcess.getOnboardingData(
        OnboardingStep.ImportUsers,
      );
      return data?.data?.users || [];
    }
  );

  const { data: roles, isLoading: isLoadingRoles } = useQuery(
    ['onboarding-roles'],
    async () => {
      const { data } = await onboardingProcess.getOnboardingRoles();
      return data;
    }
  );

  useEffect(() => {
    if (userData) {
      setEmployees(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (roles) {
      setRoleOptions(roles);
    }
  }, [roles]);

  const isLoading = isLoadingUsers || isLoadingRoles;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ): void => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreate = async () => {
    try {
      if (isEditing && currentEmployee) {
        // Handle update
        const { data } = await onboardingProcess.onboardingProcess(
          OnboardingStep.ImportUsers,
          { 
            ...formValues, 
            import_method: 'manual',
            id: currentEmployee.system?.id || currentEmployee.id, // Use system.id if available
            job_title: formValues.title, // Map title to job_title
          },
        );
        
        if (data?.status === 'HANDLE_ONBOARDING_STEP_SUCCESS') {
          const { data: userData } = await onboardingProcess.getOnboardingData(
            OnboardingStep.ImportUsers,
          );
          setEmployees(userData?.data.users);
          setNotificationMessage({
            title: 'Success',
            message: `Successfully updated ${formValues.first_name} ${formValues.last_name}`,
            isError: false
          });
          setShowNotification(true);
          // Reset edit mode
          setIsEditing(false);
          setCurrentEmployee(null);
        }
      } else {
        // Handle create
        const { data } = await onboardingProcess.onboardingProcess(
          OnboardingStep.ImportUsers,
          { 
            ...formValues, 
            import_method: 'manual',
            job_title: formValues.title, // Map title to job_title for create as well
          },
        );
        if (data?.status === 'HANDLE_ONBOARDING_STEP_SUCCESS') {
          const { data: userData } = await onboardingProcess.getOnboardingData(
            OnboardingStep.ImportUsers,
          );
          setEmployees(userData?.data.users);
          setNotificationMessage({
            title: 'Success',
            message: `Successfully created ${formValues.first_name} ${formValues.last_name}`,
            isError: false
          });
          setShowNotification(true);
        }
      }

      // Reset form in both cases
      setFormValues({
        id: '',
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        department: '',
        role: '',
        office: '',
      });
    } catch (error) {
      console.log(error);
      setNotificationMessage({
        title: 'Error',
        message: isEditing ? 'Failed to update employee' : 'Failed to create employee',
        isError: true
      });
      setShowNotification(true);
    }
  };

  const handleEdit = (employee: Employee) => {
    setFormValues({
      ...employee,
      title: employee.job_title || employee.title || '', // Handle both fields with fallback
      id: employee.id,
    });
    setCurrentEmployee(employee);
    setIsEditing(true);
    setNotificationMessage({
      title: 'Edit Mode',
      message: `Selected ${employee.first_name} ${employee.last_name} for editing`,
      isError: false
    });
    setShowNotification(true);
  };

  const handleDelete = async (id: string | undefined) => {
    if (!id) {
      console.error('Cannot delete user: ID is undefined');
      setNotificationMessage({
        title: 'Error',
        message: 'Cannot delete user: ID is undefined',
        isError: true
      });
      setShowNotification(true);
      return;
    }

    try {
      await onboardingProcess.deleteUser(id);
      setEmployees((prev) => prev.filter((emp) => emp.id !== id));
      setNotificationMessage({
        title: 'Success',
        message: 'User deleted successfully',
        isError: false
      });
      setShowNotification(true);
    } catch (error) {
      console.error('Failed to delete user:', error);
      setNotificationMessage({
        title: 'Error',
        message: 'Failed to delete user',
        isError: true
      });
      setShowNotification(true);
    }
  };

  const handleCancelEdit = () => {
    setFormValues({
      id: '',
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      department: '',
      role: '',
      office: '',
    });
    setIsEditing(false);
    setCurrentEmployee(null);
  };

  if (isLoading) {
    return <Spinner className="my-8" />;
  }

  return (
    <div className="max-h-[63vh] overflow-y-auto">
      {employees.length > 0 ? (
        <div className="mr-2 pb-2 ">
          <h6 className="flex justify-between rounded-lg bg-[#FAFAFA] px-2 py-1">
            Added employees
            <ChevronUpDownIcon className="w-5" />
          </h6>
          <div className="divide-y">
            {employees?.map((user) => (
              <div key={user?.id} className="flex justify-between py-2 ">
                <div className="flex flex-col">
                  <div className="flex items-center gap-1">
                    <p className="rounded-lg bg-[#F4F4F5] px-2 py-1">
                      # {user?.id}
                    </p>
                    <h6>
                      {user?.first_name} {user?.last_name}
                    </h6>
                  </div>
                  <p>Job Title: {user?.job_title}</p>
                  <p>Email: {user?.email}</p>
                  <p>Role: {user?.role}</p>
                  <p>Team: {user?.team}</p>
                  <p>Department: {user?.department}</p>
                  <p>Office: {user?.office}</p>
                </div>
                <div className="mr-4 flex flex-row items-start gap-2">
                  <button
                    onClick={() => handleDelete(user?.system?.id || user?.id)}
                    className="rounded-lg border-[1px] border-gray-200 p-1 shadow"
                    aria-label={`Delete ${user.first_name} ${user.last_name}`}
                  >
                    <MinusCircleIcon className="w-5" color="red" />
                  </button>
                  <button
                    onClick={() => handleEdit(user)}
                    className="rounded-lg border-[1px] border-gray-200 p-1 shadow"
                    aria-label={`Edit ${user.first_name} ${user.last_name}`}
                  >
                    <PencilSquareIcon className="w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="mr-2">
        <div className="flex flex-row gap-4">
          <div className="flex w-[50%] flex-col">
            <label htmlFor="title" className="mb-1 block text-base font-medium">
              Job Title
            </label>
            <Input
              type="text"
              placeholder="Job title"
              id="title"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex w-[50%] flex-col">
            <label htmlFor="email" className="mb-1 block text-base font-medium">
              Email
            </label>
            <Input
              type="email"
              placeholder="example@example.com"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="mt-3 flex flex-row gap-4">
          <div className="flex w-[50%] flex-col">
            <label
              htmlFor="first_name"
              className="mb-1 block text-base font-medium"
            >
              First Name
            </label>
            <Input
              type="text"
              placeholder="Enter employee first name"
              id="first_name"
              name="first_name"
              value={formValues.first_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex w-[50%] flex-col">
            <label
              htmlFor="last_name"
              className="mb-1 block text-base font-medium"
            >
              Last Name
            </label>
            <Input
              type="text"
              placeholder="Enter employee last name"
              id="last_name"
              name="last_name"
              value={formValues.last_name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="mt-3 flex flex-row gap-4">
          <div className="flex w-[50%] flex-col">
            <label
              htmlFor="department"
              className="mb-1 block text-base font-medium"
            >
              Department
            </label>
            <Select
              id="department"
              name="department"
              value={formValues.department}
              onChange={handleInputChange}
            >
              <option>Select</option>
              {departments?.map((item: { id: string; name: string }) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex w-[50%] flex-col">
            <label htmlFor="role" className="mb-1 block text-base font-medium">
              Role
            </label>
            <Select
              id="role"
              name="role"
              value={formValues.role}
              onChange={handleInputChange}
            >
              <option>Select</option>
              {roleOptions.map((item: { id: string; name: string }) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className="mt-3 flex flex-row justify-end gap-2">
          {isEditing && (
            <button
              onClick={handleCancelEdit}
              className="rounded-lg border px-3 py-1 font-normal shadow"
            >
              Cancel
            </button>
          )}
          <button
            onClick={handleCreate}
            className="rounded-lg bg-[#DC2626] px-4 py-2 text-white"
          >
            {isEditing ? 'Update' : 'Create'}
          </button>
        </div>
      </div>
      <Notification 
        title={notificationMessage.title}
        message={notificationMessage.message}
        isError={notificationMessage.isError}
        open={showNotification}
        close={() => setShowNotification(false)}
      />
    </div>
  );
}
