import * as React from 'react'
import { clsx } from 'clsx'

/* Card Component */
interface CardProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode
}

function Card({ className, children, ...props }: CardProps) {
  return (
    <div
      className={clsx(
        'overflow-hidden rounded-xl border border-gray-200 bg-white shadow-sm',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

/* Card.Header Component */
interface CardHeaderProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode
}

function CardHeader({ className, children, ...props }: CardHeaderProps) {
  return (
    <div
      className={clsx('border-b border-gray-200 px-6 py-4', className)}
      {...props}
    >
      {children}
    </div>
  )
}

/* Card.Title Component */
interface CardTitleProps extends React.ComponentPropsWithoutRef<'h3'> {
  children: React.ReactNode
}

function CardTitle({ className, children, ...props }: CardTitleProps) {
  return (
    <h3
      className={clsx('text-base font-semibold leading-6 text-gray-900', className)}
      {...props}
    >
      {children}
    </h3>
  )
}

/* Card.Content Component */
interface CardContentProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode
}

function CardContent({ className, children, ...props }: CardContentProps) {
  return (
    <div className={clsx('px-6 py-4', className)} {...props}>
      {children}
    </div>
  )
}

/* Card.Footer Component */
interface CardFooterProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode
}

function CardFooter({ className, children, ...props }: CardFooterProps) {
  return (
    <div
      className={clsx('border-t border-gray-200 px-6 py-4', className)}
      {...props}
    >
      {children}
    </div>
  )
}

/* Compose Card components */
Card.Header = CardHeader
Card.Title = CardTitle
Card.Content = CardContent
Card.Footer = CardFooter

export { Card } 