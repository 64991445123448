import { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { debounce } from 'lodash';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import officesManagement from '../../../network/officesManagement';
import { useToast } from '../../../utils/useToast';

// Import Catalyst components
import { Button } from '../../../components/Button';
import { Dialog, DialogPanel, DialogTitle } from '../../../components/Dialog';
import { Input } from '../../../components/Input';
import { Select } from '../../../components/Select';
import { Label } from '../../../components/Label';

// Phone number validation regex - supports international formats
const PHONE_REGEXP = /^(\+|00)[1-9][0-9 \-().]{7,32}$/;

// Country code mapping for Postcoder (used by the backend)
const countryCodeMapping: { [key: string]: string } = {
  GB: 'uk',
  DE: 'de',
  NL: 'nl',
  PL: 'pl',
  FR: 'fr',
  IE: 'ie',
  US: 'us',
  CA: 'ca',
  AU: 'au',
  NZ: 'nz',
  // Add more mappings as needed
};

export default function HandleNewLocation({
  open,
  setOpen,
  setCurrentOffice,
  mode = 'create',
  currentOffice,
  refetchOffices,
}: any) {
  const { mutateAsync: createMutateAsync } = useMutation(
    officesManagement.createOffice,
  );
  const { mutateAsync: updateMutateAsync } = useMutation(
    officesManagement.updateOffice,
  );

  const { toast } = useToast();
  const isEdit = mode === 'edit';

  // States for filters and searches
  const [countrySearch, setCountrySearch] = useState('');
  const [filteredCountries, setFilteredCountries] = useState<Array<any>>([]);
  const [addressSearch, setAddressSearch] = useState('');
  const [addressResults, setAddressResults] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [selectedAddressDetails, setSelectedAddressDetails] = useState<{
    addressline1?: string;
    addressline2?: string;
    posttown?: string;
    county?: string;
    postcode?: string;
  } | null>(null);

  // Define formik first, before it's used in queries
  const formik = useFormik({
    initialValues: {
      name: currentOffice?.name || '',
      address: currentOffice?.address?.line1 || '',
      country: currentOffice?.address?.country || '',
      city: currentOffice?.address?.town || '',
      phone_number: currentOffice?.phone_number || '',
      countryCode: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string(),
      address: yup.string().required('Street address is required'),
      country: yup.string().required('Country is required'),
      city: yup.string().required('City is required'),
      phone_number: yup
        .string()
        .required('Phone number is required')
        .matches(
          PHONE_REGEXP,
          'Please enter a valid phone number (e.g., +44 123 456 7890)'
        ),
    }),
    onSubmit: async (data) => {
      try {
        // Remove countryCode as it's only used internally
        const { countryCode, ...submissionData } = data;
        
        // Format address if we have selected address details
        const formattedData = {
          ...submissionData,
          address: selectedAddressDetails ? selectedAddressDetails.addressline1 : submissionData.address,
          city: selectedAddressDetails ? selectedAddressDetails.posttown : submissionData.city,
        };
        
        if (!isEdit) {
          await createMutateAsync(formattedData);
          toast({
            variant: 'success',
            title: 'Office successfully created.',
          });
        } else {
          await updateMutateAsync({ id: currentOffice.id, body: formattedData });
          toast({
            variant: 'success',
            title: 'Office successfully updated.',
          });
        }
        refetchOffices();
        setOpen(false);
        formik.resetForm();
        setSelectedAddressDetails(null);
        setCurrentOffice({
          id: '',
          office_name: '',
          address: '',
          country: '',
          city: '',
          phone: '',
        });
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.',
        });
      }
    },
  });

  // React Query hooks for fetching data
  const {
    data: countries = [],
    isLoading: loadingCountries,
  } = useQuery('countries', () => officesManagement.getCountries(), {
    staleTime: 1000 * 60 * 60, // Cache for 1 hour
    onError: (error) => {
      toast({
        variant: 'error',
        title: 'Failed to load countries',
        description: 'Please try again later',
      });
    }
  });


  // Filter countries based on search input
  useEffect(() => {
    if (!countrySearch.trim()) {
      setFilteredCountries(countries);
      return;
    }
    
    const searchTerm = countrySearch.toLowerCase();
    const filtered = countries.filter((country: any) => 
      country.name.common.toLowerCase().includes(searchTerm)
    );
    setFilteredCountries(filtered);
  }, [countrySearch, countries]);

  // Update Postcoder country code when country selection changes
  useEffect(() => {
    if (formik.values.countryCode) {
      const postcoderCode = countryCodeMapping[formik.values.countryCode];
      if (postcoderCode) {
        setSelectedCountryCode(postcoderCode);
      }
    }
  }, [formik.values.countryCode]);

  // Debounced function to search addresses
  const searchAddresses = useCallback(
    debounce(async (search: string) => {
      if (!search || search.length < 3 || !selectedCountryCode) {
        setAddressResults([]);
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      try {
        const addresses = await officesManagement.searchAddresses(selectedCountryCode, search);
        setAddressResults(addresses);
      } catch (error) {
        console.error('Error searching addresses:', error);
        toast({
          variant: 'error',
          title: 'Failed to search addresses',
          description: 'Please try again or enter the address manually',
        });
      } finally {
        setIsSearching(false);
      }
    }, 500),
    [selectedCountryCode, toast]
  );

  // Trigger address search when input changes
  useEffect(() => {
    searchAddresses(addressSearch);
    
    // Cleanup function
    return () => {
      searchAddresses.cancel();
    };
  }, [addressSearch, searchAddresses]);

  // Handle selecting an address from results
  const handleSelectAddress = (address: any) => {
    formik.setValues({
      ...formik.values,
      address: address.addressline1 || '',
      city: address.posttown || '',
    });
    // Store the full address details
    setSelectedAddressDetails({
      addressline1: address.addressline1 || '',
      addressline2: address.addressline2 || '',
      posttown: address.posttown || '',
      county: address.county || '',
      postcode: address.postcode || '',
    });
    setAddressSearch('');
    setAddressResults([]);
  };

  // Handle country selection
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryCode = e.target.value;
    const selectedCountry = countries.find((c: any) => c.cca2 === selectedCountryCode);
    
    if (selectedCountry) {
      formik.setValues({
        ...formik.values,
        country: selectedCountry.name.common,
        countryCode: selectedCountry.cca2,
        city: '', // Reset city when country changes
        address: '', // Reset address when country changes
      });
      
      // Clear address search results and selected address details
      setAddressSearch('');
      setAddressResults([]);
      setSelectedAddressDetails(null);
    }
  };

  function findError(
    fieldName: keyof typeof formik.initialValues,
  ): string | undefined {
    const error =
      formik.touched[fieldName] && formik.errors[fieldName]
        ? formik.errors[fieldName]
        : undefined;

    if (Array.isArray(error)) {
      return error.join(', ');
    }

    return error as string | undefined;
  }

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogPanel className="w-full max-w-lg">
        <div className="px-6 pt-6">
          <DialogTitle className="text-lg font-semibold text-gray-900">
            {isEdit ? 'Edit location' : 'Add new location'}
          </DialogTitle>
          <p className="mt-1 text-sm text-gray-500">
            Determine the location of the new office
          </p>
        </div>
        
        <form onSubmit={formik.handleSubmit} className="mt-6 px-6">
          <div className="space-y-4">
            {/* Office name field */}
            <div>
              <Label htmlFor="name">Office name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Enter office name (optional)"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={findError('name')}
                className="mt-1 w-full"
              />
            </div>

            {/* Country Selection with Search */}
            <div>
              <Label htmlFor="country">Country</Label>
              <div className="mt-1 mb-2">
                <Input
                  placeholder="Search for a country"
                  value={countrySearch}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCountrySearch(e.target.value)}
                  className="w-full"
                />
              </div>
              <Select
                id="countryCode"
                name="countryCode"
                value={formik.values.countryCode}
                onChange={handleCountryChange}
                error={findError('country')}
                disabled={loadingCountries}
                className="w-full"
              >
                <option value="">Select country</option>
                {filteredCountries.map((country: any) => (
                  <option key={country.cca2} value={country.cca2}>
                    {country.name.common}
                  </option>
                ))}
              </Select>
              {loadingCountries && (
                <p className="mt-1 text-sm text-gray-500">Loading countries...</p>
              )}
            </div>

            {/* Address lookup with dropdown */}
            <div className="relative">
              <Label htmlFor="addressLookup">Address Lookup</Label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                </div>
                <Input
                  id="addressLookup"
                  placeholder="Search by postal code or street name"
                  value={addressSearch}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddressSearch(e.target.value)}
                  disabled={!selectedCountryCode}
                  className="pl-10 w-full"
                />
              </div>
              
              {/* Address results dropdown */}
              {addressResults.length > 0 && (
                <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg border border-gray-200">
                  <div className="py-1 text-sm text-gray-500 px-4 bg-gray-50 border-b border-gray-200">
                    {addressResults.length} addresses found
                  </div>
                  <ul className="max-h-60 overflow-auto rounded-md py-1 text-base leading-6">
                    {addressResults.map((address, index) => (
                      <li
                        key={index}
                        className="cursor-pointer select-none px-4 py-2 hover:bg-indigo-50"
                        onClick={() => handleSelectAddress(address)}
                      >
                        <div className="flex flex-col">
                          <span className="font-medium text-gray-900">
                            {address.addressline1}
                          </span>
                          <span className="text-sm text-gray-500">
                            {address.addressline2}{address.addressline3 ? `, ${address.addressline3}` : ''}
                          </span>
                          {address.county && 
                            <span className="text-xs text-gray-400">{address.county}</span>
                          }
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              
              {isSearching && (
                <div className="mt-2 text-sm text-gray-500 flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Searching addresses...
                </div>
              )}
              
              {!selectedCountryCode && (
                <p className="mt-1 text-sm text-gray-500">Select a country first to search for addresses</p>
              )}
              
              {selectedCountryCode && !isSearching && addressSearch.length > 0 && addressSearch.length < 3 && (
                <p className="mt-1 text-sm text-gray-500">Enter at least 3 characters to search</p>
              )}
            </div>

            {/* Street Address Display */}
            <div>
              <Label htmlFor="address">Street Address</Label>
              {selectedAddressDetails ? (
                <div className="mt-1 p-3 border border-gray-300 rounded-md bg-gray-50">
                  <div className="flex justify-between items-start">
                    <div className="space-y-1">
                      {selectedAddressDetails.addressline1 && (
                        <p className="text-sm font-medium text-gray-900">{selectedAddressDetails.addressline1}</p>
                      )}
                      {selectedAddressDetails.addressline2 && (
                        <p className="text-sm text-gray-700">{selectedAddressDetails.addressline2}</p>
                      )}
                      <div className="flex flex-wrap gap-x-1 text-sm text-gray-500">
                        {selectedAddressDetails.posttown && (
                          <span>{selectedAddressDetails.posttown}</span>
                        )}
                        {selectedAddressDetails.county && selectedAddressDetails.posttown && (
                          <span>•</span>
                        )}
                        {selectedAddressDetails.county && (
                          <span>{selectedAddressDetails.county}</span>
                        )}
                        {selectedAddressDetails.postcode && (
                          <span className="font-medium ml-1">{selectedAddressDetails.postcode}</span>
                        )}
                      </div>
                    </div>
                    <Button 
                      variant="secondary" 
                      size="sm" 
                      onClick={() => setSelectedAddressDetails(null)}
                      className="text-xs"
                    >
                      Change
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="relative mt-1">
                    <Input
                      id="address"
                      name="address"
                      placeholder="Enter street address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      error={findError('address')}
                      className="w-full"
                    />
                  </div>
                  <p className="mt-1 text-xs text-gray-500">
                    Search for an address above or enter manually
                  </p>
                </>
              )}
            </div>

            {/* City field - only show if address is manually entered */}
            {!selectedAddressDetails && (
              <div>
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  name="city"
                  placeholder="Enter city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={findError('city')}
                  className="mt-1 w-full"
                />
              </div>
            )}

            {/* Phone number */}
            <div>
              <Label htmlFor="phone_number">Phone number</Label>
              <Input
                id="phone_number"
                name="phone_number"
                placeholder="+44 123 456 7890"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                error={findError('phone_number')}
                className="mt-1 w-full"
              />
              <p className="mt-1 text-xs text-gray-500">
                Enter phone number in international format (e.g., +44 123 456 7890)
              </p>
            </div>
          </div>
        </form>
        
        <div className="mt-8 flex justify-end gap-3 px-6 pb-6">
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
              formik.resetForm();
              setAddressSearch('');
              setAddressResults([]);
              setCountrySearch('');
              setSelectedAddressDetails(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? 'Save' : 'Add office location'}
          </Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
}
