import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

/**
 * Export data to Excel file
 * @param data Array of objects to export
 * @param filename Name of the file without extension
 */
export const exportToExcel = (data: any[], filename: string): void => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

/**
 * Export data to PDF file
 * @param title Title of the document
 * @param headers Table headers
 * @param data Table data as array of arrays
 * @param filename Name of the file without extension
 */
export const exportToPdf = (
  title: string,
  headers: string[],
  data: string[][],
  filename: string
): void => {
  const doc = new jsPDF('landscape');
  const pageWidth = doc.internal.pageSize.width;
  
  // Add title
  doc.setFontSize(18);
  doc.text(title, pageWidth / 2, 15, { align: 'center' });
  
  // Add date
  doc.setFontSize(11);
  doc.text(`Generated on: ${new Date().toLocaleString()}`, pageWidth / 2, 22, { align: 'center' });
  
  // Add table
  (doc as any).autoTable({
    head: [headers],
    body: data,
    startY: 30,
    styles: { fontSize: 10, cellPadding: 2 },
    headStyles: { fillColor: [220, 53, 69], textColor: 255 },
    alternateRowStyles: { fillColor: [245, 245, 245] },
    margin: { top: 30 },
  });
  
  // Save PDF
  doc.save(`${filename}.pdf`);
}; 