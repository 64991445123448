import React, { useState, useMemo } from 'react';
import { Card } from '../../../ui/Card';
import { Container } from '../../../ui/Container';
import { Button } from '../../../ui/Button';
import { Header, HeaderTitle, HeaderAction } from '../../../ui/Header';
import { 
  MagnifyingGlassIcon, 
  FunnelIcon, 
  CalendarDaysIcon,
  UserIcon,
  ClockIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  CheckIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/20/solid';
import { Tab } from '@headlessui/react';
import { clsx } from 'clsx';
import moment from 'moment';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface EmployeeScreenProps {
  employees: any[];
  rotas: any[];
  onViewEmployeeDetail?: (employeeId: string) => void;
  onBack: () => void;
}

export function EmployeeScreen({ 
  employees = [],
  rotas = [],
  onViewEmployeeDetail,
  onBack
}: EmployeeScreenProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedRole, setSelectedRole] = useState('All');
  const [timeRange, setTimeRange] = useState('This Week');

  // Filter employees based on search and filters
  const filteredEmployees = useMemo(() => {
    return employees.filter(employee => {
      // Search filter
      const matchesSearch = !searchTerm || 
        employee.name?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        employee.email?.toLowerCase().includes(searchTerm.toLowerCase());
      
      // Department filter
      const matchesDepartment = selectedDepartment === 'All' || 
        employee.department === selectedDepartment;
      
      // Role filter
      const matchesRole = selectedRole === 'All' || 
        employee.role === selectedRole;
      
      return matchesSearch && matchesDepartment && matchesRole;
    });
  }, [employees, searchTerm, selectedDepartment, selectedRole]);

  // Generate a list of unique departments
  const departments = useMemo(() => {
    const depts = ['All', ...Array.from(new Set(employees.map(e => e.department).filter(Boolean)))];
    return depts;
  }, [employees]);

  // Generate a list of unique roles
  const roles = useMemo(() => {
    const r = ['All', ...Array.from(new Set(employees.map(e => e.role).filter(Boolean)))];
    return r;
  }, [employees]);

  // Get assigned shifts for an employee in the current time range
  const getEmployeeShifts = (employeeId: string) => {
    let startDate: any;
    let endDate: any;
    
    switch (timeRange) {
      case 'Today':
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
        break;
      case 'This Week':
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
        break;
      case 'This Month':
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
        break;
      default:
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
    }
    
    return rotas.filter(rota => {
      const rotaStart = moment(rota.start_date);
      const rotaEnd = rota.end_date ? moment(rota.end_date) : rotaStart;
      
      // Check if this rota is within the time range
      const isInTimeRange = (
        (rotaStart.isSameOrAfter(startDate) && rotaStart.isSameOrBefore(endDate)) ||
        (rotaEnd.isSameOrAfter(startDate) && rotaEnd.isSameOrBefore(endDate))
      );
      
      // Check if this employee is assigned to this rota
      const isEmployeeAssigned = rota.employees?.some((emp: any) => emp.id === employeeId);
      
      return isInTimeRange && isEmployeeAssigned;
    });
  };

  return (
    <Container>
      <Header className="mb-6">
        <div className="flex items-center gap-3">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={onBack}
            className="flex items-center gap-1.5"
          >
            <ChevronRightIcon className="h-5 w-5 rotate-180" />
            <span>Back to Calendar</span>
          </Button>
          <HeaderTitle>Employee Management</HeaderTitle>
        </div>
        <HeaderAction>
          <Button color="red">
            Add Employee
          </Button>
        </HeaderAction>
      </Header>
      
      <div className="space-y-6">
        {/* Filters and search section */}
        <Card>
          <Card.Header className="bg-white py-4 border-b border-slate-200">
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
              <div className="relative flex-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5 text-slate-400" />
                </div>
                <input
                  type="text"
                  className="block w-full rounded-md border border-slate-300 py-2 pl-10 pr-3 text-sm placeholder:text-slate-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  placeholder="Search by name or email..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              <div className="flex flex-wrap gap-2">
                {/* Department filter */}
                <Menu as="div" className="relative">
                  <Menu.Button as={Button} variant="outline" size="sm" className="gap-1">
                    <FunnelIcon className="h-4 w-4 text-slate-500" />
                    Department: {selectedDepartment}
                    <ChevronDownIcon className="h-4 w-4 text-slate-500" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {departments.map((dept) => (
                          <Menu.Item key={dept}>
                            {({ active }) => (
                              <button
                                className={clsx(
                                  'flex w-full items-center px-4 py-2 text-left text-sm',
                                  active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                                )}
                                onClick={() => setSelectedDepartment(dept)}
                              >
                                {selectedDepartment === dept && (
                                  <CheckIcon className="mr-2 h-4 w-4 text-blue-500" />
                                )}
                                <span className={selectedDepartment === dept ? 'font-medium' : ''}>
                                  {dept}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                
                {/* Role filter */}
                <Menu as="div" className="relative">
                  <Menu.Button as={Button} variant="outline" size="sm" className="gap-1">
                    <AdjustmentsHorizontalIcon className="h-4 w-4 text-slate-500" />
                    Role: {selectedRole}
                    <ChevronDownIcon className="h-4 w-4 text-slate-500" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {roles.map((role) => (
                          <Menu.Item key={role}>
                            {({ active }) => (
                              <button
                                className={clsx(
                                  'flex w-full items-center px-4 py-2 text-left text-sm',
                                  active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                                )}
                                onClick={() => setSelectedRole(role)}
                              >
                                {selectedRole === role && (
                                  <CheckIcon className="mr-2 h-4 w-4 text-blue-500" />
                                )}
                                <span className={selectedRole === role ? 'font-medium' : ''}>
                                  {role}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                
                {/* Time range filter */}
                <Menu as="div" className="relative">
                  <Menu.Button as={Button} variant="outline" size="sm" className="gap-1">
                    <CalendarDaysIcon className="h-4 w-4 text-slate-500" />
                    {timeRange}
                    <ChevronDownIcon className="h-4 w-4 text-slate-500" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {['Today', 'This Week', 'This Month'].map((range) => (
                          <Menu.Item key={range}>
                            {({ active }) => (
                              <button
                                className={clsx(
                                  'flex w-full items-center px-4 py-2 text-left text-sm',
                                  active ? 'bg-slate-100 text-slate-900' : 'text-slate-700'
                                )}
                                onClick={() => setTimeRange(range)}
                              >
                                {timeRange === range && (
                                  <CheckIcon className="mr-2 h-4 w-4 text-blue-500" />
                                )}
                                <span className={timeRange === range ? 'font-medium' : ''}>
                                  {range}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </Card.Header>
        </Card>
        
        {/* Employee List */}
        <Tab.Group>
          <div className="flex flex-col">
            <Tab.List className="mb-4 flex space-x-1 rounded-lg bg-slate-100 p-1">
              <Tab
                className={({ selected }) =>
                  clsx(
                    'w-full rounded-md py-2.5 text-sm font-medium',
                    'ring-white ring-opacity-60 ring-offset-2 focus:outline-none',
                    selected
                      ? 'bg-white shadow text-slate-900'
                      : 'text-slate-700 hover:bg-white/[0.12] hover:text-slate-900'
                  )
                }
              >
                All Employees ({filteredEmployees.length})
              </Tab>
              <Tab
                className={({ selected }) =>
                  clsx(
                    'w-full rounded-md py-2.5 text-sm font-medium',
                    'ring-white ring-opacity-60 ring-offset-2 focus:outline-none',
                    selected
                      ? 'bg-white shadow text-slate-900'
                      : 'text-slate-700 hover:bg-white/[0.12] hover:text-slate-900'
                  )
                }
              >
                Scheduled ({filteredEmployees.filter(e => getEmployeeShifts(e.id).length > 0).length})
              </Tab>
            </Tab.List>
            
            <Tab.Panels>
              <Tab.Panel>
                <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {filteredEmployees.length > 0 ? (
                    filteredEmployees.map((employee) => (
                      <EmployeeCard 
                        key={employee.id} 
                        employee={employee} 
                        shifts={getEmployeeShifts(employee.id)}
                        onClick={() => onViewEmployeeDetail?.(employee.id)}
                      />
                    ))
                  ) : (
                    <div className="col-span-full flex flex-col items-center justify-center py-12">
                      <UserIcon className="h-12 w-12 text-slate-300" />
                      <p className="mt-4 text-lg font-medium text-slate-900">No employees found</p>
                      <p className="mt-1 text-sm text-slate-500">
                        Try adjusting your search or filters to find what you're looking for.
                      </p>
                    </div>
                  )}
                </div>
              </Tab.Panel>
              
              <Tab.Panel>
                <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {filteredEmployees.filter(e => getEmployeeShifts(e.id).length > 0).length > 0 ? (
                    filteredEmployees
                      .filter(e => getEmployeeShifts(e.id).length > 0)
                      .map((employee) => (
                        <EmployeeCard 
                          key={employee.id} 
                          employee={employee} 
                          shifts={getEmployeeShifts(employee.id)}
                          onClick={() => onViewEmployeeDetail?.(employee.id)}
                        />
                      ))
                  ) : (
                    <div className="col-span-full flex flex-col items-center justify-center py-12">
                      <CalendarDaysIcon className="h-12 w-12 text-slate-300" />
                      <p className="mt-4 text-lg font-medium text-slate-900">No scheduled employees</p>
                      <p className="mt-1 text-sm text-slate-500">
                        No employees are scheduled for {timeRange.toLowerCase()}.
                      </p>
                    </div>
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </Container>
  );
}

// Employee Card Component
function EmployeeCard({ 
  employee, 
  shifts, 
  onClick 
}: { 
  employee: any; 
  shifts: any[];
  onClick?: () => void;
}) {
  return (
    <Card className="overflow-hidden hover:shadow-md transition-shadow">
      <button 
        className="w-full text-left focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded-lg"
        onClick={onClick}
      >
        <Card.Header className="px-4 py-3 flex items-center gap-3 bg-white border-b border-slate-200">
          <div className="h-10 w-10 rounded-full bg-slate-200 flex items-center justify-center flex-shrink-0">
            {employee.avatar_url ? (
              <img 
                src={employee.avatar_url} 
                alt={employee.name} 
                className="h-10 w-10 rounded-full"
              />
            ) : (
              <span className="text-sm font-medium text-slate-600">
                {employee.name?.charAt(0) || '?'}
              </span>
            )}
          </div>
          <div className="overflow-hidden">
            <h3 className="font-medium text-slate-900 truncate">{employee.name}</h3>
            <p className="text-xs text-slate-500 truncate">{employee.email}</p>
          </div>
        </Card.Header>
        
        <Card.Content className="px-4 py-3">
          <div className="flex flex-col gap-2">
            {employee.role && (
              <div className="flex items-center justify-between">
                <span className="text-xs text-slate-500">Role</span>
                <span className="text-sm font-medium text-slate-700">{employee.role}</span>
              </div>
            )}
            
            {employee.department && (
              <div className="flex items-center justify-between">
                <span className="text-xs text-slate-500">Department</span>
                <span className="text-sm font-medium text-slate-700">{employee.department}</span>
              </div>
            )}
            
            <div className="flex items-center justify-between">
              <span className="text-xs text-slate-500">Scheduled Shifts</span>
              <span className="inline-flex items-center rounded-full bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800">
                {shifts.length}
              </span>
            </div>
          </div>
        </Card.Content>
        
        <div className="bg-slate-50 px-4 py-3 border-t border-slate-200">
          {shifts.length > 0 ? (
            <div className="space-y-2">
              <h4 className="text-xs font-medium text-slate-500 uppercase">Upcoming Shifts</h4>
              <div className="space-y-1.5">
                {shifts.slice(0, 2).map((shift) => (
                  <div 
                    key={shift.id} 
                    className="flex items-center gap-2 text-xs"
                  >
                    <div 
                      className="h-2 w-2 rounded-full flex-shrink-0" 
                      style={{ backgroundColor: shift.color || '#6B7280' }}
                    />
                    <span className="font-medium text-slate-700 truncate">{shift.name}</span>
                    <span className="text-slate-500 flex items-center">
                      <ClockIcon className="mr-0.5 h-3 w-3 text-slate-400" />
                      {moment(shift.start_time, 'HH:mm').format('h:mm A')}
                    </span>
                  </div>
                ))}
                
                {shifts.length > 2 && (
                  <p className="text-xs text-blue-600">
                    +{shifts.length - 2} more shifts
                  </p>
                )}
              </div>
            </div>
          ) : (
            <p className="text-xs text-slate-500 text-center py-2">No upcoming shifts</p>
          )}
        </div>
      </button>
    </Card>
  );
} 