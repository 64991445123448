import * as React from 'react'
import { clsx } from 'clsx'

type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'secondary'
type ButtonColor = 'gray' | 'green' | 'amber' | 'red' | 'blue'
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg'

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  variant?: ButtonVariant
  color?: ButtonColor
  size?: ButtonSize
  disabled?: boolean
  children: React.ReactNode
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'solid',
      color = 'gray',
      size = 'md',
      disabled = false,
      className,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        className={clsx(
          'inline-flex select-none items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition',
          'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
          {
            // Size variations
            'px-2 py-1 text-xs': size === 'xs',
            'px-2.5 py-1.5 text-sm': size === 'sm',
            'px-3.5 py-2 text-sm': size === 'md',
            'px-4 py-2.5 text-base': size === 'lg',

            // Solid variant
            'border shadow-sm': variant === 'solid',
            'bg-gray-900 text-white hover:bg-gray-700 focus-visible:outline-gray-900': 
              variant === 'solid' && color === 'gray',
            'bg-green-600 text-white hover:bg-green-500 focus-visible:outline-green-600': 
              variant === 'solid' && color === 'green',
            'bg-amber-500 text-white hover:bg-amber-400 focus-visible:outline-amber-500': 
              variant === 'solid' && color === 'amber',
            'bg-red-600 text-white hover:bg-red-500 focus-visible:outline-red-600': 
              variant === 'solid' && color === 'red',
            'bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600': 
              variant === 'solid' && color === 'blue',

            // Outline variant
            'border-outline shadow-sm': variant === 'outline',
            'border border-gray-300 text-gray-700 hover:bg-gray-50 focus-visible:outline-gray-600': 
              variant === 'outline' && color === 'gray',
            'border border-green-600 text-green-700 hover:bg-green-50 focus-visible:outline-green-600': 
              variant === 'outline' && color === 'green',
            'border border-amber-500 text-amber-700 hover:bg-amber-50 focus-visible:outline-amber-500': 
              variant === 'outline' && color === 'amber',
            'border border-red-600 text-red-700 hover:bg-red-50 focus-visible:outline-red-600': 
              variant === 'outline' && color === 'red',
            'border border-blue-600 text-blue-700 hover:bg-blue-50 focus-visible:outline-blue-600': 
              variant === 'outline' && color === 'blue',

            // Ghost variant
            'text-gray-700 hover:bg-gray-50 focus-visible:outline-gray-600': 
              variant === 'ghost' && color === 'gray',
            'text-green-700 hover:bg-green-50 focus-visible:outline-green-600': 
              variant === 'ghost' && color === 'green',
            'text-amber-700 hover:bg-amber-50 focus-visible:outline-amber-500': 
              variant === 'ghost' && color === 'amber',
            'text-red-700 hover:bg-red-50 focus-visible:outline-red-600': 
              variant === 'ghost' && color === 'red',
            'text-blue-700 hover:bg-blue-50 focus-visible:outline-blue-600': 
              variant === 'ghost' && color === 'blue',

            // Secondary variant
            'secondary-variant border shadow-sm': variant === 'secondary',
            'bg-white border-gray-300 text-gray-700 hover:bg-gray-50 focus-visible:outline-gray-600': 
              variant === 'secondary' && color === 'gray',
            'border-green-100 bg-green-50 text-green-700 hover:bg-green-100 focus-visible:outline-green-600': 
              variant === 'secondary' && color === 'green',
            'border-amber-100 bg-amber-50 text-amber-700 hover:bg-amber-100 focus-visible:outline-amber-500': 
              variant === 'secondary' && color === 'amber',
            'border-red-100 bg-red-50 text-red-700 hover:bg-red-100 focus-visible:outline-red-600': 
              variant === 'secondary' && color === 'red',
            'border-blue-100 bg-blue-50 text-blue-700 hover:bg-blue-100 focus-visible:outline-blue-600': 
              variant === 'secondary' && color === 'blue',

            // Disabled state
            'opacity-50 cursor-not-allowed': disabled,
          },
          className
        )}
        {...props}
      >
        {children}
      </button>
    )
  }
) 