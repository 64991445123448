import * as React from 'react'
import { clsx } from 'clsx'

type BadgeVariant = 'solid' | 'outline' | 'soft'
type BadgeColor = 'gray' | 'green' | 'amber' | 'red' | 'blue'

export interface BadgeProps extends React.ComponentPropsWithoutRef<'span'> {
  variant?: BadgeVariant
  color?: BadgeColor
  children: React.ReactNode
}

export function Badge({
  variant = 'solid',
  color = 'gray',
  className,
  children,
  ...props
}: BadgeProps) {
  return (
    <span
      className={clsx(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
        {
          // Solid variant
          'bg-gray-50 text-gray-700 ring-gray-700/10': variant === 'solid' && color === 'gray',
          'bg-green-50 text-green-700 ring-green-700/10': variant === 'solid' && color === 'green',
          'bg-amber-50 text-amber-700 ring-amber-700/10': variant === 'solid' && color === 'amber',
          'bg-red-50 text-red-700 ring-red-700/10': variant === 'solid' && color === 'red',
          'bg-blue-50 text-blue-700 ring-blue-700/10': variant === 'solid' && color === 'blue',

          // Outline variant
          'bg-transparent text-gray-700 ring-gray-300': variant === 'outline' && color === 'gray',
          'bg-transparent text-green-700 ring-green-600': variant === 'outline' && color === 'green',
          'bg-transparent text-amber-700 ring-amber-500': variant === 'outline' && color === 'amber',
          'bg-transparent text-red-700 ring-red-600': variant === 'outline' && color === 'red',
          'bg-transparent text-blue-700 ring-blue-600': variant === 'outline' && color === 'blue',

          // Soft variant
          'bg-gray-100 text-gray-700 ring-gray-100': variant === 'soft' && color === 'gray',
          'bg-green-100 text-green-700 ring-green-100': variant === 'soft' && color === 'green',
          'bg-amber-100 text-amber-700 ring-amber-100': variant === 'soft' && color === 'amber',
          'bg-red-100 text-red-700 ring-red-100': variant === 'soft' && color === 'red',
          'bg-blue-100 text-blue-700 ring-blue-100': variant === 'soft' && color === 'blue',
        },
        className
      )}
      {...props}
    >
      {children}
    </span>
  )
} 