import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { usePeople } from '../../People/hooks';
import { useToast } from '../../../utils/useToast';
import { Input } from '../../../ui/form/Input';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { AssignEmployee } from './AssignEmployee';
import rota from '../../../network/rota';
import { Modal } from '../../../ui/Modal';
import { Button } from '../../../ui/Button';

const colorOptions = [
  {
    id: '1',
    hex: '#F4F4F5',
  },
  {
    id: '2',
    hex: '#BE185D',
  },
  {
    id: '3',
    hex: '#A21CAF',
  },
  {
    id: '4',
    hex: '#7E22CE',
  },
  {
    id: '5',
    hex: '#6D28D9',
  },
  {
    id: '6',
    hex: '#4338CA',
  },
  {
    id: '7',
    hex: '#1D4ED8',
  },
  {
    id: '8',
    hex: '#0369A1',
  },
  {
    id: '9',
    hex: '#0E7490',
  },
  {
    id: '10',
    hex: '#0F766E',
  },
  {
    id: '11',
    hex: '#047857',
  },
  {
    id: '12',
    hex: '#15803D',
  },
];

export default function CreateNewRotaModal({ open, setOpen, refetchRotas }: any) {
  const [isShownAssignEmployees, setIsShownAssignEmployyes] = useState(false);

  const { data: peopleResponse = { data: [] } } = usePeople();
  const peopleOptions = peopleResponse?.data || [];
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      name: '',
      start_time: '',
      end_time: '',
      employees: [],
      color: '#F4F4F5',
      start_date: '',
      end_date: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(),
      start_time: yup.string().required(),
      end_time: yup.string().required(),
      start_date: yup.string().required(),
    }),
    onSubmit: async (data) => {
      try {
        await rota.createRota(data);
        refetchRotas();
        toast({
          variant: 'success',
          title: 'Rota successfully created.',
        });
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.',
        });
      }
      setOpen(false);
      formik.resetForm();
    },
  });

  function findError(
    fieldName: keyof typeof formik.initialValues,
  ): string | undefined {
    const error =
      formik.touched[fieldName] && formik.errors[fieldName]
        ? formik.errors[fieldName]
        : undefined;

    if (Array.isArray(error)) {
      return error.join(', ');
    }

    return error as string | undefined;
  }

  const handleSelectPeople = (id: string, checked: boolean) => {
    const updatedPeople = checked
      ? [...formik.values.employees, id]
      : formik.values.employees.filter((personId) => personId !== id);
    formik.setFieldValue('employees', updatedPeople);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} maxWidth="lg">
      {!isShownAssignEmployees ? (
        <>
          <Modal.Header onClose={() => setOpen(false)}>
            <Modal.Title>Create new rota</Modal.Title>
            <Modal.Description>
              Create a new rota, assign working hours and employees
            </Modal.Description>
          </Modal.Header>
          
          <Modal.Body>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-4"
            >
              <div className="flex flex-col gap-1.5">
                <label htmlFor="name" className="text-sm font-medium text-slate-700">
                  Name
                </label>
                <Input
                  type="text"
                  placeholder="Enter name"
                  id="name"
                  name="name"
                  error={findError('name')}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  className="w-full"
                />
              </div>
              
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-slate-700">Work time</label>
                <div className="flex gap-4">
                  <div className="flex w-1/2 flex-col gap-1.5">
                    <label
                      htmlFor="start_time"
                      className="text-sm font-medium text-slate-700"
                    >
                      Start time
                    </label>
                    <Input
                      type="text"
                      placeholder="00:00"
                      id="start_time"
                      name="start_time"
                      error={findError('start_time')}
                      value={formik.values.start_time}
                      onChange={formik.handleChange}
                      className="w-full"
                    />
                  </div>
                  <div className="flex w-1/2 flex-col gap-1.5">
                    <label
                      htmlFor="end_time"
                      className="text-sm font-medium text-slate-700"
                    >
                      End time
                    </label>
                    <Input
                      type="text"
                      placeholder="00:00"
                      id="end_time"
                      name="end_time"
                      error={findError('end_time')}
                      value={formik.values.end_time}
                      onChange={formik.handleChange}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
              
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-slate-700">Work days</label>
                <div className="flex gap-4">
                  <div className="flex w-1/2 flex-col gap-1.5">
                    <label
                      htmlFor="start_date"
                      className="text-sm font-medium text-slate-700"
                    >
                      Start date
                    </label>
                    <Input
                      type="date"
                      id="start_date"
                      name="start_date"
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      error={findError('start_date')}
                      className="w-full"
                    />
                  </div>
                  <div className="flex w-1/2 flex-col gap-1.5">
                    <label
                      htmlFor="end_date"
                      className="text-sm font-medium text-slate-700"
                    >
                      End date
                    </label>
                    <Input
                      type="date"
                      id="end_date"
                      name="end_date"
                      value={formik.values.end_date}
                      onChange={formik.handleChange}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
              
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-slate-700">Color</label>
                <div className="flex flex-wrap gap-2">
                  {colorOptions.map((option) => (
                    <div
                      key={option.id}
                      onClick={() => formik.setFieldValue('color', option.hex)}
                      className={`h-8 w-8 cursor-pointer rounded-full border-2 ${
                        formik.values.color === option.hex
                          ? 'border-slate-600'
                          : 'border-white'
                      }`}
                      style={{ backgroundColor: option.hex }}
                    />
                  ))}
                </div>
              </div>
              
              <div className="mt-2">
                <Button
                  type="button"
                  variant="outline"
                  className="w-full justify-between"
                  onClick={() => setIsShownAssignEmployyes(true)}
                >
                  <span>Assign employees</span>
                  <ChevronRightIcon className="h-5 w-5" />
                </Button>
              </div>
              
              <input
                type="hidden"
                name="employees"
                value={formik.values.employees}
              />
            </form>
          </Modal.Body>
          
          <Modal.Footer>
            <Button
              variant="outline"
              onClick={() => {
                setOpen(false);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button color="red" onClick={() => formik.submitForm()}>
              Create rota
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <AssignEmployee
          setIsShownAssignEmployyes={setIsShownAssignEmployyes}
          peopleOptions={peopleOptions}
          handleSelectPeople={handleSelectPeople}
          selectedEmployees={formik.values.employees}
        />
      )}
    </Modal>
  );
}
