import moment, { Moment } from 'moment';
import { WeekCellProps } from '../../../types/RotaTypes';
import { clsx } from 'clsx';
import { Popover } from '@headlessui/react';
import {
  ArrowRightIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
  PencilIcon as PencilSquareIcon,
} from '@heroicons/react/20/solid';
import { MinusCircleIcon, UserIcon } from '@heroicons/react/20/solid';
import { Button } from '../../../ui/Button';
import { Card } from '../../../ui/Card';

const getUniqueDayRotas = (dayRotas: any[]) => {
  return Array.from(new Set(dayRotas.map((rota) => rota.id))).map((id) =>
    dayRotas.find((rota) => rota.id === id),
  );
};

export function WeekDayCell({ days, rotas, today, onRotaClick }: WeekCellProps) {
  return (
    <div className="grid w-full grid-cols-7 divide-x divide-slate-200">
      {days?.map((day, index) => {
        const dayRotas = rotas.filter((rota) =>
          moment(day).isBetween(rota.start_date, rota.end_date, 'day', '[]'),
        );
        const uniqueDayRotas = getUniqueDayRotas(dayRotas);
        const isToday = moment().isSame(day, 'day');
        
        return (
          <div key={index} className="min-h-[600px] bg-white">
            <div className="flex flex-col items-center border-b border-slate-200 py-3">
              <p className="text-sm font-medium text-slate-500">
                {moment(day).format('ddd')}
              </p>
              <div
                className={clsx(
                  'mt-1 flex h-7 w-7 items-center justify-center rounded-full text-sm',
                  {
                    'bg-red-600 font-medium text-white': isToday,
                    'font-medium text-slate-900': !isToday,
                  },
                )}
              >
                {moment(day).format('D')}
              </div>
            </div>

            <div className="flex flex-col gap-2 p-2">
              {uniqueDayRotas.map((rota) => (
                <WeekDayRotaItem key={rota.id} rota={rota} onRotaClick={onRotaClick} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function WeekDayRotaItem({ rota, onRotaClick }: { rota: any; onRotaClick?: (rota: any) => void }) {
  const handleClick = () => {
    if (onRotaClick) {
      onRotaClick(rota);
    }
  };

  return (
    <div 
      className={clsx(
        "w-full rounded-md border-l-4 bg-slate-50 px-3 py-2 text-left text-xs shadow-sm hover:bg-slate-100 cursor-pointer",
      )}
      style={{ borderLeftColor: rota.color }}
      onClick={handleClick}
    >
      <p className="font-medium text-slate-900">{rota.name}</p>
      <p className="text-slate-700">
        {moment(rota.start_time, 'HH:mm').format('h:mm A')} - {' '}
        {moment(rota.end_time, 'HH:mm').format('h:mm A')}
      </p>
      <p className="mt-1 text-slate-500">
        {rota?.employees?.length || 0} {rota?.employees?.length === 1 ? 'Employee' : 'Employees'}
      </p>
    </div>
  );
}
