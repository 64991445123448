import axios from 'axios';
import { Dialog } from 'evergreen-ui';
import { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { EditUserIcon } from '../../../assets/EditUserIcon';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { editUser } from '../../../network';
import { FormGroup } from '../../../ui/form/FormGroup';
import { Input } from '../../../ui/form/Input';
import { Select } from '../../../ui/form/Select';
import { useOffices, usePeople, useRoles, useTeams } from '../hooks';
import * as Yup from 'yup';

type Props = {
  isOpen: boolean;
  onClose(): void;
  refetch(): void;
  user: any;
};

export const EditUserDialog = ({ isOpen, onClose, refetch, user }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { data: team_data } = useTeams();
  const { data: office_data } = useOffices();
  const { data: people_data } = usePeople();
  
  // Split the full name into first and last name
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  
  useEffect(() => {
    if (user?.full_name) {
      // Split the full name into parts
      const nameParts = user.full_name.split(' ');
      // The first part is the first name
      const first = nameParts[0] || '';
      // The rest is the last name (join with space if multiple parts)
      const last = nameParts.slice(1).join(' ') || '';
      
      setFirstName(first);
      setLastName(last);
    }
  }, [user]);

  // Define validation schema
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phone: Yup.string().matches(/^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}$/, 'Invalid phone number format'),
  });

  const formik = useFormik({
    initialValues: {
      id: user?.id,
      firstName: firstName,
      lastName: lastName,
      role_name: user?.role_name,
      role: user?.role || '',
      team: user?.team || (team_data && team_data[0]) || { id: '' },
      office: user?.office?.id ? user?.office?.id : '',
      manager: user?.manager?.id ? user?.manager?.id : '',
      phone: user?.phone || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsEditing(true);
        
        // Use destructuring to create a new object without firstName and lastName
        const { firstName, lastName, ...apiSubmitData } = values;
        
        // Map firstName and lastName to first_name and last_name for the API
        const submitData = {
          ...apiSubmitData,
          first_name: firstName,
          last_name: lastName,
          team: values.team.id
        };
        
        await editUser(submitData);
        setIsEditing(false);
      } catch (error) {
        console.log(error);
        setIsEditing(false);
      }
      closeDialog();
      refetch();
    },
  });

  function findError(
    fieldName: keyof typeof formik.initialValues,
  ): string | undefined {
    const error = formik.touched[fieldName]
      ? formik.errors[fieldName]
      : undefined;
    return typeof error === 'string' ? error : undefined;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.handleSubmit(event);
  };

  function closeDialog() {
    formik.resetForm();
    onClose();
  }

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={closeDialog}
      hasFooter={false}
      hasHeader={false}
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="rounded-[10px] bg-[#FEE9E9] p-3 text-[#DC2626] shadow-sm">
            <EditUserIcon />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
        </div>

        <div className="mb-5 flex flex-col gap-1">
          <div className="text-lg font-semibold">User Detail</div>
          <div className="text-sm font-normal text-[#475467]">
            Update the user's information and permissions as needed. Make sure
            all details are accurate before saving.
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-3">
          <FormGroup label="First Name" htmlFor="firstName-input">
            <Input
              id="firstName-input"
              type="text"
              name="firstName"
              error={findError('firstName')}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter first name"
            />
          </FormGroup>
          
          <FormGroup label="Last Name" htmlFor="lastName-input">
            <Input
              id="lastName-input"
              type="text"
              name="lastName"
              error={findError('lastName')}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter last name"
            />
          </FormGroup>
          
          <FormGroup label="Job Title" htmlFor="job_title-input">
            <Input
              id="job_title-input"
              type="text"
              name="role_name"
              value={formik.values.role_name}
              onChange={formik.handleChange}
              placeholder="Enter job title"
            />
          </FormGroup>
          <FormGroup label="Role within the company" htmlFor="role-input">
            <Select
              id="role-input"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
            >
              <option value="HR_ADMIN">HR Admin</option>
              <option value="EMPLOYEE">Employee</option>
            </Select>
          </FormGroup>

          <FormGroup label="Office location" htmlFor="office-input">
            <Select
              id="office-input"
              name="office"
              value={formik.values.office}
              onChange={formik.handleChange}
            >
              {office_data &&
                !office_data?.status &&
                office_data?.map((office: any, index: number) => (
                  <option key={index} value={office.id}>
                    {office.name}
                  </option>
                ))}
            </Select>
          </FormGroup>
          <FormGroup label="Team" htmlFor="team-input">
            <Select
              id="team-input"
              name="team.id"
              value={formik.values.team.id}
              onChange={formik.handleChange}
            >
              {team_data &&
                !team_data?.status &&
                team_data?.map((team: any, index: number) => (
                  <option key={index} value={team.id}>
                    {team.name}
                  </option>
                ))}
            </Select>
          </FormGroup>
          <FormGroup label="Manager" htmlFor="manager-input">
            <Select
              id="manager-input"
              name="manager"
              value={formik.values.manager}
              onChange={formik.handleChange}
            >
              {people_data?.data?.map((person: any, index: number) => (
                <option key={index} value={person.id}>
                  {person.full_name}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup label="Phone" htmlFor="phone-input">
            <Input
              id="phone-input"
              type="text"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </FormGroup>
          <div className="pb-6 mt-8 flex flex-row justify-between gap-3 ">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="w-full rounded-lg  bg-[#DC2626] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
            disabled={isEditing}
          >
            Save
          </button>
        </div>
        </form> 
      </div>
    </Dialog>
  );
};
