import React, { forwardRef } from 'react';
import clsx from 'clsx';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  optional?: boolean;
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, children, optional, ...props }, ref) => {
    return (
      <label
        ref={ref}
        className={clsx('block text-sm font-medium text-gray-700', className)}
        {...props}
      >
        {children}
        {optional && (
          <span className="ml-1 text-sm font-normal text-gray-500">(Optional)</span>
        )}
      </label>
    );
  }
);

Label.displayName = 'Label'; 