import React from 'react';
import { clsx } from 'clsx';

interface HeaderProps {
  children: React.ReactNode;
  className?: string;
}

export const Header: React.FC<HeaderProps> = ({ children, className }) => {
  return (
    <header
      className={clsx(
        'flex flex-col space-y-3 sm:flex-row sm:items-center sm:justify-between sm:space-y-0',
        className
      )}
    >
      {children}
    </header>
  );
};

export const HeaderTitle: React.FC<HeaderProps> = ({ children, className }) => {
  return (
    <h1
      className={clsx(
        'text-2xl font-bold tracking-tight text-slate-900',
        className
      )}
    >
      {children}
    </h1>
  );
};

export const HeaderAction: React.FC<HeaderProps> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'flex flex-shrink-0 items-center space-x-3',
        className
      )}
    >
      {children}
    </div>
  );
}; 