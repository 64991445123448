import { useState, useEffect } from 'react';
import {
  Card,
  Title,
  Text,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
  Flex,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Select,
  SelectItem,
  DateRangePicker,
  DateRangePickerValue,
  MultiSelect,
  MultiSelectItem,
  TextInput,
  Button,
  NumberInput,
  Divider,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@tremor/react';
import { RiAlertLine, RiSearchLine, RiCloseLine, RiFileDownloadLine, RiSettings4Line, RiRefreshLine } from '@remixicon/react';
import { reportService } from '../../services/report';
import { saveAs } from 'file-saver';

// Default risk level thresholds
const DEFAULT_RISK_LEVELS = {
  LOW: { max: 50, color: 'green', label: 'Low' },
  MEDIUM: { min: 51, max: 200, color: 'yellow', label: 'Medium' },
  HIGH: { min: 201, max: 500, color: 'orange', label: 'High' },
  CRITICAL: { min: 501, color: 'red', label: 'Critical' },
};

// Default formula settings
const DEFAULT_FORMULA_SETTINGS = {
  spellsExponent: 2,
  daysMultiplier: 1,
  formulaDescription: 'B = S² × D'
};

// Define types
type Employee = {
  userId: string;
  userName: string;
  spellsOfAbsence: number;
  totalDaysAbsent: number;
  bradfordFactor: number;
};

type DepartmentOption = {
  value: string;
  label: string;
};

type EmploymentTypeOption = {
  value: string;
  label: string;
};

type EmployeeOption = {
  value: string;
  label: string;
};

// Mock data for departments and employment types
const departments: DepartmentOption[] = [
  { value: 'all', label: 'All Departments' },
  { value: 'hr', label: 'Human Resources' },
  { value: 'it', label: 'Information Technology' },
  { value: 'sales', label: 'Sales' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'finance', label: 'Finance' },
];

const employmentTypes: EmploymentTypeOption[] = [
  { value: 'all', label: 'All Types' },
  { value: 'full-time', label: 'Full Time' },
  { value: 'part-time', label: 'Part Time' },
  { value: 'contract', label: 'Contract' },
  { value: 'intern', label: 'Intern' },
];

// Mock response data
const mockEmployees: Employee[] = [
  { userId: '1', userName: 'John Doe', spellsOfAbsence: 5, totalDaysAbsent: 10, bradfordFactor: 250 },
  { userId: '2', userName: 'Jane Smith', spellsOfAbsence: 3, totalDaysAbsent: 15, bradfordFactor: 135 },
  { userId: '3', userName: 'Bob Johnson', spellsOfAbsence: 8, totalDaysAbsent: 8, bradfordFactor: 512 },
  { userId: '4', userName: 'Alice Brown', spellsOfAbsence: 2, totalDaysAbsent: 4, bradfordFactor: 16 },
  { userId: '5', userName: 'Charlie Williams', spellsOfAbsence: 4, totalDaysAbsent: 12, bradfordFactor: 192 },
  { userId: '6', userName: 'Diana Garcia', spellsOfAbsence: 7, totalDaysAbsent: 14, bradfordFactor: 686 },
  { userId: '7', userName: 'Ethan Martinez', spellsOfAbsence: 1, totalDaysAbsent: 3, bradfordFactor: 3 },
];

// Calculate department averages from mock data
const mockDepartmentAverages = [
  { department: 'Human Resources', averageScore: 158 },
  { department: 'Information Technology', averageScore: 220 },
  { department: 'Sales', averageScore: 105 },
  { department: 'Marketing', averageScore: 86 },
  { department: 'Finance', averageScore: 143 },
];

// Mock employee options for search
const mockEmployeeOptions: EmployeeOption[] = [
  { value: 'all', label: 'All Employees' },
  { value: '1', label: 'John Doe' },
  { value: '2', label: 'Jane Smith' },
  { value: '3', label: 'Bob Johnson' },
  { value: '4', label: 'Alice Brown' },
  { value: '5', label: 'Charlie Williams' },
  { value: '6', label: 'Diana Garcia' },
  { value: '7', label: 'Ethan Martinez' },
  { value: '8', label: 'Frank Wilson' },
  { value: '9', label: 'Grace Davis' },
  { value: '10', label: 'Hannah Miller' },
];

// Helper function to determine risk level
const getRiskLevel = (bradfordFactor: number) => {
  if (bradfordFactor <= DEFAULT_RISK_LEVELS.LOW.max) return DEFAULT_RISK_LEVELS.LOW;
  if (bradfordFactor >= DEFAULT_RISK_LEVELS.MEDIUM.min && bradfordFactor <= DEFAULT_RISK_LEVELS.MEDIUM.max) return DEFAULT_RISK_LEVELS.MEDIUM;
  if (bradfordFactor >= DEFAULT_RISK_LEVELS.HIGH.min && bradfordFactor <= DEFAULT_RISK_LEVELS.HIGH.max) return DEFAULT_RISK_LEVELS.HIGH;
  return DEFAULT_RISK_LEVELS.CRITICAL;
};

export default function BradfordFactor() {
  // State for filters
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: new Date(new Date().getFullYear(), 0, 1), // Start of current year
    to: new Date(),
  });
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState<string[]>(['all']);
  const [selectedEmployee, setSelectedEmployee] = useState<string>('all');
  const [employeeSearchTerm, setEmployeeSearchTerm] = useState<string>('');
  const [employeeOptions, setEmployeeOptions] = useState<EmployeeOption[]>(mockEmployeeOptions);
  
  // State for employee data
  const [employees, setEmployees] = useState<Employee[]>(mockEmployees);
  const [departmentAverages, setDepartmentAverages] = useState(mockDepartmentAverages);
  const [loading, setLoading] = useState<boolean>(false);

  // Settings state
  const [showSettings, setShowSettings] = useState(false);
  const [thresholds, setThresholds] = useState({
    lowMax: DEFAULT_RISK_LEVELS.LOW.max,
    mediumMin: DEFAULT_RISK_LEVELS.MEDIUM.min,
    mediumMax: DEFAULT_RISK_LEVELS.MEDIUM.max,
    highMin: DEFAULT_RISK_LEVELS.HIGH.min,
    highMax: DEFAULT_RISK_LEVELS.HIGH.max,
    criticalMin: DEFAULT_RISK_LEVELS.CRITICAL.min,
  });
  const [formulaSettings, setFormulaSettings] = useState({
    spellsExponent: DEFAULT_FORMULA_SETTINGS.spellsExponent,
    daysMultiplier: DEFAULT_FORMULA_SETTINGS.daysMultiplier,
  });
  const [formulaDescription, setFormulaDescription] = useState(DEFAULT_FORMULA_SETTINGS.formulaDescription);

  // Filter employee options based on search term
  const filteredEmployeeOptions = employeeOptions.filter(
    (emp) => 
      emp.value === 'all' || 
      emp.label.toLowerCase().includes(employeeSearchTerm.toLowerCase())
  );

  // Calculate metrics
  const highRiskEmployees = employees.filter(emp => emp.bradfordFactor > DEFAULT_RISK_LEVELS.HIGH.min).length;
  const totalEmployees = employees.length;
  const averageBradfordScore = employees.length > 0 
    ? Math.round(employees.reduce((acc, emp) => acc + emp.bradfordFactor, 0) / employees.length) 
    : 0;

  // Fetch employee data when needed
  useEffect(() => {
    // In a real implementation, fetch employees from API based on department, etc.
    // For now, just use mock data
    setEmployeeOptions(mockEmployeeOptions);
  }, [selectedDepartment]);

  // Fetch data function
  const fetchBradfordData = async () => {
    setLoading(true);
    try {
      const response = await reportService.getBradfordFactorData({
        department: selectedDepartment, 
        employmentTypes: selectedEmploymentTypes,
        startDate: dateRange.from?.toISOString().split('T')[0],
        endDate: dateRange.to?.toISOString().split('T')[0],
        employeeId: selectedEmployee !== 'all' ? selectedEmployee : undefined,
        // Send custom formula settings if they differ from defaults
        formulaSettings: 
          formulaSettings.spellsExponent !== DEFAULT_FORMULA_SETTINGS.spellsExponent || 
          formulaSettings.daysMultiplier !== DEFAULT_FORMULA_SETTINGS.daysMultiplier ? 
          {
            spellsExponent: formulaSettings.spellsExponent,
            daysMultiplier: formulaSettings.daysMultiplier
          } : undefined,
        // Send custom thresholds if they differ from defaults
        thresholds:
          thresholds.lowMax !== DEFAULT_RISK_LEVELS.LOW.max ||
          thresholds.mediumMax !== DEFAULT_RISK_LEVELS.MEDIUM.max ||
          thresholds.highMax !== DEFAULT_RISK_LEVELS.HIGH.max ?
          {
            lowMax: thresholds.lowMax,
            mediumMax: thresholds.mediumMax,
            highMax: thresholds.highMax
          } : undefined
      });
      
      // Extract data from response
      if (response.payload?.bradford_factor) {
        setEmployees(response.payload.bradford_factor.bradfordFactors);
        
        // Use department averages from API if available
        if (response.payload.bradford_factor.departmentAverages) {
          setDepartmentAverages(response.payload.bradford_factor.departmentAverages);
        } else {
          // Fall back to calculating department averages locally
          const deptAverages = calculateDepartmentAverages(response.payload.bradford_factor.bradfordFactors);
          setDepartmentAverages(deptAverages);
        }

        // Update settings from server if they're returned
        if (response.payload.bradford_factor.settings) {
          const serverSettings = response.payload.bradford_factor.settings;
          
          // Update formula settings if provided
          if (serverSettings.formula) {
            setFormulaSettings({
              spellsExponent: serverSettings.formula.spellsExponent,
              daysMultiplier: serverSettings.formula.daysMultiplier
            });
          }
          
          // Update thresholds if provided
          if (serverSettings.thresholds) {
            setThresholds({
              lowMax: serverSettings.thresholds.lowMax,
              mediumMin: serverSettings.thresholds.mediumMin,
              mediumMax: serverSettings.thresholds.mediumMax,
              highMin: serverSettings.thresholds.highMin,
              highMax: serverSettings.thresholds.highMax,
              criticalMin: serverSettings.thresholds.criticalMin
            });
          }
        }
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Bradford Factor data:', error);
      setLoading(false);
    }
  };
  
  // Helper function to calculate department averages
  const calculateDepartmentAverages = (employees: Employee[]) => {
    // In a real implementation, employees would have department info
    // For now, just return mock data
    return mockDepartmentAverages;
  };

  // Reset search term when employee changes
  const handleEmployeeChange = (value: string) => {
    setSelectedEmployee(value);
    setEmployeeSearchTerm('');
  };

  // Effect to fetch data when filters change
  useEffect(() => {
    fetchBradfordData();
  }, [selectedDepartment, selectedEmploymentTypes, dateRange, selectedEmployee]);

  // Export to CSV function
  const exportToCSV = () => {
    // CSV headers
    const headers = ['Employee', 'Total Absences', 'Total Days', 'Bradford Score', 'Risk Level'];
    
    // Format employee data for CSV
    const rows = employees.map(employee => {
      const risk = getRiskLevel(employee.bradfordFactor);
      return [
        employee.userName,
        employee.spellsOfAbsence.toString(),
        employee.totalDaysAbsent.toString(),
        employee.bradfordFactor.toString(),
        risk.label
      ];
    });
    
    // Combine headers and rows
    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');
    
    // Create and download file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `bradford-factor-report-${new Date().toISOString().split('T')[0]}.csv`);
  };
  
  // Export to Excel-compatible CSV function
  const exportToExcel = () => {
    // Excel CSV headers with BOM for proper character encoding
    const headers = ['Employee', 'Total Absences', 'Total Days', 'Bradford Score', 'Risk Level'];
    
    // Format employee data for Excel
    const rows = employees.map(employee => {
      const risk = getRiskLevel(employee.bradfordFactor);
      return [
        `"${employee.userName}"`, // Quotes to handle commas in names
        employee.spellsOfAbsence.toString(),
        employee.totalDaysAbsent.toString(),
        employee.bradfordFactor.toString(),
        `"${risk.label}"`
      ];
    });
    
    // Add department data if not filtering by employee
    if (selectedEmployee === 'all') {
      rows.push(['', '', '', '', '']); // Empty row as separator
      rows.push(['"Department Averages"', '', '', '', '']);
      rows.push(['Department', '', 'Average Score', 'Risk Level', '']);
      
      departmentAverages.forEach(dept => {
        const risk = getRiskLevel(dept.averageScore);
        rows.push([
          `"${dept.department}"`,
          '',
          dept.averageScore.toString(),
          `"${risk.label}"`,
          ''
        ]);
      });
    }
    
    // Combine headers and rows with BOM for Excel compatibility
    const BOM = '\uFEFF'; // UTF-8 BOM for Excel
    const csvContent = BOM + [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');
    
    // Create and download file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `bradford-factor-report-${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  // Update formula description when settings change
  useEffect(() => {
    // Create a formula description based on current settings
    if (formulaSettings.spellsExponent === 2 && formulaSettings.daysMultiplier === 1) {
      setFormulaDescription('B = S² × D');
    } else if (formulaSettings.spellsExponent === 2 && formulaSettings.daysMultiplier !== 1) {
      setFormulaDescription(`B = S² × (${formulaSettings.daysMultiplier} × D)`);
    } else if (formulaSettings.spellsExponent !== 2 && formulaSettings.daysMultiplier === 1) {
      setFormulaDescription(`B = S^${formulaSettings.spellsExponent} × D`);
    } else {
      setFormulaDescription(`B = S^${formulaSettings.spellsExponent} × (${formulaSettings.daysMultiplier} × D)`);
    }
  }, [formulaSettings]);

  // Modified getRiskLevel function to use custom thresholds
  const getRiskLevel = (bradfordFactor: number) => {
    if (bradfordFactor <= thresholds.lowMax) return { color: 'green', label: 'Low' };
    if (bradfordFactor >= thresholds.mediumMin && bradfordFactor <= thresholds.mediumMax) 
      return { color: 'yellow', label: 'Medium' };
    if (bradfordFactor >= thresholds.highMin && bradfordFactor <= thresholds.highMax) 
      return { color: 'orange', label: 'High' };
    return { color: 'red', label: 'Critical' };
  };

  // Calculate custom Bradford factor
  const calculateCustomBradfordFactor = (spells: number, days: number): number => {
    return Math.pow(spells, formulaSettings.spellsExponent) * (days * formulaSettings.daysMultiplier);
  };

  // Apply custom settings to employee data
  const applyCustomSettings = () => {
    setLoading(true);
    
    // Recalculate Bradford Factors using custom formula settings
    const recalculatedEmployees = mockEmployees.map(employee => {
      const customBradfordFactor = calculateCustomBradfordFactor(
        employee.spellsOfAbsence, 
        employee.totalDaysAbsent
      );
      
      return {
        ...employee,
        bradfordFactor: Math.round(customBradfordFactor)
      };
    });
    
    setEmployees(recalculatedEmployees);
    
    // Also update department averages
    const recalculatedDeptAverages = mockDepartmentAverages.map(dept => {
      // For mockup, we'll just scale the existing average by the formula changes
      const scaleFactor = (formulaSettings.spellsExponent / DEFAULT_FORMULA_SETTINGS.spellsExponent) * 
                         (formulaSettings.daysMultiplier / DEFAULT_FORMULA_SETTINGS.daysMultiplier);
      
      return {
        ...dept,
        averageScore: Math.round(dept.averageScore * scaleFactor)
      };
    });
    
    setDepartmentAverages(recalculatedDeptAverages);
    setLoading(false);
  };

  // Reset settings to defaults
  const resetSettings = () => {
    setThresholds({
      lowMax: DEFAULT_RISK_LEVELS.LOW.max,
      mediumMin: DEFAULT_RISK_LEVELS.MEDIUM.min,
      mediumMax: DEFAULT_RISK_LEVELS.MEDIUM.max,
      highMin: DEFAULT_RISK_LEVELS.HIGH.min,
      highMax: DEFAULT_RISK_LEVELS.HIGH.max,
      criticalMin: DEFAULT_RISK_LEVELS.CRITICAL.min,
    });
    
    setFormulaSettings({
      spellsExponent: DEFAULT_FORMULA_SETTINGS.spellsExponent,
      daysMultiplier: DEFAULT_FORMULA_SETTINGS.daysMultiplier,
    });
    
    // Reload data with default calculations
    fetchBradfordData();
  };

  // Validate that thresholds are consistent
  useEffect(() => {
    // Ensure thresholds are consistent (e.g., lowMax + 1 === mediumMin)
    const adjustedThresholds = { ...thresholds };
    
    adjustedThresholds.mediumMin = thresholds.lowMax + 1;
    adjustedThresholds.highMin = thresholds.mediumMax + 1;
    adjustedThresholds.criticalMin = thresholds.highMax + 1;
    
    if (JSON.stringify(adjustedThresholds) !== JSON.stringify(thresholds)) {
      setThresholds(adjustedThresholds);
    }
  }, [thresholds.lowMax, thresholds.mediumMax, thresholds.highMax]);

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="mb-8 flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-tremor-content-strong mb-2">Bradford Factor Report</h2>
          <Text>Monitor and manage frequent short-term absence patterns using the formula: {formulaDescription}</Text>
        </div>
        <div className="flex gap-2">
          <Button 
            variant="secondary" 
            icon={RiSettings4Line} 
            onClick={() => setShowSettings(!showSettings)}
          >
            Settings
          </Button>
          <Button 
            variant="secondary" 
            icon={RiFileDownloadLine} 
            onClick={exportToCSV}
            disabled={employees.length === 0 || loading}
          >
            Export CSV
          </Button>
          <Button 
            variant="secondary" 
            icon={RiFileDownloadLine} 
            onClick={exportToExcel}
            disabled={employees.length === 0 || loading}
          >
            Export Excel
          </Button>
        </div>
      </div>

      {/* Settings Panel */}
      {showSettings && (
        <Card className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <Title>Bradford Factor Settings</Title>
            <Button 
              variant="light" 
              icon={RiRefreshLine} 
              onClick={resetSettings}
              color="red"
            >
              Reset to Defaults
            </Button>
          </div>
          
          <Accordion className="mt-2">
            <AccordionHeader>Formula Settings</AccordionHeader>
            <AccordionBody>
              <Text className="mb-2">
                The standard Bradford Factor formula is B = S² × D, where S is the number of spells (instances) of absence and D is the total days.
                You can customize this formula below:
              </Text>
              
              <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
                <div>
                  <Text className="mb-2">Spells (S) Exponent</Text>
                  <NumberInput
                    value={formulaSettings.spellsExponent}
                    onValueChange={(value) => setFormulaSettings({...formulaSettings, spellsExponent: value || 2})}
                    min={1}
                    max={5}
                    placeholder="2"
                    step={0.1}
                  />
                  <Text className="mt-1 text-sm text-gray-500">
                    Higher values give more weight to frequency (default: 2)
                  </Text>
                </div>
                <div>
                  <Text className="mb-2">Days (D) Multiplier</Text>
                  <NumberInput
                    value={formulaSettings.daysMultiplier}
                    onValueChange={(value) => setFormulaSettings({...formulaSettings, daysMultiplier: value || 1})}
                    min={0.1}
                    max={10}
                    placeholder="1"
                    step={0.1}
                  />
                  <Text className="mt-1 text-sm text-gray-500">
                    Higher values give more weight to duration (default: 1)
                  </Text>
                </div>
              </div>
              
              <div className="mt-4">
                <Text className="font-medium">Current Formula: {formulaDescription}</Text>
              </div>
            </AccordionBody>
          </Accordion>
          
          <Accordion className="mt-2">
            <AccordionHeader>Risk Level Thresholds</AccordionHeader>
            <AccordionBody>
              <Text className="mb-2">
                Adjust the threshold values to determine when an employee falls into each risk category.
              </Text>
              
              <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-3">
                <div>
                  <Text className="mb-2">
                    <Badge color="green" className="mr-2">Low Risk</Badge>
                    Maximum Score
                  </Text>
                  <NumberInput
                    value={thresholds.lowMax}
                    onValueChange={(value) => setThresholds({...thresholds, lowMax: value || 50})}
                    min={0}
                    max={1000}
                    placeholder="50"
                  />
                </div>
                <div>
                  <Text className="mb-2">
                    <Badge color="yellow" className="mr-2">Medium Risk</Badge>
                    Maximum Score
                  </Text>
                  <NumberInput
                    value={thresholds.mediumMax}
                    onValueChange={(value) => setThresholds({...thresholds, mediumMax: value || 200})}
                    min={thresholds.mediumMin}
                    max={1000}
                    placeholder="200"
                  />
                </div>
                <div>
                  <Text className="mb-2">
                    <Badge color="orange" className="mr-2">High Risk</Badge>
                    Maximum Score
                  </Text>
                  <NumberInput
                    value={thresholds.highMax}
                    onValueChange={(value) => setThresholds({...thresholds, highMax: value || 500})}
                    min={thresholds.highMin}
                    max={2000}
                    placeholder="500"
                  />
                </div>
              </div>
              
              <div className="mt-4">
                <Text className="font-medium">Current Thresholds:</Text>
                <div className="grid grid-cols-4 gap-2 mt-2">
                  <div className="p-2 bg-green-100 rounded">
                    <Text><strong>Low:</strong> 0-{thresholds.lowMax}</Text>
                  </div>
                  <div className="p-2 bg-yellow-100 rounded">
                    <Text><strong>Medium:</strong> {thresholds.mediumMin}-{thresholds.mediumMax}</Text>
                  </div>
                  <div className="p-2 bg-orange-100 rounded">
                    <Text><strong>High:</strong> {thresholds.highMin}-{thresholds.highMax}</Text>
                  </div>
                  <div className="p-2 bg-red-100 rounded">
                    <Text><strong>Critical:</strong> {thresholds.criticalMin}+</Text>
                  </div>
                </div>
              </div>
            </AccordionBody>
          </Accordion>
          
          <Divider className="my-4" />
          
          <div className="flex justify-end">
            <Button onClick={applyCustomSettings}>
              Apply Settings
            </Button>
          </div>
        </Card>
      )}

      {/* Threshold Info Panel - Updated to use current thresholds */}
      <Card className="mb-6 bg-blue-50">
        <Flex alignItems="center" className="gap-2">
          <RiAlertLine className="h-5 w-5 text-blue-500" />
          <Title>Bradford Factor Thresholds</Title>
        </Flex>
        <Text className="mt-2">
          The Bradford Factor (B) identifies frequent short-term absence patterns using the formula: <strong>{formulaDescription}</strong> where:
        </Text>
        <ul className="list-disc ml-5 mt-2">
          <li><Text>S = number of separate absence spells</Text></li>
          <li><Text>D = total number of days absent</Text></li>
        </ul>
        <div className="grid grid-cols-4 gap-2 mt-4">
          <div className="p-2 bg-green-100 rounded">
            <Text><strong>Low:</strong> 0-{thresholds.lowMax}</Text>
          </div>
          <div className="p-2 bg-yellow-100 rounded">
            <Text><strong>Medium:</strong> {thresholds.mediumMin}-{thresholds.mediumMax}</Text>
          </div>
          <div className="p-2 bg-orange-100 rounded">
            <Text><strong>High:</strong> {thresholds.highMin}-{thresholds.highMax}</Text>
          </div>
          <div className="p-2 bg-red-100 rounded">
            <Text><strong>Critical:</strong> {thresholds.criticalMin}+</Text>
          </div>
        </div>
      </Card>

      {/* Highlight Cards */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-6">
        <Card decoration="top" decorationColor="blue">
          <Text>Total Employees</Text>
          <Flex justifyContent="start" alignItems="baseline" className="space-x-1">
            <Title>{totalEmployees}</Title>
          </Flex>
        </Card>
        <Card decoration="top" decorationColor="red">
          <Text>High Risk Employees</Text>
          <Flex justifyContent="start" alignItems="baseline" className="space-x-1">
            <Title>{highRiskEmployees}</Title>
            <Text>({Math.round((highRiskEmployees / totalEmployees) * 100)}%)</Text>
          </Flex>
        </Card>
        <Card decoration="top" decorationColor="amber">
          <Text>Average Bradford Score</Text>
          <Flex justifyContent="start" alignItems="baseline" className="space-x-1">
            <Title>{averageBradfordScore}</Title>
          </Flex>
        </Card>
      </div>

      {/* Filters */}
      <Card className="mb-6">
        <Title>Filters</Title>
        <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-4">
          <div>
            <Text className="mb-2">Department</Text>
            <Select
              value={selectedDepartment}
              onValueChange={setSelectedDepartment}
              placeholder="Select Department"
            >
              {departments.map((dept) => (
                <SelectItem key={dept.value} value={dept.value}>
                  {dept.label}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div>
            <Text className="mb-2">Date Range</Text>
            <DateRangePicker
              value={dateRange}
              onValueChange={setDateRange}
              selectPlaceholder="Select range"
              color="blue"
            />
          </div>
          <div>
            <Text className="mb-2">Employment Type</Text>
            <MultiSelect
              value={selectedEmploymentTypes}
              onValueChange={setSelectedEmploymentTypes}
              placeholder="Select Employment Types"
            >
              {employmentTypes.map((type) => (
                <MultiSelectItem key={type.value} value={type.value}>
                  {type.label}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </div>
          <div>
            <Text className="mb-2">Employee</Text>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <RiSearchLine className="h-5 w-5 text-gray-400" />
              </div>
              <TextInput
                value={employeeSearchTerm}
                onChange={(e) => setEmployeeSearchTerm(e.target.value)}
                placeholder="Search employees..."
                className="pl-10"
              />
              {employeeSearchTerm && (
                <button
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setEmployeeSearchTerm('')}
                  aria-label="Clear search"
                >
                  <RiCloseLine className="h-5 w-5 text-gray-400" />
                </button>
              )}
            </div>
            <div className={`mt-1 max-h-60 overflow-auto rounded-md bg-white shadow-lg ${!employeeSearchTerm ? 'hidden' : ''}`}>
              <ul className="py-1 text-base divide-y divide-gray-200">
                {filteredEmployeeOptions.map((employee) => (
                  <li 
                    key={employee.value}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleEmployeeChange(employee.value)}
                  >
                    {employee.label}
                  </li>
                ))}
                {filteredEmployeeOptions.length === 0 && (
                  <li className="px-4 py-2 text-gray-500">No employees found</li>
                )}
              </ul>
            </div>
            {selectedEmployee !== 'all' && (
              <div className="mt-2">
                <Badge color="blue" className="mr-1">
                  {employeeOptions.find(e => e.value === selectedEmployee)?.label}
                  <button
                    className="ml-1"
                    onClick={() => setSelectedEmployee('all')}
                    aria-label="Remove employee filter"
                  >
                    <RiCloseLine className="h-3 w-3 inline" />
                  </button>
                </Badge>
              </div>
            )}
          </div>
        </div>
      </Card>

      {/* Show loading state when fetching data */}
      {loading ? (
        <Card>
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
          </div>
        </Card>
      ) : (
        <>
          {/* Risk Table */}
          <Card>
            <Title className="mb-4">Employee Bradford Factor Scores</Title>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Employee</TableHeaderCell>
                  <TableHeaderCell className="text-right">Total Absences</TableHeaderCell>
                  <TableHeaderCell className="text-right">Total Days</TableHeaderCell>
                  <TableHeaderCell className="text-right">Bradford Score</TableHeaderCell>
                  <TableHeaderCell>Risk Level</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.length > 0 ? (
                  employees.map((employee) => {
                    const risk = getRiskLevel(employee.bradfordFactor);
                    return (
                      <TableRow key={employee.userId}>
                        <TableCell>{employee.userName}</TableCell>
                        <TableCell className="text-right">{employee.spellsOfAbsence}</TableCell>
                        <TableCell className="text-right">{employee.totalDaysAbsent}</TableCell>
                        <TableCell className="text-right font-medium">{employee.bradfordFactor}</TableCell>
                        <TableCell>
                          <Badge color={risk.color as "green" | "yellow" | "orange" | "red"}>{risk.label}</Badge>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-4">
                      No data available for the selected filters
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Card>

          {/* Department Analysis - Only show when not filtering by a specific employee */}
          {selectedEmployee === 'all' && (
            <Card className="mt-6">
              <Title className="mb-4">Average Bradford Score by Department</Title>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Department</TableHeaderCell>
                    <TableHeaderCell className="text-right">Average Score</TableHeaderCell>
                    <TableHeaderCell>Risk Level</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departmentAverages.map((dept) => {
                    const risk = getRiskLevel(dept.averageScore);
                    return (
                      <TableRow key={dept.department}>
                        <TableCell>{dept.department}</TableCell>
                        <TableCell className="text-right font-medium">{dept.averageScore}</TableCell>
                        <TableCell>
                          <Badge color={risk.color as "green" | "yellow" | "orange" | "red"}>{risk.label}</Badge>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          )}
        </>
      )}
    </div>
  );
} 